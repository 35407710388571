import classNames from 'classnames'
import React, { useEffect } from 'react'
import Badge from '@fnd/components/Badge'
import Mockup from '@fnd/components/Mockup/Mockup'


export function MagazineCard({
  logo,
  cover,
  name,
  tags,
  contacts,
  preview,
  onClick,
  className,
}) {
  const classes = classNames({
    'magazine-card': true,
    'card-preview': preview,
    'cursor-pointer': true,
    [className]: className,
  })

  const genres = tags.filter(tag => tag !== 'all')

  return (
    <div className={classes} onClick={onClick}>
      <Mockup
        placeholder
        showAddressBar
        favicon={logo}
      >
        <img src={cover} alt={name} />
      </Mockup>

      <div className="website-card-content p-4">
        <h3 className="text-xl mb-3">
          {name}
        </h3>

        <div className="flex items-center justify-between gap-2">
          {contacts && contacts?.length > 0 && <Badge
            className="flex-1 justify-center"
            icon="envelope"
            variant="light"
            label={contacts?.length}
          />}
        </div>

        {genres && genres?.length > 0 && <div>
          <Badge
            className="block text-center truncate mt-2"
            icon="music"
            variant="light"
            label={genres.join([', '])}
          />
        </div>}
      </div>
    </div>
  )
}

export default MagazineCard
