import React from 'react'
import PushToPlaylist from '@fnd/components/PushToPlaylist'
import { PlaylistOverview } from '@fnd/components/PlaylistDetails'
import { useMatchStore } from '@fnd/store'

export function PlaylistInfo({
  curator,
  isFeatured,
  playlist,
  profile,
  score,
  tracks,
  values,
}) {
  const { track, trackId } = useMatchStore()

  return (
    <>
      <div className="playlist-detail">
        <PlaylistOverview
          playlist={playlist}
          curator={curator}
          score={score}
          values={values}
        />
      </div>

      <PushToPlaylist
        className="mt-8"
        curatorId={curator?.id}
        curatorName={curator?.display_name}
        curatorExternalUrls={curator?.external_urls}
        disabled={!score}
        playlistId={playlist?.id}
        playlist={playlist}
        isFeatured={isFeatured}
        score={score}
        track={track}
        tracks={tracks}
        trackId={trackId}
        userName={profile?.display_name}
        userContacts={profile?.contacts}
      />
    </>
  )
}


export default PlaylistInfo
