import React, { useEffect, useMemo, useState } from "react"
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@fnd/components/Table/Table"
import { TablePagination } from "@fnd/components/Table/TablePagination"
import Empty from "@fnd/components/Empty"
import Spinner from "@fnd/components/Spinner"
import classNames from "classnames"
import { PER_PAGE } from "@fnd/constants"

export function DataTable({
  rowIdKey = "id",
  columns,
  data,
  filters,
  isLoading = true,
  pagination,
  setPagination,
  total,
  className
}) {
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnFilters, setColumnFilters] = useState([])
  const [sorting, setSorting] = useState([])

  const defaultPagination = {
    pageIndex: 0,
    pageSize: PER_PAGE
  }

  const tableColumns = useMemo(() => columns, [])

  const table = useReactTable({
    data,
    columns: tableColumns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: pagination || defaultPagination
    },
    enableRowSelection: true,
    manualPagination: pagination ? true : false,
    autoResetSelectedRows: false,
    getRowId: (row) => row[rowIdKey],
    rowCount: total || data.length,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  const TableFilters = filters

  useEffect(() => {
    const hiddenColumns = columns
      .filter(column => column.show === false)
      .map(column => column.id)

    setColumnVisibility({
      ...columnVisibility,
      ...hiddenColumns.reduce((item, key) => {
        item[key] = false
        return item
      }, {})
    })
  }, [columns])

  const tableClasses = classNames({
    'flex flex-col gap-4': true,
    [className]: className,
  })

  return (
    <div className={tableClasses}>
      {filters && (
        <TableFilters
          data={data}
          table={table}
        />
      )}

      <div className="border-outline rounded-lg w-full overflow-x-auto">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows?.length > 0 && (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}

            {!isLoading && table.getRowModel().rows?.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center text-muted-foreground"
                >
                  <Empty ghost className="my-0" />
                </TableCell>
              </TableRow>
            )}

            {isLoading && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  <Spinner
                    className="flex justify-center text-xl w-full py-8 text-center"
                  />
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </div>

      <TablePagination
        pagination={pagination}
        table={table}
      />
    </div>
  )
}
