import React from 'react'

export const AfricaMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1254.000000pt" height="1280.000000pt" viewBox="0 0 1254.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M5061 12746 c-35 -30 -73 -56 -85 -58 -11 -2 -58 9 -102 25 -66 22
-87 25 -109 17 -17 -7 -33 -7 -45 0 -30 16 -46 12 -58 -15 -9 -18 -19 -25 -41
-25 -19 0 -35 -8 -45 -22 l-15 -22 -48 26 c-43 23 -63 26 -190 31 l-142 5 -33
-32 c-33 -32 -36 -33 -143 -38 -131 -6 -181 -24 -191 -65 -12 -49 -23 -55 -81
-48 -81 10 -151 -14 -215 -75 -44 -42 -59 -50 -91 -50 -21 0 -54 7 -75 16 -44
18 -163 11 -226 -14 -35 -14 -41 -14 -71 2 -18 10 -54 42 -80 72 -25 30 -54
54 -64 54 -25 0 -49 -39 -57 -92 -10 -67 -122 -249 -181 -294 -39 -29 -160
-79 -232 -94 -55 -12 -119 -38 -143 -58 -22 -18 -23 -53 -4 -90 12 -24 12 -38
1 -90 -7 -35 -20 -71 -29 -81 -22 -24 -20 -89 4 -126 29 -44 27 -113 -5 -156
-13 -19 -37 -52 -52 -75 -44 -64 -164 -171 -224 -199 -30 -13 -71 -25 -92 -25
-66 0 -105 -23 -128 -77 -60 -140 -84 -175 -166 -233 -62 -44 -85 -96 -92
-210 l-6 -94 -33 -10 c-19 -5 -42 -23 -54 -40 -12 -17 -34 -44 -49 -61 -15
-16 -41 -67 -58 -112 -45 -124 -68 -164 -121 -213 -40 -37 -49 -53 -55 -92
-10 -68 16 -140 71 -194 27 -26 44 -51 44 -66 0 -12 3 -39 6 -59 5 -31 2 -41
-25 -68 -24 -24 -30 -38 -26 -54 7 -24 62 -81 86 -89 12 -4 12 -8 0 -31 -10
-18 -12 -43 -7 -91 7 -59 5 -69 -20 -112 -30 -51 -43 -106 -53 -229 l-6 -80
-48 -31 c-74 -48 -97 -74 -97 -106 0 -38 15 -66 56 -104 32 -30 34 -35 34
-102 0 -39 -5 -94 -11 -121 -19 -91 25 -161 109 -174 63 -11 89 -37 97 -102 6
-51 9 -57 40 -69 27 -12 40 -29 69 -88 19 -40 36 -82 38 -93 2 -14 12 -21 32
-23 18 -2 47 -23 83 -58 54 -54 54 -55 43 -90 -20 -59 -6 -96 61 -161 33 -32
70 -75 82 -96 14 -25 31 -40 51 -45 23 -6 34 -17 42 -40 16 -49 99 -127 190
-178 96 -54 148 -104 166 -159 12 -36 17 -40 50 -43 27 -2 41 -10 49 -25 15
-29 28 -37 59 -37 14 0 40 -9 58 -20 18 -11 39 -20 48 -20 16 0 119 50 173 85
22 14 60 25 96 29 38 4 71 14 90 28 37 28 99 33 236 20 115 -11 185 -35 231
-79 l27 -25 31 22 c18 13 52 27 77 31 24 5 63 22 86 38 23 16 60 34 81 39 21
6 49 21 61 32 17 16 46 25 111 33 81 11 89 14 94 37 5 21 15 26 70 36 67 13
381 17 435 5 21 -4 31 -13 33 -29 2 -14 17 -29 41 -42 39 -19 90 -71 92 -93 1
-7 2 -36 3 -66 1 -41 6 -57 21 -68 57 -40 98 -63 112 -63 9 0 45 16 80 35 61
33 68 35 162 35 l99 0 61 -66 c89 -98 115 -156 119 -274 3 -79 0 -113 -21
-191 -14 -52 -28 -121 -32 -154 -4 -33 -18 -94 -32 -135 -14 -41 -27 -104 -30
-140 -6 -77 15 -137 82 -235 23 -33 54 -81 69 -107 14 -26 49 -73 77 -105 27
-31 52 -67 54 -80 3 -13 7 -33 10 -46 3 -14 17 -29 37 -38 22 -9 34 -22 36
-39 2 -14 12 -34 22 -45 40 -45 80 -120 80 -152 0 -26 6 -36 25 -45 14 -6 25
-14 25 -18 0 -4 -11 -15 -25 -24 -34 -22 -32 -41 15 -110 22 -32 40 -69 40
-82 0 -12 20 -64 45 -115 51 -104 54 -127 24 -175 -33 -55 -29 -107 18 -209
22 -49 51 -119 63 -155 37 -104 17 -157 -81 -215 -42 -25 -81 -100 -87 -168
-2 -29 -12 -60 -20 -70 -10 -11 -20 -56 -28 -122 -10 -88 -16 -111 -42 -150
-17 -26 -33 -66 -36 -89 -32 -276 -38 -420 -23 -480 5 -16 30 -50 56 -75 34
-33 53 -62 66 -101 9 -30 27 -66 40 -80 28 -32 61 -112 70 -170 12 -86 37
-140 105 -236 76 -106 86 -134 71 -191 -17 -61 -13 -123 15 -214 21 -71 25
-96 20 -159 -5 -61 -2 -87 14 -132 11 -32 20 -72 20 -89 0 -57 33 -214 52
-249 56 -102 86 -145 113 -160 42 -23 98 -161 96 -238 -1 -63 26 -135 59 -152
12 -6 33 -35 47 -63 13 -29 35 -67 49 -86 19 -26 24 -47 24 -90 0 -69 -6 -79
-50 -83 -24 -2 -36 -9 -38 -21 -8 -39 10 -89 44 -126 38 -42 42 -62 18 -82
-15 -12 -14 -15 6 -31 12 -10 39 -21 60 -24 28 -5 51 -19 86 -55 27 -27 56
-49 64 -49 9 0 36 13 61 29 42 28 127 51 210 56 20 2 51 13 68 26 21 16 42 22
66 20 66 -5 120 -3 169 4 30 5 74 3 115 -5 67 -12 68 -12 142 21 54 25 87 33
124 32 41 -1 55 4 82 28 26 22 42 28 72 27 32 -1 48 6 87 38 93 77 235 219
259 260 20 34 32 43 69 53 32 8 52 21 69 44 100 137 173 247 222 335 46 81 63
103 84 108 48 10 51 13 51 45 0 18 15 60 34 93 37 68 64 175 76 303 7 82 9 86
50 128 52 53 135 98 212 114 44 9 69 22 105 55 l48 43 1 94 c1 60 7 110 17
138 22 57 21 83 -3 132 -14 27 -19 50 -15 72 4 22 0 47 -14 77 -11 25 -21 60
-21 78 0 22 -8 39 -25 54 -55 48 -18 133 65 148 82 15 98 26 111 70 10 32 31
59 92 113 43 39 101 99 128 134 55 71 121 114 203 132 44 10 69 25 134 82 83
72 91 84 97 145 2 28 17 50 68 102 87 91 101 120 92 191 -4 31 -6 103 -5 161
6 340 7 355 24 389 23 44 14 76 -32 105 -98 63 -142 130 -142 218 0 21 -9 57
-19 80 -11 24 -24 77 -30 118 -12 79 -6 120 23 162 32 45 15 107 -43 162 -36
35 -39 62 -11 116 24 48 49 62 109 64 46 1 46 1 49 38 4 49 -11 75 -48 83 -35
8 -37 18 -11 52 11 13 23 45 26 71 13 106 16 113 61 158 24 25 44 51 44 59 0
7 29 44 65 81 36 37 65 75 65 84 0 19 89 123 164 190 27 25 67 72 89 105 79
118 302 331 412 394 79 45 116 81 140 136 17 38 48 74 122 142 102 94 118 119
147 223 10 36 32 76 64 115 63 78 74 99 83 161 4 29 14 67 23 85 8 19 22 54
31 77 18 54 105 210 153 275 47 65 61 108 62 192 0 41 7 80 15 95 8 14 14 43
14 65 0 22 -6 51 -14 65 -8 14 -14 39 -15 56 0 31 -26 64 -49 64 -7 0 -29 -11
-49 -25 -64 -44 -191 -88 -234 -81 -25 4 -53 0 -88 -14 -28 -11 -72 -20 -99
-20 -42 0 -53 -5 -85 -36 l-38 -37 -81 6 c-72 6 -91 4 -174 -23 -108 -35 -182
-39 -206 -12 -9 10 -24 41 -33 70 -22 66 -43 86 -94 84 -29 -1 -42 3 -49 16
-14 26 5 52 38 52 37 0 55 22 46 57 -3 16 -9 45 -12 66 -4 32 -11 41 -38 53
-19 7 -37 24 -41 36 -4 13 -20 42 -35 65 -16 23 -29 46 -29 51 0 17 -35 40
-80 52 -25 6 -52 13 -62 16 -9 3 -23 22 -31 42 -15 37 -88 107 -152 147 -16
11 -47 34 -68 52 -20 18 -43 33 -51 33 -24 0 -58 52 -52 80 8 39 -45 256 -86
350 -33 77 -35 80 -69 80 -25 0 -30 5 -35 30 -5 23 -12 30 -29 30 -70 0 -115
99 -115 253 0 116 -12 170 -62 279 -52 110 -91 164 -126 171 -67 14 -116 99
-92 162 6 15 10 39 10 55 0 23 -5 31 -25 36 -27 7 -45 31 -45 62 0 24 -75 169
-105 202 -13 14 -30 39 -38 56 -9 16 -34 49 -56 71 -40 39 -41 43 -41 108 0
62 -2 69 -31 93 -123 108 -253 352 -210 398 5 5 -13 57 -45 127 -30 65 -54
122 -54 126 0 5 12 7 28 5 40 -6 19 2 -100 36 -111 32 -128 31 -157 -4 -7 -8
-26 -18 -43 -21 -20 -5 -37 -19 -50 -41 -11 -19 -28 -39 -38 -44 -27 -14 -79
-12 -137 5 -28 8 -75 20 -104 25 -30 6 -67 20 -82 31 -16 12 -68 29 -120 39
-51 10 -100 22 -109 25 -11 4 -21 0 -30 -14 -20 -32 -35 -26 -69 29 -16 27
-38 51 -47 54 -9 2 -69 7 -132 11 -148 8 -150 9 -150 49 0 39 -25 66 -71 75
-22 4 -38 16 -49 36 -25 46 -68 46 -119 0 -27 -24 -61 -42 -106 -55 -79 -23
-90 -31 -105 -75 -9 -27 -7 -43 10 -91 11 -32 20 -69 20 -82 0 -43 -31 -92
-75 -117 -58 -34 -93 -32 -151 10 -101 73 -106 75 -171 75 -59 0 -105 13 -230
65 -38 17 -76 66 -93 120 -26 82 -43 94 -158 102 -72 6 -79 8 -110 41 -29 30
-45 37 -105 48 -40 7 -92 13 -117 14 -76 1 -119 17 -142 56 -21 35 -33 40 -57
25 -10 -7 -24 -2 -44 15 -16 13 -32 24 -37 24 -4 0 -13 13 -20 29 -17 42 4 82
50 97 22 7 38 21 45 38 6 14 21 35 33 46 37 34 30 83 -18 130 -35 34 -40 45
-40 83 0 35 7 53 36 90 34 44 38 67 10 67 -7 0 -23 -11 -36 -25 l-24 -25 -50
49 c-92 88 -110 90 -195 17z m3409 -6356 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
      <path d="M1662 11348 c-17 -17 -15 -48 5 -55 11 -4 24 3 40 23 12 16 23 32 23
37 0 12 -55 8 -68 -5z"/>
      <path d="M1042 11263 c-7 -2 -17 -13 -22 -23 -8 -15 -6 -17 23 -11 18 4 32 13
32 21 0 15 -14 21 -33 13z"/>
      <path d="M1602 11236 c-29 -49 -35 -75 -22 -91 19 -23 48 -18 75 11 30 32 32
74 7 97 -27 24 -38 21 -60 -17z"/>
      <path d="M1225 11230 c-12 -4 -27 -15 -34 -24 -12 -15 -10 -21 14 -46 16 -17
34 -30 40 -30 18 0 63 53 70 83 7 26 6 27 -31 26 -22 0 -48 -4 -59 -9z"/>
      <path d="M1360 11154 c-16 -19 -4 -61 23 -78 40 -25 75 38 41 75 -21 23 -47
24 -64 3z"/>
      <path d="M0 9067 c0 -20 21 -37 46 -37 33 0 38 16 12 34 -27 19 -58 21 -58 3z" />
      <path d="M68 8869 c-20 -11 -25 -73 -8 -84 13 -8 66 21 74 41 17 43 -23 69
-66 43z"/>
      <path d="M5025 6848 c-44 -24 -56 -56 -29 -82 39 -39 94 -3 94 61 0 28 -4 33
-22 33 -13 0 -32 -6 -43 -12z"/>
      <path d="M3907 5584 c-13 -13 15 -34 45 -34 23 0 29 4 26 18 -3 17 -57 30 -71
16z"/>
      <path d="M9797 5094 c-12 -12 -8 -31 13 -55 l19 -24 1 26 c0 28 -22 64 -33 53z" />
      <path d="M10370 4090 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
      <path d="M11256 3970 c-20 -22 -36 -44 -36 -48 0 -5 11 -18 25 -30 14 -12 25
-27 25 -34 0 -19 -98 -108 -119 -108 -28 0 -51 -27 -51 -59 0 -16 -10 -37 -25
-51 -16 -15 -25 -34 -25 -53 0 -28 -2 -29 -31 -23 -37 7 -69 -8 -69 -33 0 -24
-23 -43 -47 -39 -13 2 -19 -4 -21 -22 -5 -37 -27 -56 -97 -84 -35 -14 -78 -34
-97 -46 -18 -11 -43 -20 -54 -20 -12 0 -33 -9 -48 -21 -15 -11 -39 -24 -54
-28 -23 -6 -27 -13 -30 -47 -2 -24 -12 -49 -27 -64 -32 -35 -51 -75 -70 -150
-19 -74 -14 -102 26 -147 16 -18 31 -46 35 -61 3 -15 10 -40 15 -56 17 -53 10
-118 -16 -161 -35 -58 -43 -75 -55 -122 -7 -28 -28 -62 -64 -100 -29 -32 -61
-72 -70 -90 -27 -53 -21 -118 19 -193 24 -48 37 -89 44 -146 21 -165 28 -184
90 -242 31 -29 70 -56 87 -61 68 -19 181 21 298 104 72 51 78 62 101 194 9 52
25 102 44 134 19 33 36 85 46 141 11 58 26 103 43 128 19 29 26 55 28 95 2 46
7 60 32 87 28 28 47 71 88 196 8 24 26 56 40 71 31 33 43 81 44 175 0 88 20
158 65 223 29 42 35 59 35 104 0 64 6 72 38 51 35 -23 56 -11 48 28 -3 17 -1
56 5 87 11 51 10 60 -10 99 -19 37 -21 57 -20 155 2 105 1 116 -23 157 -14 25
-29 60 -33 77 -7 35 -46 73 -75 73 -10 0 -34 -18 -54 -40z"/>
      <path d="M12472 2590 c-22 -9 -52 -63 -52 -95 0 -29 38 -15 79 30 40 43 51 65
32 65 -5 0 -15 2 -23 4 -7 3 -23 1 -36 -4z"/>
      <path d="M12097 2415 c-26 -25 -10 -47 32 -43 31 3 36 6 36 28 0 20 -6 26 -26
28 -15 2 -34 -4 -42 -13z"/>
    </g>
  </svg>
)

export const FranceMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt" height="1280.000000pt" viewBox="0 0 1280.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M6145 11934 c-60 -51 -88 -64 -143 -64 -34 0 -75 -9 -115 -26 -84
-35 -152 -53 -167 -44 -27 17 -169 -41 -210 -85 -14 -15 -49 -45 -77 -66 -30
-22 -55 -50 -59 -64 -3 -14 -1 -55 6 -93 7 -37 18 -101 26 -142 17 -96 18
-186 3 -280 -19 -116 -16 -142 19 -208 l31 -61 -41 2 c-44 2 -42 3 -79 -77
-13 -29 -51 -78 -91 -117 -56 -56 -78 -70 -125 -84 -32 -9 -102 -38 -155 -66
-124 -62 -233 -99 -296 -99 -44 0 -58 -6 -124 -54 -72 -53 -219 -126 -253
-126 -9 0 -29 -12 -44 -28 -23 -23 -29 -39 -34 -99 -4 -39 -12 -84 -17 -99
-13 -33 -16 -91 -6 -101 4 -5 32 -10 62 -13 l55 -5 -82 -50 c-209 -128 -213
-129 -315 -96 -33 10 -119 24 -191 30 -85 7 -153 18 -190 32 -128 46 -164 47
-231 8 -41 -24 -42 -24 -42 3 0 22 -55 112 -99 160 l-24 27 38 35 c37 35 38
36 32 93 -7 70 -29 93 -108 112 -53 13 -55 12 -129 -23 -41 -20 -82 -36 -92
-36 -10 0 -30 14 -45 31 -42 47 -83 62 -138 48 -24 -6 -52 -9 -60 -5 -25 9
-65 -11 -65 -34 0 -11 20 -40 44 -65 63 -63 66 -69 50 -134 -20 -84 -18 -109
15 -146 23 -26 30 -46 36 -99 6 -64 8 -67 51 -97 69 -47 72 -55 80 -234 7
-157 7 -159 35 -185 28 -25 28 -25 17 -91 -16 -94 4 -200 43 -234 l28 -23 -47
-6 c-26 -3 -60 -6 -77 -6 -16 1 -44 -7 -61 -16 -22 -12 -42 -15 -67 -10 -37 7
-37 8 -37 55 0 55 -9 66 -67 79 -57 13 -87 -3 -91 -48 -4 -39 -11 -46 -67 -61
-22 -6 -49 -17 -61 -24 -20 -13 -21 -12 -20 22 1 46 -22 63 -58 43 -23 -12
-28 -11 -39 5 -12 15 -22 17 -62 10 -42 -6 -57 -15 -106 -65 -31 -32 -65 -72
-75 -89 -10 -17 -21 -31 -25 -31 -3 0 -12 26 -19 57 -10 46 -21 66 -59 104
-28 28 -59 75 -80 120 -60 132 -53 124 -94 125 -32 0 -41 6 -57 32 -20 32 -61
44 -75 22 -3 -5 -21 -10 -38 -10 -38 0 -90 -29 -113 -65 -21 -32 -78 -36 -94
-5 -18 33 -48 44 -74 26 -20 -13 -24 -25 -28 -89 -3 -40 -8 -79 -12 -85 -5 -8
-38 -12 -90 -12 -72 0 -86 -3 -116 -25 -43 -32 -76 -33 -86 -2 -16 53 -59 71
-107 45 -12 -6 -47 -30 -77 -53 -55 -43 -56 -43 -79 -24 -13 11 -31 19 -41 19
-16 0 -233 -106 -287 -140 -21 -13 -123 -202 -123 -228 0 -36 125 -65 176 -39
16 8 50 17 76 20 56 8 74 -7 59 -46 -8 -20 -18 -26 -47 -29 -34 -3 -38 -6 -50
-48 -10 -33 -10 -47 -2 -58 19 -23 65 -42 101 -42 26 0 45 -10 77 -37 l42 -38
-112 -1 c-95 -1 -116 -4 -138 -21 -20 -15 -27 -29 -27 -54 0 -33 2 -35 60 -54
69 -23 81 -30 114 -67 22 -25 23 -30 12 -73 -13 -53 3 -106 40 -125 32 -18
141 -7 182 17 18 11 62 23 97 27 37 4 73 15 84 24 15 14 20 14 24 4 8 -23 64
-82 91 -96 14 -8 53 -17 86 -21 74 -9 111 -27 118 -57 10 -38 81 -101 114 -99
124 5 131 4 171 -39 36 -37 43 -40 93 -40 30 0 69 4 87 9 25 8 32 7 32 -5 0
-8 16 -32 36 -53 l35 -40 133 6 c119 5 133 4 139 -11 4 -10 0 -31 -9 -47 -24
-48 -30 -158 -10 -197 28 -54 49 -62 146 -53 47 4 93 11 104 16 16 7 18 5 14
-21 -3 -16 -11 -42 -18 -59 -9 -23 -10 -40 -1 -71 10 -36 16 -43 45 -48 38 -7
53 -21 56 -51 4 -35 1 -43 -19 -50 -10 -3 -31 -17 -45 -30 -33 -31 -34 -71 -1
-150 19 -48 33 -66 70 -90 36 -24 57 -49 96 -120 27 -49 54 -108 59 -130 10
-47 60 -96 153 -150 34 -20 83 -50 109 -67 28 -18 66 -32 92 -35 38 -5 46 -10
57 -35 28 -67 61 -73 151 -28 l60 31 -6 -27 c-4 -15 -16 -40 -27 -55 -24 -34
-24 -55 0 -110 30 -70 46 -156 32 -172 -6 -8 -10 -39 -9 -70 1 -31 -1 -59 -5
-63 -12 -13 -39 22 -56 72 -11 31 -29 58 -53 77 -36 29 -38 29 -63 13 -34 -23
-32 -57 10 -138 18 -37 37 -83 41 -103 5 -29 10 -35 32 -35 l25 0 -21 -23
c-26 -28 -27 -45 -4 -73 23 -28 46 -39 108 -53 41 -10 61 -23 106 -69 68 -71
109 -124 137 -179 22 -43 56 -172 47 -180 -2 -2 -7 11 -11 29 -4 18 -17 51
-30 73 -25 44 -157 172 -228 222 -76 53 -82 37 -98 -252 -5 -93 -14 -235 -20
-315 -7 -80 -11 -176 -11 -213 1 -51 -4 -79 -19 -109 -39 -77 -8 -108 69 -69
45 23 52 20 52 -21 0 -22 -4 -28 -22 -28 -13 0 -41 -11 -63 -23 -38 -21 -40
-26 -47 -82 -4 -33 -14 -91 -22 -130 -8 -38 -20 -142 -26 -230 -12 -182 -47
-371 -118 -629 -63 -230 -119 -323 -212 -350 -44 -13 -45 -15 -43 -51 4 -55
18 -67 68 -59 42 6 43 5 54 -29 7 -20 17 -38 24 -40 20 -7 47 12 47 33 0 11 1
20 3 20 1 0 32 -10 68 -23 61 -22 65 -25 58 -48 -10 -39 -30 -72 -61 -101 -15
-14 -28 -31 -28 -37 0 -17 67 -81 85 -81 9 0 29 10 45 21 l29 22 39 -32 c29
-22 50 -31 78 -31 25 0 45 -7 60 -21 13 -13 39 -23 61 -25 21 -1 58 -10 82
-18 24 -9 58 -16 76 -16 23 0 43 -10 74 -40 23 -23 52 -40 65 -40 32 0 53 -20
63 -60 11 -44 39 -50 79 -17 22 19 37 23 66 19 27 -3 45 2 67 17 l30 21 53
-56 c29 -31 55 -59 58 -63 3 -4 18 -17 34 -29 40 -30 105 -29 175 3 l55 26 41
-41 c22 -22 45 -40 51 -40 6 0 21 11 33 24 20 21 27 23 63 14 23 -5 77 -7 120
-5 70 3 82 7 108 32 32 31 39 71 20 121 -6 15 -9 28 -7 30 2 2 39 -11 83 -30
48 -20 104 -35 144 -39 86 -8 92 -11 112 -57 13 -32 22 -40 43 -40 15 0 33 7
41 15 14 13 20 12 55 -5 21 -11 39 -27 39 -34 0 -8 12 -28 26 -45 33 -40 74
-42 103 -5 18 23 23 25 38 13 9 -8 36 -14 60 -14 43 0 45 -1 59 -40 11 -32 12
-45 2 -63 -16 -31 0 -55 38 -56 16 -1 53 -16 82 -35 42 -27 52 -38 52 -61 0
-48 22 -56 93 -35 34 10 86 29 115 43 l54 24 60 -31 c47 -24 74 -31 116 -31
47 0 60 -4 84 -27 l28 -27 28 22 c54 43 134 84 206 107 72 23 77 23 146 9 112
-24 127 -5 61 74 -68 82 -84 163 -55 276 16 63 15 162 -4 276 -9 54 -9 56 27
96 35 40 53 70 64 105 4 11 22 18 57 22 29 4 60 13 70 22 9 8 32 15 50 15 28
0 44 11 97 65 48 50 63 72 63 94 0 32 33 52 53 32 18 -18 62 -12 85 12 12 12
32 42 45 67 16 30 32 46 51 51 16 4 36 15 46 24 16 15 18 13 30 -15 8 -18 28
-39 49 -50 20 -10 49 -32 65 -49 32 -34 82 -42 92 -16 5 12 10 10 26 -11 40
-50 101 -50 140 -1 l18 22 0 -27 c0 -35 28 -51 61 -34 21 11 29 8 70 -21 111
-79 175 -77 201 8 6 22 14 39 18 39 10 0 40 -35 40 -47 0 -29 39 -43 151 -53
62 -5 122 -10 132 -10 23 0 22 -22 -4 -71 -23 -47 -22 -92 3 -97 10 -2 47 8
83 23 75 30 97 27 122 -17 10 -16 39 -41 64 -53 52 -27 170 -51 197 -40 26 11
317 37 395 35 51 -1 75 3 95 17 14 10 49 29 77 43 27 14 50 30 51 35 0 6 4 33
8 60 6 42 12 53 37 68 18 10 37 33 46 57 12 31 21 40 39 40 27 0 100 61 109
91 4 10 26 27 50 38 66 29 122 78 148 130 21 41 27 46 61 49 52 4 195 79 209
109 8 17 8 36 0 64 -10 37 -8 43 13 67 79 88 123 146 136 181 15 39 15 42 -5
67 -12 16 -21 42 -21 68 -1 33 -5 42 -21 44 -12 2 -31 -8 -45 -23 -18 -19 -33
-25 -65 -25 -22 0 -45 -4 -51 -10 -18 -18 -70 -14 -100 7 -16 11 -47 26 -69
33 -22 7 -62 23 -90 35 -27 12 -62 28 -78 34 -15 6 -32 23 -37 38 -6 16 -19
44 -30 64 -11 20 -20 52 -20 70 0 19 -9 52 -19 74 -17 38 -17 41 -1 65 9 14
27 53 40 88 22 61 23 62 61 62 36 0 39 2 39 29 0 17 -11 42 -25 59 -18 21 -25
42 -25 70 0 49 -10 58 -102 86 -69 21 -74 24 -95 66 -12 25 -22 56 -22 69 -1
13 -17 41 -36 62 -45 50 -45 69 -2 69 19 0 37 3 40 7 4 4 32 5 62 4 58 -3 66
2 123 77 20 28 25 29 52 19 24 -9 32 -8 49 7 31 29 55 99 47 145 -4 28 -2 42
9 51 27 23 16 48 -46 99 -33 28 -64 51 -69 51 -4 0 -13 14 -21 31 -10 25 -10
38 -1 62 15 40 -5 73 -50 85 -27 7 -41 22 -75 80 l-42 71 22 16 c12 8 28 15
35 15 21 0 142 120 142 141 0 31 -38 107 -59 118 -11 6 -33 11 -49 11 -29 0
-29 0 -21 43 12 64 12 67 -28 84 -21 8 -40 17 -42 19 -2 2 13 23 33 48 47 57
47 91 3 134 -26 25 -33 39 -29 60 5 34 -4 42 -92 77 -77 30 -104 29 -190 -4
-28 -11 -64 -23 -79 -26 -16 -3 -50 -26 -76 -50 -56 -52 -74 -55 -97 -17 -10
15 -28 36 -41 48 l-24 21 36 52 c37 56 41 71 25 101 -8 15 1 25 57 64 57 39
125 107 151 151 4 7 21 18 37 26 25 12 31 21 33 53 3 39 -23 116 -40 116 -4 0
-8 4 -8 8 0 4 38 20 84 35 62 20 100 40 141 74 52 43 56 50 52 79 -5 28 0 36
35 64 24 19 51 56 67 88 41 87 42 88 88 100 35 10 43 16 43 36 0 27 -34 53
-80 63 -35 7 -34 3 -15 51 24 58 63 83 119 76 49 -7 55 -14 31 -34 -19 -16
-19 -34 -1 -49 10 -9 40 -10 101 -5 80 6 90 10 134 45 31 23 54 53 65 79 l17
42 43 -6 c55 -9 70 7 60 60 -5 26 -23 55 -56 90 l-48 51 21 49 c11 27 22 68
23 91 2 23 15 62 30 88 31 57 32 68 5 111 -21 34 -25 67 -21 166 2 54 5 61 51
112 34 37 52 67 57 93 3 21 15 53 25 71 14 24 19 51 18 95 -1 47 4 73 22 107
37 73 130 202 180 250 53 51 93 125 134 253 35 107 31 114 -69 124 -52 5 -75
12 -97 31 -23 19 -36 23 -62 18 -74 -14 -145 -11 -227 9 -80 20 -85 23 -94 53
-5 18 -20 44 -33 58 -26 28 -35 28 -122 -13 -42 -19 -63 -23 -95 -19 -22 4
-59 8 -81 9 -40 2 -84 27 -130 73 -13 13 -28 24 -32 24 -24 -1 -85 -29 -97
-46 -14 -18 -15 -18 -34 -1 -10 9 -25 36 -32 60 -19 64 -38 97 -65 109 -17 8
-27 22 -31 47 -3 20 -8 44 -11 54 -6 23 -117 77 -156 77 -15 0 -51 14 -80 30
-30 17 -65 30 -78 30 -14 0 -54 -20 -88 -45 -51 -35 -67 -42 -82 -34 -11 5
-19 14 -19 19 0 27 -34 40 -108 40 -64 0 -79 3 -99 21 -24 23 -58 28 -109 16
-37 -9 -78 18 -98 68 -25 60 -91 98 -112 64 -4 -7 -12 1 -20 19 -10 24 -22 33
-54 42 -25 7 -55 25 -74 45 -35 38 -130 85 -169 85 -26 0 -27 3 -27 49 0 28 5
53 11 57 16 10 4 97 -18 134 -17 30 -17 32 4 73 27 54 36 106 22 123 -7 8 -31
14 -56 14 -41 0 -50 -5 -94 -49 -42 -43 -49 -55 -49 -90 0 -45 -26 -71 -71
-71 -15 0 -51 -16 -80 -35 -59 -40 -71 -40 -172 -2 -59 22 -59 22 -54 58 3 20
14 46 26 58 12 12 21 34 21 49 0 29 -26 62 -48 62 -7 0 -12 11 -12 24 0 41 18
76 45 86 37 14 32 44 -12 71 -21 12 -54 40 -74 61 l-35 37 -115 0 c-78 0 -121
-5 -135 -14 -20 -13 -22 -11 -33 40 -15 66 -62 139 -112 172 -39 26 -120 35
-166 19 -18 -7 -27 -1 -53 35 -23 33 -30 50 -25 69 22 87 22 86 -48 130 -42
27 -67 49 -71 65 -9 34 -42 40 -105 19 -46 -15 -80 -43 -95 -77 -5 -13 -49 32
-93 96 -20 28 -37 40 -71 49 l-45 11 7 86 c6 80 5 88 -18 122 -13 20 -28 53
-32 75 -3 21 -11 45 -17 52 -15 19 -49 14 -82 -14z"/>
      <path d="M11702 2350 c-47 -7 -61 -21 -63 -60 0 -14 -3 -83 -5 -154 -2 -71 -6
-132 -9 -134 -3 -3 -15 0 -28 5 -28 13 -85 2 -168 -33 -31 -13 -65 -24 -75
-24 -23 0 -115 -42 -154 -70 -15 -11 -35 -20 -42 -20 -30 0 -48 -35 -48 -93 0
-48 -4 -58 -26 -76 -14 -11 -34 -23 -45 -26 -14 -5 -19 -16 -19 -40 0 -31 3
-35 39 -45 21 -5 44 -17 52 -26 11 -13 9 -14 -14 -9 -32 9 -77 -31 -77 -69 0
-46 68 -139 130 -177 12 -7 10 -13 -15 -34 -16 -14 -41 -31 -55 -38 -32 -16
-35 -35 -10 -74 18 -31 23 -33 75 -33 30 0 55 -4 55 -9 0 -5 -5 -32 -11 -59
-10 -43 -16 -51 -40 -56 -20 -4 -29 -12 -29 -24 0 -32 33 -52 83 -52 51 0 70
-16 42 -36 -10 -8 -19 -31 -22 -58 -5 -40 -1 -50 31 -94 38 -53 52 -62 94 -62
15 0 46 -9 70 -20 36 -16 47 -29 67 -74 18 -42 31 -57 51 -62 36 -9 74 22 89
72 6 22 24 58 38 80 15 21 27 44 27 50 0 6 9 23 21 38 15 19 20 36 16 59 -3
21 1 38 11 50 11 13 17 50 22 135 l7 117 62 122 c57 114 61 127 61 190 0 37
-5 108 -10 157 -8 64 -7 99 1 123 11 35 -2 147 -22 185 -5 10 -9 25 -9 33 0 9
-11 21 -25 27 -19 9 -24 16 -19 32 7 23 27 61 46 87 8 12 6 26 -10 60 -12 27
-23 74 -26 119 -4 61 -9 78 -27 93 -12 9 -26 16 -33 16 -6 -1 -31 -5 -54 -9z"/>
    </g>
  </svg>
)

export const GermanyMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="933.000000pt" height="1280.000000pt" viewBox="0 0 933.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M2610 12573 c-87 -196 -99 -237 -102 -368 -3 -111 -2 -120 16 -123
18 -4 19 1 13 81 -6 76 -5 87 14 111 23 29 50 34 69 11 18 -21 45 -19 88 8 20
13 56 27 79 31 l43 6 32 -67 c18 -38 33 -72 34 -78 1 -5 9 -32 18 -60 22 -71
20 -110 -7 -137 -24 -24 -86 -55 -122 -60 -11 -2 -21 -11 -23 -21 -4 -22 28
-23 91 -1 34 12 49 22 53 40 3 13 15 26 25 29 13 5 19 16 19 38 l0 32 18 -28
c10 -15 34 -38 52 -52 18 -13 51 -50 72 -81 21 -30 53 -71 71 -91 44 -48 41
-59 -12 -55 -58 5 -100 -14 -107 -47 -3 -14 -8 -34 -11 -44 -4 -16 2 -18 54
-15 57 3 58 3 78 45 11 23 26 45 34 48 17 7 18 6 26 -26 5 -21 -2 -36 -40 -82
-25 -31 -56 -59 -68 -61 -12 -2 -49 -9 -83 -15 -33 -6 -83 -11 -110 -11 -45 0
-52 -3 -62 -25 -16 -35 -17 -35 25 -35 35 0 36 -1 17 -15 -10 -8 -28 -15 -39
-15 -18 0 -20 -6 -19 -46 1 -38 7 -51 30 -70 37 -30 52 -30 76 1 15 20 28 25
63 25 32 0 47 -5 54 -18 11 -22 0 -128 -18 -160 -10 -20 -10 -28 5 -50 38 -58
50 -65 85 -53 36 12 49 8 49 -18 0 -10 7 -32 16 -50 10 -19 13 -42 9 -58 -6
-25 -7 -25 -75 -19 l-69 7 34 -88 c19 -48 48 -106 63 -128 l28 -40 144 -6 c80
-4 153 -10 163 -14 35 -15 15 -23 -45 -18 -50 4 -80 0 -146 -22 -74 -24 -95
-26 -197 -23 l-114 3 -44 48 -43 48 -41 -16 c-35 -14 -44 -25 -71 -83 -16 -37
-33 -76 -37 -87 -11 -30 -55 -208 -55 -222 0 -12 45 -114 76 -175 23 -44 -2
-36 -112 37 -54 36 -104 65 -112 65 -39 0 -68 -29 -80 -80 -18 -78 -17 -80 28
-80 l40 0 0 -58 c0 -58 -1 -60 -48 -99 l-48 -40 -29 44 c-26 38 -34 43 -57 39
-23 -4 -28 0 -41 32 -8 20 -14 38 -13 38 0 1 20 4 44 7 23 2 44 6 45 8 2 2 8
17 15 33 12 26 9 34 -33 105 -25 43 -45 82 -45 88 0 5 -14 35 -31 66 l-31 57
-92 0 c-51 0 -118 -5 -147 -10 -30 -6 -70 -13 -89 -16 -19 -2 -51 -9 -70 -16
-19 -6 -115 -13 -212 -17 l-178 -6 -75 -75 -75 -74 0 -62 c0 -59 -2 -63 -32
-83 -32 -19 -33 -23 -44 -118 -7 -65 -7 -109 -1 -130 l10 -33 81 -1 c137 -2
156 -17 120 -102 -14 -35 -18 -79 -19 -267 -2 -253 -3 -255 -92 -385 -40 -59
-41 -64 -47 -165 -8 -142 -18 -230 -24 -230 -3 0 -44 7 -91 16 -82 15 -224 13
-235 -4 -2 -4 6 -33 19 -65 l24 -57 -34 0 c-19 0 -37 -5 -41 -11 -10 -17 18
-132 37 -149 9 -9 63 -26 120 -39 82 -19 109 -22 132 -13 27 10 29 9 43 -25 9
-20 25 -49 37 -65 l21 -28 -23 -68 c-23 -65 -23 -69 -7 -100 17 -32 16 -32 -9
-42 -15 -6 -31 -10 -36 -10 -6 0 -19 -19 -29 -42 -24 -54 -101 -115 -157 -124
-37 -6 -47 -14 -83 -66 -23 -32 -41 -60 -39 -62 2 -1 29 -20 59 -41 30 -21 59
-42 64 -46 11 -10 -41 -113 -66 -128 -16 -10 -23 -9 -39 5 -17 15 -24 16 -62
5 -23 -7 -64 -27 -92 -43 -52 -32 -77 -37 -132 -23 -31 7 -33 6 -33 -19 0 -14
-2 -26 -5 -26 -3 0 -55 23 -117 50 -61 28 -112 49 -114 47 -2 -2 2 -17 8 -35
7 -17 10 -34 7 -37 -3 -3 -27 3 -53 12 -44 17 -49 17 -60 2 -6 -9 -37 -22 -69
-29 -31 -7 -57 -15 -57 -18 0 -3 14 -27 31 -54 29 -45 30 -50 15 -67 -22 -25
-21 -29 17 -39 28 -7 32 -13 35 -44 3 -29 10 -41 42 -62 35 -23 39 -29 34 -56
-5 -25 0 -38 32 -77 22 -26 45 -59 53 -73 45 -81 45 -81 35 -151 -5 -37 -6
-80 -2 -96 9 -31 -17 -75 -99 -167 -42 -47 -43 -50 -43 -117 0 -75 6 -81 63
-60 14 6 27 7 30 4 13 -12 -9 -35 -72 -74 -45 -27 -77 -56 -95 -85 -32 -50
-38 -52 -57 -23 -13 18 -17 19 -42 8 -28 -13 -28 -9 -11 -106 l6 -33 64 0 64
0 0 -39 c0 -34 4 -40 31 -51 27 -12 30 -17 25 -43 -3 -16 -6 -38 -6 -48 0 -14
-7 -19 -25 -19 -22 0 -25 -4 -25 -35 0 -28 -4 -35 -20 -35 -11 0 -20 -6 -20
-13 0 -8 9 -22 20 -32 11 -10 20 -31 20 -46 0 -33 32 -59 71 -59 29 0 60 -41
83 -110 8 -26 17 -36 36 -38 52 -6 53 -10 10 -62 l-42 -51 23 -49 c23 -49 24
-50 71 -50 41 0 49 -4 69 -32 18 -25 23 -44 21 -86 -1 -29 -4 -58 -7 -63 -4
-5 3 -22 14 -38 26 -36 27 -51 3 -51 -10 0 -29 -12 -43 -26 -13 -15 -46 -41
-74 -59 -76 -50 -91 -69 -76 -102 11 -25 10 -29 -12 -45 -17 -12 -23 -25 -20
-38 3 -11 -2 -31 -10 -44 -10 -15 -17 -49 -18 -88 -2 -73 24 -144 72 -194 17
-17 29 -35 27 -40 -9 -32 108 -152 142 -146 9 1 27 -9 40 -23 16 -18 34 -25
59 -25 57 0 63 -9 56 -76 -5 -48 -12 -65 -38 -92 -21 -21 -32 -44 -33 -64 -1
-21 -11 -40 -27 -54 -27 -23 -30 -41 -28 -161 l1 -61 53 -7 c51 -7 107 -43
107 -70 0 -6 11 -22 25 -35 16 -15 25 -34 25 -53 0 -17 14 -49 33 -76 18 -25
40 -63 50 -83 9 -21 21 -38 27 -38 5 0 10 -15 10 -32 0 -47 8 -54 71 -61 66
-8 86 5 75 49 -7 25 -5 26 19 20 15 -4 33 -2 39 3 9 8 25 5 54 -9 23 -11 46
-20 52 -20 5 0 9 -3 8 -7 -1 -5 2 -29 6 -55 7 -38 12 -48 27 -48 12 0 19 7 19
19 0 32 19 34 54 7 25 -18 51 -27 100 -32 46 -5 66 -3 66 4 0 7 16 18 35 26
25 11 35 21 35 37 0 20 3 21 48 14 56 -8 62 -11 62 -32 0 -9 15 -35 33 -59 23
-31 39 -43 58 -44 14 0 39 -11 55 -25 23 -19 40 -25 79 -25 28 0 56 5 63 12
10 10 19 8 43 -6 23 -14 41 -16 78 -11 43 6 53 3 102 -28 30 -19 69 -45 87
-56 21 -13 50 -21 78 -21 24 0 44 -3 44 -6 0 -3 -12 -20 -26 -37 -14 -17 -39
-60 -55 -96 -17 -36 -35 -77 -42 -91 -7 -14 -38 -42 -70 -63 -42 -29 -57 -45
-57 -62 0 -16 -19 -39 -60 -75 -75 -65 -103 -121 -101 -205 2 -49 -3 -71 -27
-115 -24 -45 -29 -69 -31 -130 -2 -60 -6 -78 -21 -90 -10 -8 -22 -27 -26 -43
-3 -15 -10 -30 -15 -33 -5 -3 -9 -14 -9 -24 0 -11 -22 -62 -50 -113 -43 -81
-50 -102 -50 -150 0 -38 7 -71 22 -102 21 -43 21 -46 5 -80 -9 -20 -17 -43
-17 -53 0 -9 -7 -26 -15 -36 -8 -11 -15 -38 -15 -60 -1 -23 -7 -52 -14 -65
-10 -18 -11 -32 -3 -54 7 -22 6 -37 -7 -63 -22 -47 -20 -70 8 -107 14 -18 30
-51 38 -75 13 -43 17 -45 61 -27 22 9 23 -12 0 -37 -15 -17 -15 -19 3 -33 13
-11 30 -13 54 -9 19 3 48 7 63 9 16 2 40 13 53 26 27 25 70 31 86 12 6 -7 7
-13 3 -13 -4 0 -2 -6 4 -13 6 -8 21 -13 33 -11 13 1 54 3 91 3 60 1 70 4 74
21 3 11 13 20 23 20 10 0 28 11 41 25 34 35 68 32 108 -9 39 -40 85 -51 161
-41 33 5 50 12 50 21 0 8 -4 14 -10 14 -22 0 -8 17 26 32 35 16 35 16 44 -8 5
-13 13 -24 18 -24 15 0 32 24 32 47 0 14 6 23 15 23 20 0 19 10 -4 39 -16 20
-23 23 -55 17 -20 -4 -40 -14 -43 -22 -8 -21 -49 -17 -79 9 -24 21 -25 26 -14
48 25 48 93 109 122 109 18 0 28 5 28 15 0 8 7 15 15 15 8 0 15 -9 15 -20 0
-11 7 -20 15 -20 8 0 15 7 15 15 0 25 33 16 58 -16 18 -22 21 -33 13 -43 -6
-7 -8 -16 -4 -19 3 -4 -1 -7 -9 -7 -9 0 -23 -11 -32 -25 l-16 -24 47 3 c25 2
57 -1 70 -7 30 -13 39 -3 25 28 -13 29 5 48 20 21 5 -9 16 -16 25 -16 11 0 14
-7 10 -25 -6 -32 24 -55 71 -55 54 0 109 70 67 86 -26 10 -16 21 14 16 23 -3
31 -10 33 -31 2 -19 9 -27 28 -29 23 -3 51 -12 90 -32 8 -4 24 -2 35 4 11 6
21 11 23 12 1 1 -4 14 -13 30 -8 16 -15 34 -15 41 0 7 -16 24 -35 39 -20 15
-33 29 -30 32 11 12 74 -34 89 -64 8 -18 28 -41 43 -51 15 -10 36 -26 45 -35
12 -12 34 -18 66 -18 68 -1 126 -30 147 -75 13 -26 26 -38 46 -42 16 -3 29 -9
29 -14 0 -5 6 -9 14 -9 7 0 19 -7 26 -15 8 -10 30 -15 61 -15 43 0 51 3 61 25
17 37 48 33 48 -5 0 -24 6 -33 27 -41 15 -6 35 -8 45 -6 10 3 21 1 24 -4 3 -5
18 -9 34 -9 20 0 31 -6 35 -20 3 -10 24 -33 46 -51 31 -24 40 -38 38 -58 -2
-14 5 -47 15 -73 21 -53 39 -60 90 -33 39 21 60 20 51 -2 -4 -10 -9 -34 -12
-53 -3 -19 -12 -44 -19 -55 -21 -30 -17 -35 28 -35 50 0 125 34 133 61 4 11
22 39 40 64 19 24 44 63 55 85 20 40 20 44 6 87 -14 40 -14 49 -1 80 12 28 19
34 32 27 10 -4 35 -9 56 -12 29 -3 41 0 55 18 16 20 20 21 67 9 28 -7 64 -23
81 -36 36 -27 52 -28 101 -8 33 14 38 14 52 0 15 -15 14 -17 -1 -29 -26 -19
-22 -34 12 -41 17 -4 30 -11 30 -17 0 -5 9 -23 20 -38 13 -19 18 -38 15 -56
-3 -17 1 -37 9 -48 14 -17 19 -18 77 -5 34 8 64 20 66 27 7 18 57 42 88 42 23
0 24 -2 15 -20 -15 -28 1 -26 58 10 36 22 62 30 95 30 51 0 61 13 55 73 -3 35
-7 33 112 54 42 7 46 12 70 61 l25 53 88 -7 c79 -6 91 -4 110 13 33 30 127 45
262 41 l120 -3 -3 24 c-2 13 -12 42 -22 65 l-18 42 39 21 39 21 0 -35 0 -36
43 7 c23 3 64 10 90 14 54 10 74 -2 97 -59 11 -26 17 -29 56 -29 40 0 46 3 60
34 13 27 24 35 53 40 54 9 56 9 95 -29 20 -19 44 -38 53 -43 14 -8 14 -12 -2
-35 -13 -21 -14 -30 -4 -49 19 -38 39 -56 85 -79 24 -11 44 -27 44 -34 0 -21
67 -30 97 -14 34 18 74 107 80 181 5 52 3 60 -27 102 -28 39 -40 47 -86 57
-47 11 -54 15 -54 36 0 25 37 89 64 112 9 7 16 24 16 37 0 33 -68 154 -101
182 -16 13 -33 41 -39 62 -7 26 -25 52 -54 77 l-44 38 51 55 c28 30 65 78 83
107 66 102 139 135 371 169 23 3 46 21 84 62 73 79 89 121 88 220 -2 71 1 84
22 111 26 33 59 40 79 16 7 -8 28 -15 47 -15 22 0 50 -11 78 -30 52 -36 62
-37 70 -7 4 12 17 36 30 52 31 42 46 112 44 205 -1 48 3 86 10 94 9 11 7 19
-12 38 -13 13 -41 54 -61 91 -34 61 -41 67 -69 67 -28 0 -38 9 -79 65 -41 56
-52 65 -79 65 -20 0 -37 -8 -48 -22 l-18 -21 -37 22 c-24 15 -36 30 -36 46 0
33 -48 115 -89 152 -24 22 -46 32 -68 33 -18 0 -33 5 -33 11 0 6 -28 39 -62
74 -35 34 -85 95 -113 134 -27 39 -58 74 -70 77 -11 4 -32 15 -47 25 -19 14
-44 19 -91 19 -63 0 -66 1 -85 34 -11 19 -38 45 -59 57 -42 24 -63 68 -63 132
0 25 -6 37 -24 46 -16 9 -28 30 -40 72 -10 38 -21 59 -31 59 -11 0 -15 11 -15
43 0 39 -4 45 -60 90 -33 27 -60 51 -60 55 0 3 20 35 44 71 72 110 106 164
106 172 0 4 -15 17 -34 29 -30 18 -34 25 -30 53 4 31 2 34 -105 102 -142 91
-182 141 -196 250 -3 22 -19 76 -36 120 -46 121 -46 115 -11 115 25 0 32 -6
46 -40 11 -27 23 -41 41 -46 18 -4 24 -11 21 -23 -3 -9 7 -38 22 -66 21 -39
32 -50 52 -50 24 0 25 4 31 65 6 73 15 100 34 100 7 0 18 13 25 30 7 16 26 38
43 49 17 10 35 34 41 52 7 20 19 34 31 36 80 13 107 14 135 4 30 -10 34 -8 61
20 24 26 35 30 58 26 18 -4 31 -2 33 5 3 8 21 1 49 -18 68 -46 73 -46 119 -7
31 26 41 42 40 62 -1 14 -2 31 -1 37 1 7 32 14 72 18 82 8 101 23 128 97 16
41 19 44 49 41 18 -2 32 0 32 5 1 4 16 26 34 47 l33 39 35 -27 c19 -14 39 -26
45 -26 12 0 54 73 73 129 13 38 21 45 65 62 45 17 51 17 73 3 20 -13 32 -14
67 -5 24 6 56 8 72 5 15 -3 40 -1 56 5 22 8 27 17 27 43 1 29 6 36 45 56 25
12 61 22 81 22 24 0 80 22 169 65 79 39 145 65 164 65 27 0 34 5 47 35 7 19
14 36 14 39 0 2 -34 23 -75 45 -41 23 -75 46 -75 52 0 5 9 28 20 49 l20 40 38
-17 c21 -10 64 -18 102 -19 36 -1 82 -9 104 -18 37 -15 38 -18 33 -54 -3 -20
-8 -44 -11 -52 -3 -8 -2 -21 3 -29 7 -11 14 -10 40 8 34 24 35 22 22 -46 -10
-50 0 -63 46 -63 22 0 36 -6 43 -20 8 -15 21 -20 49 -20 38 0 38 1 65 68 15
37 37 86 47 109 11 23 22 50 24 60 2 10 18 57 34 105 17 47 34 115 37 150 4
39 18 85 35 120 40 79 39 94 -13 333 -16 73 -27 92 -62 105 -17 7 -54 23 -83
36 -28 13 -57 24 -62 24 -14 0 -14 54 0 140 l12 69 -42 66 c-22 36 -59 94 -82
129 -46 74 -47 68 30 165 l51 64 0 76 c0 46 7 94 17 124 l17 47 -31 28 c-31
26 -32 31 -36 117 -7 144 -12 156 -74 157 -39 0 -41 2 -67 60 -48 110 -46 139
20 220 25 31 50 108 59 183 l6 50 -68 45 c-37 25 -109 85 -159 133 -51 48
-138 121 -193 162 l-101 75 0 74 0 74 43 34 c23 18 51 41 61 50 11 10 24 18
28 18 5 0 26 28 48 62 38 58 40 65 40 142 0 74 3 86 40 158 l41 78 -21 67
c-11 37 -27 77 -34 88 -13 19 -25 74 -31 134 -1 14 -12 80 -23 146 -12 66 -31
190 -43 275 -11 85 -24 159 -28 163 -4 4 -32 -2 -61 -13 -77 -30 -128 -17
-236 61 -47 34 -87 65 -89 70 -3 4 16 11 42 14 27 3 60 13 75 21 17 9 57 14
122 14 l96 0 0 56 0 57 -107 114 c-66 71 -122 122 -148 134 -67 32 -115 78
-140 136 -22 49 -27 54 -52 51 -22 -2 -29 -9 -31 -31 -2 -16 -8 -26 -15 -24
-7 2 -11 14 -10 25 2 13 -5 26 -16 31 -15 8 -27 4 -57 -17 -22 -15 -74 -38
-116 -52 -43 -13 -78 -27 -78 -32 0 -16 -15 -7 -38 23 -20 24 -22 32 -11 45
10 12 9 18 -4 32 -13 12 -20 13 -28 5 -9 -9 -34 8 -101 65 -88 76 -97 95 -35
72 64 -23 178 -50 188 -45 5 4 9 28 9 55 l0 49 75 35 c113 52 104 53 155 -14
24 -33 48 -60 51 -60 4 0 20 26 37 57 l30 58 -44 55 c-24 30 -62 66 -84 80
-38 23 -41 28 -38 65 3 33 10 44 48 73 25 19 46 38 48 42 1 4 -6 28 -17 53
l-20 47 -118 0 -118 0 -22 32 -22 31 25 19 c16 13 24 30 24 49 0 34 0 34 -88
13 -79 -20 -122 -54 -122 -97 0 -15 -11 -43 -25 -61 -26 -34 -32 -56 -16 -56
18 0 71 78 71 104 0 16 6 26 14 26 12 0 11 -10 -2 -62 -19 -72 -24 -78 -69
-78 -18 0 -33 -4 -33 -9 0 -5 25 -29 56 -54 l55 -46 -55 -37 c-31 -20 -56 -40
-56 -43 0 -4 18 -18 40 -31 27 -17 40 -32 40 -47 0 -21 -5 -23 -53 -23 l-53 0
-11 -40 c-9 -36 -8 -42 9 -54 23 -16 21 -59 -2 -64 -19 -3 -40 52 -49 133 -7
59 -33 125 -50 125 -5 0 -39 -25 -77 -56 -96 -79 -154 -101 -154 -59 0 38
-130 66 -182 39 -16 -8 -28 -10 -28 -5 0 6 13 17 30 26 22 11 63 15 173 15
127 0 146 2 160 18 10 10 17 21 17 24 0 3 -72 9 -160 13 -158 7 -160 8 -216
41 -31 19 -62 34 -68 34 -11 0 -27 -24 -69 -101 -7 -13 -41 -62 -76 -109 -44
-59 -78 -93 -112 -112 -46 -27 -159 -131 -159 -148 0 -11 -50 -29 -95 -33 -22
-2 -53 -10 -70 -17 -93 -38 -110 -41 -190 -35 l-80 6 -92 -73 c-76 -60 -93
-78 -93 -100 0 -38 12 -35 54 14 30 35 36 38 36 21 0 -27 -31 -83 -47 -83 -6
0 -21 -12 -32 -27 -19 -27 -20 -27 -21 -6 0 34 -35 39 -76 11 -33 -21 -35 -26
-31 -65 4 -41 4 -41 -20 -30 -22 10 -27 8 -43 -13 -18 -24 -19 -24 -40 -5 -15
14 -19 26 -15 47 6 25 4 28 -19 28 -19 0 -27 6 -32 25 -6 24 -10 25 -73 25
-63 0 -71 -3 -146 -50 -78 -49 -80 -50 -85 -28 -5 19 -13 23 -52 26 -44 3 -47
5 -62 43 -16 39 -16 42 5 77 13 21 24 39 25 41 2 2 17 -2 34 -9 30 -13 33 -11
78 32 26 24 70 59 98 76 74 46 78 60 71 202 l-7 121 30 26 c29 24 30 26 14 44
-22 24 -41 24 -49 0 -7 -24 -49 -32 -72 -15 -39 30 -76 17 -160 -57 -27 -23
-57 -39 -83 -43 -37 -6 -45 -2 -122 55 -45 33 -109 75 -143 91 -33 17 -74 41
-91 54 -50 38 -120 27 -181 -29 -20 -18 -23 -18 -29 -3 -3 9 -3 32 1 52 5 30
2 41 -16 60 -18 19 -33 24 -75 24 -51 0 -128 -16 -187 -39 -29 -11 -47 -8 -47
10 0 5 6 9 13 9 26 0 126 75 140 105 9 20 15 65 16 128 1 92 -1 98 -22 107
l-22 9 24 0 c15 1 21 6 18 14 -10 27 -119 187 -126 187 -5 0 -14 -15 -22 -32
-7 -18 -14 -35 -16 -37 -1 -2 -33 16 -70 41 -38 24 -88 50 -113 57 -71 20 -75
24 -72 65 5 48 -8 46 -67 -9 -26 -25 -53 -45 -59 -45 -6 0 -17 -7 -26 -15 -13
-14 -16 -13 -26 5 -15 28 -46 25 -66 -5 -15 -23 -23 -26 -63 -23 -40 3 -46 6
-47 26 -1 12 -3 24 -5 26 -2 2 -51 21 -109 42 -103 38 -107 38 -265 40 -88 2
-177 6 -197 10 -31 5 -38 4 -38 -9 0 -16 -11 -19 -112 -32 -65 -8 -122 8 -136
40 -24 53 -7 114 44 154 18 14 25 27 21 41 -4 15 0 20 14 20 10 0 19 7 19 15
0 11 -11 15 -40 15 -37 0 -40 -2 -60 -47z"/>
      <path d="M2740 12123 c-14 -2 -41 -8 -61 -13 -32 -9 -38 -15 -44 -46 -3 -20
-5 -37 -3 -38 41 -30 59 -36 115 -36 70 0 83 11 83 72 0 29 -5 37 -32 49 -18
8 -44 13 -58 12z"/>
      <path d="M2552 12001 c-18 -22 -32 -43 -32 -46 0 -4 14 -26 32 -49 17 -22 33
-43 35 -45 4 -7 53 29 53 39 0 6 -12 29 -26 51 -17 28 -24 49 -20 65 9 35 -6
29 -42 -15z"/>
      <path d="M2930 11933 c1 -19 19 -35 34 -30 26 10 19 37 -9 37 -14 0 -25 -3
-25 -7z"/>
      <path d="M7301 11808 c-7 -40 -16 -94 -20 -120 -6 -36 -5 -48 4 -48 15 0 45
102 45 152 0 22 7 41 20 53 23 21 18 35 -14 35 -21 0 -24 -7 -35 -72z"/>
      <path d="M2750 11824 c0 -20 24 -44 45 -44 28 0 29 3 15 31 -10 17 -60 28 -60
13z"/>
      <path d="M2860 11762 c-19 -9 -37 -17 -38 -19 -2 -1 -1 -20 3 -42 7 -40 8 -41
45 -41 39 0 40 1 55 52 8 28 15 55 15 60 0 13 -42 8 -80 -10z"/>
      <path d="M5236 11714 c-25 -32 -36 -57 -36 -80 0 -33 2 -34 38 -34 45 0 58 -8
71 -41 9 -26 13 -27 108 -31 54 -3 99 -4 101 -3 2 2 -16 46 -40 98 l-43 95
-60 16 c-33 8 -70 18 -81 22 -18 5 -29 -4 -58 -42z"/>
      <path d="M8085 11300 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
      <path d="M7940 11220 c0 -11 4 -20 9 -20 11 0 22 26 14 34 -12 12 -23 5 -23
-14z"/>
      <path d="M2102 11158 c3 -7 17 -14 32 -16 19 -2 27 0 24 9 -2 7 -17 14 -32 16
-20 3 -27 0 -24 -9z"/>
      <path d="M2659 10773 c-12 -15 -12 -16 4 -10 10 4 25 7 33 7 8 0 14 5 14 10 0
16 -36 11 -51 -7z"/>
      <path d="M2720 10720 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
-18 3 -23 -2 -23 -18z"/>
      <path d="M2093 10503 c-7 -2 -13 -11 -13 -19 0 -11 16 -14 65 -14 70 0 84 12
34 29 -33 11 -65 13 -86 4z"/>
      <path d="M1934 10483 c-6 -5 -14 -18 -18 -30 -6 -20 -4 -23 19 -23 40 0 126
31 122 44 -4 13 -106 20 -123 9z"/>
      <path d="M1714 10415 c-7 -19 -14 -37 -14 -40 0 -13 39 -3 55 15 14 15 31 20
73 20 45 0 53 3 48 16 -3 9 -6 18 -6 20 0 2 -32 4 -71 4 l-70 0 -15 -35z"/>
      <path d="M1623 10400 c-36 -15 -27 -30 17 -30 33 0 40 3 40 20 0 22 -19 25
-57 10z"/>
      <path d="M1403 10383 c-15 -3 -23 -12 -23 -24 0 -15 7 -19 35 -19 59 0 176 23
172 35 -4 10 -142 17 -184 8z"/>
      <path d="M1193 10316 c-142 -22 -97 -44 52 -26 80 10 102 16 98 26 -6 16 -47
16 -150 0z"/>
      <path d="M953 10204 c-33 -14 -58 -46 -45 -59 12 -12 73 -35 93 -35 13 0 18 6
16 22 -1 17 5 24 31 31 38 12 42 34 6 47 -33 13 -62 11 -101 -6z"/>
    </g>
  </svg>
)

export const IndiaMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1239.000000pt" height="1280.000000pt" viewBox="0 0 1239.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M4415 12661 l-351 -139 -127 -97 c-126 -95 -129 -98 -170 -181 l-42
-85 -65 8 c-102 12 -134 8 -308 -45 -125 -39 -170 -48 -190 -42 -168 54 -509
140 -531 135 -14 -3 -49 -13 -78 -21 l-52 -15 -30 -92 c-17 -51 -31 -95 -31
-98 0 -3 18 -14 40 -24 45 -21 48 -28 21 -72 -23 -36 -24 -34 64 -83 33 -18
61 -34 63 -36 2 -1 -19 -23 -47 -49 -28 -26 -51 -52 -51 -58 0 -5 23 -36 51
-68 l51 -59 -38 -46 c-22 -26 -49 -58 -61 -73 -12 -14 -22 -30 -23 -36 0 -5
33 -46 74 -90 l73 -79 84 -21 84 -21 10 -75 10 -74 85 -8 c130 -13 127 -11
160 -77 16 -33 30 -62 30 -65 0 -3 -67 -38 -148 -79 l-149 -75 -32 -62 -31
-62 41 -53 c44 -58 44 -56 -8 -121 l-33 -42 65 -36 c36 -20 67 -40 70 -45 3
-5 -51 -32 -119 -60 l-125 -53 -84 -126 c-52 -78 -92 -151 -106 -192 l-22 -66
-67 -28 c-37 -15 -72 -33 -79 -39 -6 -6 -63 -104 -125 -218 l-114 -208 -100
-69 c-55 -39 -106 -77 -113 -85 -7 -9 -59 -93 -115 -187 l-101 -172 -205 -55
-205 -54 -82 88 -82 89 -23 -30 c-13 -16 -95 -123 -183 -237 l-161 -208 -20
-105 c-12 -58 -18 -107 -15 -109 3 -2 67 -16 141 -31 74 -16 138 -31 142 -34
4 -4 -2 -67 -13 -141 -12 -74 -17 -136 -13 -138 5 -1 54 -26 109 -55 l99 -52
103 -280 c57 -154 103 -284 103 -290 0 -11 -142 -85 -163 -85 -7 0 -34 22 -61
49 l-48 49 -97 -63 -96 -63 -225 48 c-124 27 -235 52 -246 54 -20 5 -22 1 -28
-62 -4 -37 -9 -79 -12 -95 l-6 -27 -101 0 -102 0 -67 -45 -68 -45 193 -215
192 -214 160 0 160 0 135 73 c74 41 137 72 138 71 4 -3 -36 -208 -41 -210 -1
0 -34 -28 -73 -62 l-70 -62 -199 -21 c-110 -12 -201 -23 -203 -25 -2 -2 -1
-26 3 -53 l6 -49 304 -281 304 -282 153 0 153 0 156 66 156 66 63 279 c60 262
65 280 85 275 11 -2 68 -12 127 -22 l107 -17 -27 -32 c-15 -18 -48 -57 -74
-87 l-47 -54 64 -322 64 -322 -30 -156 c-16 -86 -29 -169 -29 -184 0 -22 28
-161 55 -277 4 -18 13 -23 35 -23 36 0 36 5 10 -155 l-20 -125 99 -422 99
-423 84 -180 c46 -99 93 -200 103 -225 10 -25 20 -46 21 -48 1 -2 20 6 43 18
23 11 41 19 42 18 0 -2 6 -61 13 -133 l14 -130 159 -286 159 -286 53 -232 c29
-128 59 -254 66 -280 10 -37 46 -92 151 -228 l137 -180 192 -533 192 -533 149
-183 149 -184 65 0 65 0 86 75 86 74 17 108 c10 59 20 117 23 129 4 18 33 30
174 75 l169 53 0 63 c0 59 5 74 75 213 l75 150 103 0 c57 0 106 2 108 4 2 2
-1 127 -6 278 l-10 273 106 334 107 333 -43 97 -42 96 -12 364 -11 364 52 83
53 83 120 3 120 3 71 123 c40 68 75 126 78 129 3 3 90 29 193 57 l188 52 60
112 60 112 365 280 365 280 180 221 180 222 275 148 275 148 148 158 147 158
0 161 0 162 193 85 192 85 40 75 c22 41 43 78 46 81 4 4 16 -42 28 -101 13
-59 27 -125 32 -145 l9 -36 83 32 c81 31 82 32 87 71 l5 40 14 -43 c8 -24 18
-45 22 -48 16 -9 59 8 59 25 0 9 -42 219 -93 466 -91 437 -94 453 -86 535 4
47 8 113 8 147 l1 62 -124 103 c-69 57 -123 108 -122 113 2 6 34 54 72 107
l69 98 82 0 c45 0 84 3 86 8 3 4 0 39 -6 77 l-12 70 -84 10 -84 10 -18 50
c-10 28 -18 60 -18 73 -1 19 133 302 143 302 2 0 30 -40 62 -90 33 -49 62 -90
66 -90 3 0 23 29 43 65 20 36 40 63 44 61 4 -3 25 -43 45 -90 l39 -86 54 0
c59 0 54 -5 88 85 5 14 19 -24 51 -140 43 -159 43 -160 91 -202 26 -23 62 -49
78 -57 19 -9 183 -31 455 -61 234 -26 438 -50 454 -54 29 -7 27 -10 -222 -268
-139 -144 -252 -266 -252 -273 0 -11 79 -273 83 -275 1 -1 39 -30 85 -64 46
-35 85 -61 86 -59 2 1 49 72 105 156 57 85 105 152 107 149 5 -4 48 -172 164
-632 24 -93 46 -174 50 -178 9 -10 210 89 210 104 0 5 -13 63 -30 127 -39 158
-32 186 51 197 47 7 49 8 49 39 0 52 21 350 25 354 2 2 83 28 180 56 l177 53
108 278 108 279 106 138 106 139 10 137 c5 75 10 147 10 159 0 17 48 62 187
179 103 86 196 160 208 164 11 4 91 11 177 15 l158 7 5 32 c3 17 13 80 23 141
l17 109 52 31 53 30 0 55 c0 54 -2 57 -53 108 l-53 53 -160 32 c-154 32 -159
34 -158 58 1 13 5 60 9 104 l6 81 -33 16 c-30 14 -37 27 -72 127 -22 61 -44
111 -49 111 -5 0 -79 -28 -164 -61 l-155 -61 -86 6 c-48 4 -114 9 -147 12
l-60 6 -320 -246 -320 -246 -130 -51 c-71 -27 -196 -76 -277 -107 l-148 -57 0
-66 0 -66 94 -42 94 -43 -42 -94 c-37 -84 -44 -94 -71 -99 -16 -3 -237 -28
-490 -56 l-460 -50 -163 42 -163 42 -34 67 -34 67 40 39 c21 21 39 41 39 45 0
3 -18 14 -40 24 l-40 18 0 149 c0 81 -3 148 -7 147 -5 0 -62 -19 -128 -42
l-120 -42 -35 -180 c-20 -99 -44 -195 -54 -214 l-18 -34 53 -53 53 -53 -29
-71 c-16 -39 -32 -73 -35 -75 -4 -3 -81 -14 -172 -26 l-165 -21 -204 46 c-112
25 -325 73 -474 106 l-270 61 -130 129 -130 129 -320 11 -320 11 -550 316
c-302 173 -562 323 -576 332 l-26 16 63 234 c35 129 67 238 71 241 5 4 70 51
146 105 76 54 137 101 135 106 -2 5 -147 97 -323 204 l-320 197 -98 -7 c-54
-3 -103 -8 -110 -11 -8 -3 -33 37 -66 105 l-54 109 10 128 10 129 -64 139
c-35 77 -60 142 -56 146 4 4 28 23 53 43 l46 36 53 -85 53 -84 31 22 c133 92
228 171 223 185 -3 8 -17 52 -30 99 -14 46 -27 88 -31 93 -3 6 -48 33 -100 62
-92 51 -95 54 -95 88 0 20 -5 90 -10 156 -6 78 -6 122 1 126 5 3 63 14 129 24
l119 18 6 51 c15 129 10 119 111 208 l94 83 64 193 c35 105 61 192 57 192 -4
0 -33 -9 -63 -19 l-56 -19 -7 36 c-3 21 -8 45 -11 54 -4 14 -292 210 -305 207
-2 0 -162 -63 -354 -138z"/>
    </g>
  </svg>
)

export const ItalyMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt" height="1280.000000pt" viewBox="0 0 1280.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M6295 12412 c-55 -21 -135 -57 -177 -80 l-76 -41 -27 21 c-36 28
-101 34 -149 14 -21 -9 -56 -16 -78 -16 -22 0 -47 -4 -56 -9 -11 -6 -33 -5
-58 1 -49 13 -76 7 -131 -28 -53 -35 -72 -71 -81 -159 l-7 -70 -40 -3 c-35 -3
-120 15 -181 38 -11 4 -31 28 -44 53 -40 76 -80 81 -133 17 -19 -22 -39 -33
-73 -39 -25 -5 -50 -15 -55 -22 -15 -23 -28 -80 -35 -152 -6 -63 -5 -70 18
-95 77 -83 11 -105 -109 -36 -47 27 -70 29 -130 11 -51 -15 -123 -87 -123
-123 0 -32 43 -125 68 -148 l21 -18 -24 -36 c-31 -46 -31 -57 0 -78 32 -21 32
-38 1 -52 -21 -9 -27 -8 -40 13 -9 14 -23 49 -31 80 -7 30 -17 60 -21 66 -18
30 -227 -24 -239 -62 -5 -14 -15 -19 -43 -19 -75 0 -102 46 -102 177 0 62 -3
74 -20 83 -25 13 -51 1 -69 -34 -11 -20 -19 -24 -41 -19 -36 7 -66 -10 -90
-50 -23 -36 -25 -73 -9 -127 16 -52 -14 -105 -112 -198 -45 -43 -83 -88 -88
-106 -5 -17 -13 -69 -16 -116 -5 -66 -3 -91 8 -114 12 -22 13 -34 5 -52 -13
-28 -26 -29 -71 -10 -31 12 -35 19 -55 103 -12 49 -22 109 -22 135 0 53 -34
98 -73 98 -52 0 -94 25 -164 96 l-72 74 1 103 c1 173 -31 202 -141 127 -62
-43 -71 -55 -71 -99 0 -36 -38 -72 -68 -64 -51 13 -67 -53 -37 -155 l16 -53
-28 -23 c-16 -13 -49 -62 -73 -109 l-45 -86 -33 21 -33 20 -34 -38 -35 -38
-63 12 c-34 6 -85 17 -114 24 -51 11 -52 11 -88 -21 -37 -32 -37 -32 -118 -26
-71 6 -87 4 -122 -14 -42 -23 -80 -27 -90 -11 -14 22 -57 9 -89 -27 -28 -30
-44 -37 -87 -44 -30 -4 -67 -12 -83 -18 l-29 -12 6 -81 c6 -73 10 -85 37 -113
16 -17 48 -42 70 -56 33 -20 40 -30 40 -56 0 -77 70 -193 115 -193 20 0 45
-29 45 -51 0 -11 -15 -33 -32 -50 -27 -24 -33 -37 -33 -71 0 -40 -1 -41 -65
-75 -36 -18 -77 -47 -91 -63 -14 -17 -33 -30 -42 -30 -9 0 -40 -9 -69 -21 -50
-19 -54 -20 -81 -4 -62 37 -79 -22 -24 -85 61 -72 84 -120 89 -191 8 -98 17
-109 87 -109 32 0 73 -3 91 -6 31 -7 32 -7 25 -51 -5 -35 -3 -49 11 -64 16
-18 16 -19 -3 -19 -33 0 -50 -31 -55 -100 -4 -48 -12 -75 -31 -103 -27 -40
-28 -90 -2 -132 7 -12 9 -31 4 -48 -14 -50 9 -96 93 -188 67 -72 87 -88 117
-92 21 -2 62 -16 92 -31 52 -24 62 -25 170 -20 63 2 129 7 146 9 29 3 33 1 33
-20 0 -13 7 -28 16 -33 15 -8 15 -16 4 -76 -13 -61 -16 -66 -40 -66 -44 0 -90
-71 -106 -163 -9 -53 5 -64 90 -72 61 -5 88 -2 167 19 52 14 114 30 138 36 34
9 62 30 131 98 71 71 90 97 105 141 10 30 31 75 46 100 16 25 29 51 29 58 0 7
19 17 42 24 52 14 108 69 108 107 0 43 40 89 98 113 29 12 73 35 99 52 38 26
57 31 103 32 50 0 64 -5 116 -41 76 -52 161 -99 197 -108 21 -5 33 -1 51 17
l24 24 99 -72 c55 -40 109 -75 120 -79 12 -4 25 -23 34 -49 8 -25 35 -65 68
-99 44 -47 60 -58 86 -58 34 0 65 25 65 53 0 13 11 11 67 -14 36 -17 77 -32
90 -33 24 -3 147 -133 163 -173 5 -12 16 -77 24 -145 8 -68 20 -136 26 -153 7
-16 16 -70 21 -120 l8 -90 45 -40 c41 -37 46 -46 60 -120 10 -50 23 -85 34
-93 10 -8 21 -38 28 -79 10 -60 9 -70 -7 -97 -34 -56 -14 -163 32 -177 117
-34 175 -54 182 -63 4 -6 5 -38 1 -72 -5 -54 -3 -63 18 -87 16 -19 36 -29 67
-32 53 -7 56 -8 117 -65 28 -25 66 -77 89 -120 29 -54 46 -76 62 -78 15 -2 26
-14 33 -37 6 -18 33 -53 59 -78 39 -37 58 -47 97 -52 134 -17 174 -38 174 -90
0 -31 28 -65 54 -65 34 0 76 -61 108 -155 15 -44 33 -83 40 -87 7 -3 50 -18
96 -32 130 -41 192 -94 192 -166 0 -29 78 -178 102 -194 13 -8 37 -18 53 -22
59 -14 103 -43 128 -86 14 -24 47 -65 74 -93 43 -45 58 -53 155 -83 58 -19
116 -41 128 -49 12 -9 33 -39 45 -68 41 -93 91 -113 180 -75 78 34 114 35 170
7 72 -36 183 -70 208 -64 81 21 73 24 144 -49 43 -45 76 -90 94 -131 16 -35
49 -85 74 -112 42 -45 46 -52 39 -85 -8 -45 11 -77 61 -100 40 -20 85 -16 143
11 31 15 33 15 71 -20 21 -19 48 -38 60 -42 35 -11 29 -21 -42 -78 -24 -19
-28 -30 -30 -82 -2 -55 0 -60 20 -63 12 -2 45 9 75 24 44 23 71 29 161 34 90
6 113 11 139 30 34 25 36 24 67 -45 11 -22 37 -71 60 -110 47 -79 50 -104 20
-159 -21 -39 -21 -41 -4 -104 16 -58 22 -67 63 -93 25 -16 59 -29 74 -29 18 0
28 -5 28 -14 0 -27 49 -73 106 -100 31 -15 71 -38 89 -51 17 -14 41 -25 53
-25 11 0 44 22 74 49 l52 48 38 -25 c54 -35 105 -144 117 -250 4 -43 16 -90
25 -104 9 -13 23 -49 32 -79 22 -74 75 -176 118 -228 41 -49 43 -55 50 -171 3
-52 15 -116 30 -163 15 -44 26 -101 26 -132 0 -48 3 -56 31 -75 40 -29 52 -63
44 -123 -10 -68 -56 -121 -99 -114 -25 4 -42 -3 -83 -34 -29 -21 -59 -39 -68
-39 -64 0 -71 -55 -19 -140 l36 -58 -32 -73 c-34 -78 -65 -118 -115 -149 -16
-10 -44 -43 -62 -72 -31 -52 -32 -55 -28 -148 6 -116 14 -166 29 -179 14 -11
170 -31 248 -31 65 0 142 26 191 63 37 28 107 156 107 195 0 30 60 123 93 145
12 8 38 18 57 22 50 10 92 38 115 77 18 30 20 52 21 223 0 105 4 199 7 210 12
32 119 103 221 146 93 39 97 39 127 23 27 -14 38 -14 78 -3 60 16 78 32 86 77
3 20 8 45 11 54 3 9 -8 31 -25 50 -39 44 -45 75 -26 120 20 48 19 87 -2 110
-15 17 -15 21 0 54 21 43 21 67 2 103 -20 40 -67 77 -147 116 -67 34 -88 50
-208 172 -53 53 -72 65 -131 83 -39 12 -69 27 -69 35 0 7 25 43 56 81 70 85
84 117 84 191 0 61 9 82 61 138 15 17 46 73 68 125 39 91 45 99 153 202 62 59
117 107 122 108 4 0 23 -12 40 -27 l32 -28 -56 -20 c-47 -17 -55 -24 -58 -48
-3 -24 2 -30 40 -47 24 -11 56 -20 73 -20 16 0 52 -11 80 -24 134 -64 172 -76
239 -76 36 0 86 5 110 12 90 24 120 -26 196 -320 22 -84 234 -272 309 -272 31
0 80 48 91 88 5 20 17 75 25 122 10 52 30 112 52 155 21 41 36 86 37 109 1 35
-5 43 -61 95 -34 31 -86 94 -116 141 -68 107 -157 192 -222 210 -41 12 -49 18
-66 57 -16 39 -26 47 -91 77 -40 18 -143 68 -228 109 -152 75 -157 78 -237
166 -91 98 -184 158 -283 181 -30 7 -95 31 -145 53 -49 22 -145 61 -212 88
-159 63 -180 74 -228 119 -22 21 -78 54 -125 75 -47 21 -99 48 -115 60 -32 24
-105 133 -105 157 0 25 40 78 66 88 45 17 154 120 154 145 0 12 9 30 20 40 11
10 20 24 20 30 0 44 -133 139 -208 150 -58 8 -152 -24 -194 -65 l-27 -27 -36
24 c-49 33 -94 39 -151 19 -62 -21 -154 -22 -154 -1 0 23 -37 37 -83 30 -73
-11 -204 31 -284 91 -18 14 -55 32 -82 40 -47 16 -50 20 -76 80 -32 74 -82
124 -190 187 -97 56 -120 80 -145 148 -24 67 -44 84 -120 104 -75 20 -139 95
-154 180 -4 22 -30 86 -57 142 -32 66 -52 123 -58 165 -11 79 -55 234 -87 311
-14 33 -36 90 -50 128 -13 37 -31 80 -39 96 -8 15 -15 49 -15 75 0 26 -5 59
-11 75 -14 38 -66 91 -114 115 -22 12 -79 51 -126 88 -47 37 -99 75 -115 83
-16 8 -71 50 -123 92 -90 72 -141 104 -221 135 -41 16 -249 190 -331 276 -50
53 -59 70 -92 171 -27 80 -37 129 -37 175 0 44 -9 88 -30 148 -33 91 -36 127
-19 195 11 42 12 44 31 31 11 -8 28 -12 37 -9 34 12 70 56 76 94 4 24 12 39
21 39 22 0 47 47 40 75 -9 36 -71 102 -132 141 l-52 33 1 83 c1 61 -3 91 -16
113 -21 37 -22 94 -2 159 8 27 15 64 15 82 0 27 5 34 30 42 16 6 50 25 75 42
l46 31 11 -31 c15 -44 41 -51 113 -30 124 37 217 83 274 135 31 28 77 66 102
84 32 22 49 42 53 62 7 37 22 37 123 3 43 -14 106 -28 141 -31 60 -6 64 -5 95
25 46 45 85 50 120 16 21 -19 26 -29 18 -37 -25 -25 -12 -64 29 -91 22 -14 46
-26 54 -26 8 0 27 -5 40 -12 38 -17 66 12 66 66 0 58 -57 174 -101 205 -19 14
-57 45 -85 69 l-51 44 -5 75 c-5 79 -14 93 -55 93 -36 0 -27 26 21 59 54 37
86 76 86 105 0 13 -26 46 -65 85 -61 59 -68 63 -100 58 -28 -5 -37 -2 -45 13
-19 35 -12 57 22 71 18 7 51 32 73 55 30 31 52 44 85 51 54 10 69 39 54 100
-9 37 -11 38 -104 69 -61 21 -116 33 -155 33 -66 1 -193 19 -323 46 -46 9 -97
15 -114 13 -21 -4 -53 6 -107 32 -112 55 -205 83 -247 76 -37 -8 -68 8 -131
67 -31 29 -32 31 -18 65 31 73 14 103 -63 112 -42 5 -47 8 -54 37 -6 24 -4 41
7 62 18 36 20 122 3 139 -19 19 -45 14 -153 -26z"/>
      <path d="M3853 5425 c-12 -8 -24 -30 -28 -48 -4 -17 -19 -49 -33 -70 -24 -35
-28 -38 -62 -32 -30 5 -39 2 -60 -21 -14 -14 -48 -41 -76 -59 -28 -18 -68 -51
-89 -74 -21 -22 -47 -43 -59 -47 -12 -4 -41 -21 -66 -39 -52 -36 -139 -75
-170 -75 -12 0 -32 13 -46 29 -16 19 -36 31 -57 33 -27 3 -33 8 -40 41 -5 21
-18 42 -30 49 -18 9 -24 7 -44 -16 -20 -23 -23 -38 -23 -103 0 -73 -1 -76 -35
-107 -39 -36 -43 -63 -24 -146 6 -25 10 -60 10 -78 -1 -31 1 -33 31 -31 44 3
111 -33 127 -67 7 -15 26 -45 42 -65 27 -34 29 -43 27 -106 -3 -68 -3 -68 27
-77 l30 -9 -1 -119 c-1 -114 -2 -122 -27 -153 -20 -25 -26 -45 -29 -91 -3 -79
3 -88 63 -81 37 5 49 3 49 -7 0 -8 -6 -16 -13 -19 -26 -10 -40 -90 -42 -231
l-2 -139 -33 -27 c-31 -25 -32 -28 -26 -81 8 -70 9 -104 1 -155 -4 -24 -2 -50
4 -61 8 -16 5 -27 -14 -56 -33 -47 -32 -73 3 -91 27 -14 28 -18 24 -68 -9
-129 -9 -133 15 -136 15 -2 29 7 45 28 20 28 65 60 83 60 3 0 5 -8 5 -18 0
-40 32 -109 55 -122 34 -18 175 -8 250 19 100 35 201 156 162 193 -11 10 -10
21 8 68 l21 55 40 -3 c26 -2 45 2 52 11 20 24 69 9 83 -25 14 -35 88 -88 123
-88 27 0 32 5 101 115 l45 70 0 108 c0 94 3 114 25 156 23 46 24 59 23 198 -2
331 -2 319 22 374 25 60 22 109 -8 143 -32 35 -51 106 -38 141 17 48 80 127
146 184 48 42 60 58 60 82 0 31 -35 73 -71 84 -10 4 -21 16 -23 28 -2 12 -11
60 -20 107 -39 222 -42 227 -122 286 -26 19 -28 24 -16 43 21 35 15 79 -13 97
-22 14 -25 23 -25 75 0 58 -2 61 -48 109 -27 28 -57 50 -68 50 -11 0 -37 -9
-58 -21 -35 -19 -40 -19 -48 -5 -10 18 -59 46 -80 46 -8 0 -23 -7 -35 -15z"/>
      <path d="M9190 2079 c-58 -22 -141 -58 -184 -79 l-79 -39 -25 19 c-13 11 -36
20 -49 20 -13 0 -43 5 -67 11 -37 9 -47 8 -77 -10 -19 -12 -40 -21 -47 -21 -7
0 -77 -31 -155 -69 -128 -62 -150 -70 -227 -77 -47 -4 -124 -7 -172 -6 -92 3
-109 -2 -184 -53 -20 -14 -50 -25 -67 -25 -34 0 -153 57 -206 99 -81 63 -96
71 -138 71 -48 0 -63 17 -63 70 0 83 -57 96 -116 26 -27 -32 -46 -45 -73 -50
-20 -3 -71 -29 -114 -57 -97 -63 -125 -62 -182 6 -21 26 -45 45 -56 45 -12 0
-39 -15 -62 -34 -23 -19 -58 -41 -78 -49 -53 -22 -73 -54 -87 -143 -8 -43 -17
-98 -22 -122 -8 -46 2 -171 19 -214 12 -32 40 -49 68 -42 16 4 24 -1 35 -23
13 -27 15 -28 118 -34 135 -9 161 -17 196 -59 39 -47 41 -48 104 -56 66 -8 74
-11 140 -68 35 -30 88 -59 158 -88 88 -36 109 -49 125 -77 14 -23 38 -41 74
-57 29 -13 59 -24 65 -24 6 0 26 -11 43 -25 47 -35 113 -49 208 -42 79 5 83 4
151 -31 53 -28 84 -54 132 -112 37 -44 68 -92 75 -115 24 -87 22 -85 92 -85
l63 0 71 -64 c88 -79 127 -94 178 -70 34 16 39 16 71 0 48 -23 86 -20 112 7
19 20 22 35 22 98 0 55 5 83 20 109 10 19 27 51 37 71 9 19 39 50 65 68 26 19
48 41 48 51 0 29 -50 109 -72 115 -16 6 -19 12 -13 33 3 15 9 50 12 77 6 55 9
50 -75 122 -27 23 -34 38 -39 79 -5 47 -2 57 31 111 20 32 36 64 36 70 0 6 6
18 14 25 7 8 18 43 24 78 10 55 18 71 55 110 25 25 62 82 85 130 22 47 59 116
81 155 23 38 41 83 41 98 0 19 13 44 40 74 44 51 49 72 22 96 -29 27 -89 20
-202 -24z"/>
    </g>
  </svg>
)

export const SouthAmericaMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="898.000000pt" height="1280.000000pt" viewBox="0 0 898.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M4838 12789 c-35 -20 -17 -59 27 -59 22 0 39 38 24 56 -13 16 -27 17
-51 3z"/>
      <path d="M4874 12685 c-15 -11 -17 -17 -8 -32 16 -26 58 -24 62 4 4 30 -29 47
-54 28z"/>
      <path d="M4923 12523 c-20 -7 -15 -51 6 -63 11 -5 22 -10 24 -10 9 0 27 62 21
71 -6 10 -31 11 -51 2z"/>
      <path d="M3245 12101 c-27 -6 -34 -14 -65 -81 -5 -10 -22 -15 -49 -16 -32 0
-49 -7 -74 -31 -46 -43 -68 -53 -143 -60 -53 -5 -68 -10 -80 -29 -17 -26 -62
-44 -109 -44 -71 0 -118 -69 -120 -176 -2 -87 -7 -96 -50 -88 -29 5 -36 2 -51
-22 -10 -15 -18 -43 -19 -63 0 -33 -3 -36 -40 -46 -35 -10 -40 -16 -43 -44 -3
-31 -6 -33 -32 -28 -26 6 -28 3 -34 -32 -4 -27 -17 -48 -41 -70 -20 -17 -39
-31 -43 -31 -4 0 -13 -4 -21 -9 -12 -8 -12 -12 2 -26 10 -9 17 -32 17 -53 0
-22 8 -44 21 -58 19 -20 21 -35 22 -170 2 -147 2 -148 -25 -182 -20 -25 -28
-46 -28 -76 0 -22 -7 -79 -14 -126 -9 -52 -11 -101 -7 -126 11 -56 -1 -74 -48
-74 -70 0 -111 -56 -111 -152 0 -29 -8 -45 -35 -73 -31 -30 -42 -35 -84 -35
-27 0 -53 -6 -59 -12 -5 -7 -15 -42 -21 -78 -14 -82 -35 -110 -96 -129 -58
-19 -88 -57 -104 -132 -16 -75 -12 -147 13 -214 20 -55 25 -61 61 -72 22 -7
57 -15 78 -18 57 -9 46 -37 -43 -112 -56 -46 -79 -73 -91 -105 -9 -24 -25 -59
-37 -78 -28 -45 -28 -58 3 -86 19 -18 23 -29 19 -52 -3 -16 -1 -45 4 -66 7
-25 7 -46 0 -67 -19 -56 36 -101 149 -119 67 -11 81 -23 94 -80 15 -68 76
-183 128 -241 26 -30 54 -72 61 -94 7 -22 28 -57 46 -77 41 -46 59 -82 68
-133 12 -67 117 -285 178 -371 32 -44 71 -111 89 -150 17 -38 39 -87 50 -110
10 -22 19 -56 19 -75 0 -39 12 -59 91 -155 103 -126 266 -259 327 -269 54 -9
88 -24 109 -47 10 -11 54 -38 98 -59 44 -21 98 -48 120 -60 22 -11 47 -24 55
-28 9 -5 16 -26 18 -53 4 -58 27 -80 112 -108 70 -24 80 -32 80 -69 0 -13 14
-65 31 -115 25 -71 34 -118 40 -211 5 -66 11 -136 14 -155 5 -24 -4 -74 -25
-155 -37 -138 -40 -183 -16 -245 13 -33 18 -73 18 -147 0 -88 3 -106 20 -129
19 -24 20 -30 9 -77 -10 -42 -10 -80 2 -202 13 -124 15 -216 8 -312 -1 -7 -14
-25 -31 -40 -37 -32 -38 -62 -4 -102 24 -29 25 -36 19 -94 -4 -37 -16 -74 -28
-91 -20 -29 -20 -30 -3 -113 10 -47 29 -114 42 -149 13 -36 24 -74 24 -87 0
-12 11 -46 25 -75 32 -69 31 -87 -6 -158 -31 -58 -32 -60 -23 -149 15 -166 5
-417 -20 -484 -21 -57 -20 -83 4 -114 40 -51 60 -109 60 -179 0 -42 6 -81 17
-103 16 -34 16 -36 -5 -65 -26 -34 -27 -48 -8 -75 7 -11 16 -42 19 -68 7 -53
72 -186 95 -194 8 -4 27 2 43 11 25 15 33 15 56 4 15 -7 29 -22 31 -33 2 -11
19 -34 38 -52 34 -32 43 -64 25 -92 -5 -7 -4 -25 2 -41 8 -21 6 -37 -7 -70
-17 -44 -10 -70 20 -70 21 0 24 -14 12 -53 -10 -32 -9 -40 7 -57 10 -11 24
-20 31 -20 23 0 54 -43 54 -74 0 -17 -11 -55 -25 -84 -28 -61 -30 -86 -10
-122 9 -16 14 -56 15 -116 0 -77 -3 -95 -20 -117 -11 -14 -20 -29 -20 -35 0
-5 9 -17 20 -27 11 -10 20 -23 20 -29 1 -27 21 -40 65 -41 27 -1 53 -9 62 -18
14 -14 13 -17 -15 -31 -31 -16 -36 -28 -47 -108 -7 -44 -5 -49 20 -70 70 -58
75 -66 75 -113 0 -43 18 -75 48 -86 5 -2 20 -31 33 -64 20 -49 31 -63 61 -77
20 -10 44 -31 54 -47 10 -16 28 -35 41 -42 16 -9 29 -30 39 -66 9 -30 25 -65
37 -79 l22 -26 54 28 c50 26 56 27 114 17 34 -6 79 -18 99 -27 32 -14 38 -21
38 -46 0 -62 -83 -66 -118 -6 -33 55 -102 54 -102 -3 0 -32 23 -55 63 -65 37
-9 34 -26 -4 -26 -40 0 -33 -18 14 -36 20 -8 54 -21 75 -29 46 -17 94 -19 110
-3 7 7 12 28 12 48 0 26 13 56 45 105 82 129 68 118 151 111 73 -7 74 -7 74
17 0 32 -30 57 -68 57 -27 0 -32 4 -32 23 0 13 -11 34 -24 46 -34 32 -56 82
-56 125 0 48 32 109 64 124 28 13 29 17 26 142 l-2 76 51 47 c28 26 62 52 76
59 53 24 67 104 29 166 -12 18 -23 22 -68 22 -43 0 -61 6 -94 29 -22 16 -43
35 -47 40 -3 6 -24 20 -45 31 -48 25 -70 64 -70 126 0 39 5 51 33 79 22 22 47
36 73 40 21 3 46 10 54 14 17 9 32 75 31 136 0 22 -1 72 -2 110 0 39 0 73 0
78 1 4 12 7 26 7 32 0 32 18 -1 49 -31 30 -28 55 9 59 19 2 27 9 27 22 0 24
-17 34 -51 32 -15 -1 -39 2 -53 8 -23 9 -26 15 -26 62 0 52 -1 53 -48 83 -53
34 -66 70 -36 100 24 23 96 15 138 -16 27 -21 45 -24 128 -27 87 -3 98 -2 117
17 23 23 29 87 10 93 -6 2 -16 42 -22 90 -9 75 -8 92 7 132 21 55 44 64 110
42 70 -24 63 -25 271 40 44 13 91 24 103 24 53 0 82 31 144 155 75 151 75 149
-32 289 -18 24 -30 51 -30 70 0 17 -8 54 -18 81 l-18 50 -47 -3 c-61 -4 -98
17 -126 70 -19 35 -21 48 -12 71 14 36 31 34 74 -7 27 -25 47 -34 86 -39 54
-6 100 -27 144 -63 35 -30 54 -30 112 -2 l46 22 42 -22 c53 -28 58 -28 88 4
13 14 38 33 54 41 59 30 85 63 85 109 0 37 6 49 39 81 49 50 64 79 96 194 30
107 36 116 79 127 21 5 36 19 46 39 8 17 27 41 42 53 18 13 29 34 33 58 24
150 55 228 144 361 96 144 94 138 86 260 -6 85 -12 114 -31 147 -30 51 -32
120 -5 172 20 39 72 91 151 151 25 19 57 51 72 72 35 48 73 68 152 78 60 7 68
11 130 68 99 90 118 98 264 100 68 0 133 4 145 7 12 3 35 26 50 51 16 25 54
67 84 93 l55 48 3 75 c2 41 4 80 4 86 1 7 16 19 34 26 47 20 62 43 59 95 -2
51 6 64 61 99 51 32 58 56 41 129 -7 32 -14 85 -14 117 0 53 3 61 36 93 22 23
33 42 29 52 -27 72 -27 140 2 164 27 22 33 55 18 105 -7 26 -17 84 -20 128 -4
44 -14 98 -21 120 -18 52 -17 69 2 107 14 28 14 34 0 72 -9 22 -13 52 -9 66 8
36 51 78 92 91 40 13 71 36 71 54 0 6 13 29 29 51 16 21 32 49 35 61 4 13 27
66 52 118 38 82 48 95 68 95 19 0 35 16 72 71 26 40 75 103 108 142 l60 70 7
101 c4 63 14 123 28 160 27 76 27 84 -9 186 -16 47 -30 104 -30 128 0 38 -6
50 -49 95 l-50 52 -98 3 c-95 3 -99 4 -125 34 -15 17 -37 32 -50 33 -13 1 -26
3 -29 4 -3 0 -32 36 -64 79 -56 75 -153 160 -222 196 -18 9 -40 30 -48 46 -19
37 -49 39 -150 10 -38 -11 -87 -20 -109 -20 -39 0 -194 47 -252 77 -40 21
-100 13 -167 -21 -54 -29 -70 -27 -43 3 32 35 23 74 -29 134 -28 33 -59 59
-75 62 -15 3 -39 19 -54 36 -21 24 -34 29 -70 29 -34 0 -52 7 -76 27 -30 25
-61 34 -167 49 -71 11 -89 1 -121 -68 -36 -74 -138 -168 -184 -168 -24 0 -28
4 -28 28 0 35 16 52 50 52 37 0 62 21 88 74 12 25 32 67 44 92 30 61 19 77
-58 85 -32 4 -109 7 -171 8 -89 1 -115 -2 -127 -15 -17 -16 -17 -19 -20 -119
-1 -60 -1 -60 -32 -63 -18 -2 -49 -18 -72 -38 -32 -27 -49 -34 -83 -34 l-43 0
77 74 c42 41 77 81 77 91 0 9 11 40 25 69 18 38 36 58 64 74 28 16 43 33 51
58 7 20 25 50 40 67 37 44 62 94 58 120 -2 17 -17 26 -66 42 -35 12 -67 28
-72 35 -4 8 -10 50 -13 93 -5 65 -10 84 -34 115 -23 31 -29 50 -33 111 l-5 75
-33 8 c-29 8 -38 18 -65 76 -36 78 -42 84 -81 77 -21 -4 -36 2 -58 20 -19 16
-42 25 -63 25 -26 0 -39 7 -56 31 -31 44 -100 73 -153 65 -27 -4 -57 -1 -85 9
-52 18 -115 19 -191 3 -71 -14 -108 -3 -137 43 -12 19 -46 58 -75 86 -42 42
-60 53 -85 53 -45 0 -73 30 -73 78 0 69 -86 174 -184 224 -34 18 -44 19 -79 8
-78 -23 -132 27 -84 78 77 84 7 162 -147 162 -79 0 -86 5 -86 55 0 32 25 55
60 55 48 0 48 21 0 53 -34 24 -78 21 -129 -8 -28 -16 -59 -25 -87 -25 -26 0
-57 -8 -76 -20 -18 -11 -41 -20 -51 -20 -10 0 -27 -15 -38 -34 -11 -19 -26
-36 -33 -39 -16 -6 -80 22 -158 69 l-59 36 -107 -6 c-59 -4 -125 -9 -148 -12
-50 -7 -74 13 -74 61 0 21 -9 44 -23 61 -22 25 -30 27 -98 28 l-74 1 3 52 c4
57 -4 66 -69 78 -26 5 -39 2 -49 -10 -11 -14 -8 -21 25 -56 36 -36 38 -40 23
-55 -8 -9 -42 -19 -75 -23 -67 -8 -111 -34 -143 -86 -29 -48 -55 -47 -47 1 5
27 1 40 -14 55 -10 10 -19 25 -19 32 0 17 28 43 56 51 37 11 74 53 74 84 0 48
-22 59 -95 43z"/>
      <path d="M4810 12046 c0 -21 21 -31 30 -15 5 7 6 17 4 21 -9 14 -34 9 -34 -6z" />
      <path d="M4857 11828 c-26 -9 -28 -14 -23 -44 6 -27 3 -35 -14 -44 -17 -9 -19
-14 -9 -26 18 -22 73 -14 77 11 3 11 13 28 23 38 23 22 25 63 3 71 -19 7 -22
7 -57 -6z"/>
      <path d="M16 9904 c-20 -20 -20 -19 6 -66 20 -36 21 -41 6 -49 -8 -5 -19 -20
-22 -34 -19 -77 74 -90 113 -17 19 35 5 116 -26 152 -28 33 -54 38 -77 14z"/>
      <path d="M190 9775 c-11 -14 -10 -19 7 -37 13 -13 28 -18 38 -14 21 8 16 35
-11 53 -18 12 -23 11 -34 -2z"/>
      <path d="M336 9754 c-10 -26 -7 -34 12 -34 21 0 42 18 42 37 0 19 -47 16 -54
-3z"/>
      <path d="M3800 2400 c-25 -25 -25 -31 1 -83 19 -36 20 -48 12 -94 -5 -29 -7
-57 -4 -62 3 -5 31 -11 61 -13 l55 -3 3 62 c2 42 -2 72 -13 92 -8 17 -15 39
-15 51 0 24 -42 70 -65 70 -8 0 -24 -9 -35 -20z"/>
      <path d="M5257 2373 c-3 -4 -5 -27 -5 -51 -1 -37 3 -44 28 -57 16 -8 40 -15
55 -15 21 0 25 5 25 29 0 16 -6 31 -12 34 -7 2 -13 17 -13 33 0 26 -4 29 -37
32 -20 2 -38 -1 -41 -5z"/>
      <path d="M4062 1951 c-20 -12 3 -63 26 -59 24 5 28 44 5 57 -11 5 -25 6 -31 2z" />
      <path d="M3940 1878 c-38 -63 -19 -91 57 -86 43 3 48 5 51 29 4 32 -41 89 -69
89 -11 0 -28 -14 -39 -32z"/>
      <path d="M3975 1676 c-8 -12 -38 -35 -66 -50 -27 -15 -48 -33 -46 -39 2 -7 28
-13 63 -14 32 -1 80 -4 105 -8 36 -5 49 -3 63 11 23 23 14 61 -25 97 -36 34
-73 35 -94 3z"/>
      <path d="M3993 1324 c-18 -8 -33 -16 -33 -18 0 -2 21 -16 47 -30 53 -30 66
-25 61 25 -4 40 -24 46 -75 23z"/>
      <path d="M4010 1192 c-17 -6 -11 -28 16 -53 21 -19 24 -30 19 -56 -5 -29 -3
-34 20 -43 28 -11 75 -14 75 -5 0 3 -6 16 -14 28 -16 25 -6 64 19 73 18 7 20
40 3 51 -13 7 -118 11 -138 5z"/>
      <path d="M6330 753 c-34 -6 -47 -21 -27 -29 24 -9 21 -25 -10 -50 l-27 -21 24
-13 c21 -11 26 -9 71 41 26 30 47 59 45 64 -3 11 -39 15 -76 8z"/>
      <path d="M6453 718 c-39 -42 -34 -78 11 -78 33 0 66 21 66 41 0 5 10 9 23 9
24 0 66 25 57 33 -3 3 -34 8 -70 12 -60 6 -67 5 -87 -17z"/>
      <path d="M4274 696 c-8 -22 -1 -59 13 -64 15 -4 36 45 29 65 -7 17 -35 17 -42
-1z"/>
      <path d="M5114 522 c-17 -11 -34 -48 -34 -76 0 -36 38 -60 74 -46 15 5 34 10
42 10 23 0 16 -37 -12 -54 -18 -13 -24 -24 -22 -44 3 -27 9 -30 91 -47 16 -3
27 -11 24 -18 -6 -18 -82 -20 -119 -3 -18 8 -68 17 -110 20 -69 6 -77 5 -82
-12 -10 -31 4 -52 34 -52 15 0 47 -7 71 -16 24 -8 84 -16 132 -18 50 -2 93 -8
99 -14 14 -14 50 -16 64 -3 13 14 130 35 189 35 30 -1 64 -8 85 -20 26 -13 56
-18 113 -19 82 0 100 9 95 50 -3 16 -9 19 -37 17 -19 -1 -41 4 -50 11 -9 8
-38 17 -64 21 -73 9 -189 67 -250 125 -30 28 -63 51 -74 51 -27 0 -46 24 -38
49 4 13 -1 27 -14 40 -18 18 -33 21 -108 21 -49 0 -93 -4 -99 -8z"/>
      <path d="M4427 513 c-9 -14 20 -51 52 -67 29 -16 147 -11 157 6 3 4 -21 20
-52 35 -61 28 -147 42 -157 26z"/>
      <path d="M5016 421 c-21 -23 -14 -57 14 -66 27 -8 40 2 40 30 0 55 -23 70 -54
36z"/>
      <path d="M4650 384 c-20 -23 2 -62 37 -66 18 -2 28 -9 28 -20 0 -26 20 -41 44
-33 32 10 28 53 -6 66 -15 5 -33 19 -40 29 -22 32 -49 42 -63 24z"/>
      <path d="M5460 148 c-19 -5 -41 -15 -48 -21 -7 -5 -29 -13 -48 -16 -34 -6 -34
-7 -19 -28 10 -15 26 -23 44 -23 16 0 45 -7 64 -15 20 -8 48 -15 61 -15 23 0
25 3 20 29 l-6 29 53 -2 c49 -1 54 1 57 22 6 39 -14 52 -82 51 -33 0 -77 -5
-96 -11z"/>
      <path d="M5705 70 c-61 -13 -95 -28 -95 -44 0 -22 29 -27 112 -20 81 7 97 18
78 55 -11 20 -30 22 -95 9z"/>
    </g>
  </svg>
)

export const SpainMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt" height="1280.000000pt" viewBox="0 0 1280.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M1990 10731 c-19 -4 -46 -13 -60 -21 -13 -7 -61 -14 -107 -15 -105
-4 -125 -16 -150 -92 l-19 -58 -80 -32 c-88 -35 -109 -58 -92 -102 5 -14 24
-38 42 -53 19 -15 36 -37 39 -48 7 -25 5 -25 -43 5 -50 31 -70 31 -100 0 -30
-32 -142 -105 -161 -105 -8 0 -20 -5 -27 -12 -8 -8 -15 -8 -24 -1 -35 28 -86
34 -170 22 -110 -16 -138 -36 -138 -94 0 -53 -9 -59 -95 -70 -33 -4 -65 -11
-72 -17 -7 -5 -20 -33 -29 -61 -9 -28 -30 -75 -46 -103 -20 -38 -28 -66 -28
-104 0 -71 12 -90 58 -90 33 0 39 -4 50 -32 9 -25 9 -37 -4 -64 -33 -69 4
-105 71 -70 27 14 30 14 37 -2 13 -34 8 -65 -22 -126 -54 -109 -21 -128 112
-62 51 25 102 46 115 46 l22 0 -20 -47 c-11 -27 -27 -62 -36 -80 -13 -29 -13
-35 4 -65 23 -43 73 -88 96 -88 32 0 13 -22 -33 -37 -106 -35 -109 -98 -7
-134 l37 -13 -54 -56 c-29 -30 -59 -65 -65 -77 -6 -12 -11 -71 -11 -133 0
-145 24 -200 89 -200 20 0 62 43 114 115 36 51 36 51 179 99 79 26 160 58 181
72 l37 24 10 -25 c5 -14 17 -25 28 -25 34 0 55 -24 48 -56 -5 -22 -19 -36 -56
-57 -55 -31 -100 -79 -100 -108 0 -10 13 -33 29 -51 28 -32 32 -33 109 -34 43
0 84 -4 90 -8 15 -9 49 9 88 46 20 19 34 26 38 19 12 -19 44 -12 99 19 54 30
87 38 87 20 0 -24 123 -62 197 -59 32 1 81 -2 110 -6 72 -11 135 19 157 76 9
22 16 48 16 59 0 23 6 24 40 5 14 -8 41 -14 61 -15 19 0 59 -11 88 -26 65 -31
91 -28 111 11 8 17 15 31 15 32 1 1 27 -7 59 -19 47 -18 62 -19 77 -9 29 17
39 6 39 -43 0 -28 6 -48 16 -57 17 -14 15 -39 -10 -107 -13 -35 -14 -51 -6
-83 13 -48 28 -55 86 -42 34 8 53 7 91 -7 70 -24 143 -59 143 -69 0 -4 -13
-26 -28 -47 -16 -21 -37 -61 -48 -87 -13 -34 -32 -58 -63 -80 -24 -18 -47 -42
-52 -54 -5 -13 -20 -24 -41 -28 -18 -4 -52 -23 -75 -41 -25 -21 -52 -34 -70
-34 -33 0 -50 -18 -78 -80 -11 -25 -27 -51 -36 -57 -10 -7 -31 -35 -48 -63
-26 -42 -36 -50 -60 -50 -29 0 -61 -22 -61 -42 0 -5 23 -35 50 -66 28 -31 55
-72 60 -92 6 -19 14 -41 19 -49 6 -9 4 -19 -6 -29 -11 -12 -13 -29 -8 -81 5
-55 3 -74 -15 -114 -23 -52 -21 -98 7 -129 13 -15 12 -20 -7 -46 -19 -26 -20
-34 -11 -69 6 -22 20 -52 31 -66 l21 -26 -53 -33 c-29 -18 -66 -40 -82 -48
-35 -18 -76 -76 -76 -108 0 -41 31 -94 64 -107 32 -13 66 -68 66 -104 0 -9
-11 -28 -25 -41 -17 -16 -25 -34 -25 -58 0 -20 -15 -62 -35 -102 -20 -37 -39
-80 -44 -96 -7 -27 -12 -29 -82 -39 -48 -7 -96 -23 -138 -44 -50 -27 -67 -31
-85 -23 -42 20 -105 35 -120 29 -26 -10 -18 -51 17 -98 18 -24 38 -56 44 -71
7 -14 34 -38 61 -54 l50 -28 7 -80 c7 -86 14 -105 51 -145 13 -14 24 -35 24
-47 0 -11 11 -31 25 -44 17 -16 25 -34 25 -56 0 -51 39 -67 101 -40 27 12 69
-37 69 -82 0 -34 -4 -40 -35 -55 -41 -19 -75 -70 -75 -111 0 -29 -64 -136
-126 -209 -18 -21 -35 -54 -38 -73 -4 -19 -20 -61 -35 -94 -35 -73 -31 -100
18 -139 41 -33 52 -52 76 -133 18 -58 52 -106 91 -126 12 -6 44 -9 77 -5 l55
6 -9 -39 c-6 -22 -13 -49 -16 -61 -3 -12 -13 -29 -22 -37 -16 -15 -19 -14 -36
4 -28 31 -60 25 -83 -14 -13 -21 -33 -38 -55 -45 -26 -9 -38 -21 -52 -54 -35
-83 -39 -92 -61 -117 -12 -14 -32 -43 -43 -65 -11 -22 -32 -53 -46 -69 -14
-16 -30 -47 -37 -68 -6 -21 -19 -45 -30 -54 -26 -24 -22 -57 14 -116 28 -45
32 -62 35 -130 1 -43 7 -86 12 -96 5 -9 12 -45 16 -80 5 -42 12 -65 24 -71 9
-5 97 -10 196 -12 145 -3 187 -6 215 -20 20 -10 54 -21 75 -24 22 -4 45 -11
50 -15 6 -4 21 -10 35 -13 14 -4 64 -42 110 -86 47 -44 102 -87 123 -97 20 -9
37 -22 37 -28 0 -6 5 -42 10 -80 10 -63 9 -71 -10 -91 -31 -33 -24 -59 31
-119 45 -50 55 -56 90 -56 l39 0 -2 -83 c-3 -71 0 -86 20 -114 12 -17 22 -39
22 -50 1 -10 45 -64 99 -120 101 -106 129 -121 152 -85 11 17 13 17 38 -7 14
-14 40 -45 57 -69 39 -54 54 -64 134 -91 l65 -21 85 23 c121 34 200 92 200
148 0 6 15 32 33 58 19 26 37 58 40 71 14 55 44 86 120 124 42 22 94 57 117
78 39 38 42 39 65 24 27 -18 76 -21 103 -6 9 5 38 18 64 29 28 12 55 32 69 53
12 18 49 55 81 81 33 27 64 59 69 71 10 20 17 22 132 23 253 4 345 9 358 19
32 26 76 26 128 0 28 -14 67 -26 87 -26 21 0 46 -7 56 -17 29 -26 196 -20 256
9 48 23 88 34 174 51 54 10 59 9 127 -23 39 -19 71 -38 71 -42 0 -4 5 -8 12
-8 6 0 19 -7 28 -15 9 -8 31 -18 48 -21 27 -5 36 -2 59 25 16 17 39 52 53 78
19 36 34 49 65 61 55 19 96 17 114 -6 12 -15 32 -21 80 -24 55 -4 67 -8 86
-32 18 -22 29 -27 55 -24 27 2 43 14 78 58 24 30 63 77 86 104 70 83 94 125
106 184 6 31 15 75 21 97 6 22 14 59 18 82 6 33 18 51 55 85 62 55 147 118
159 118 15 0 58 51 69 82 10 29 46 50 118 70 98 26 119 30 170 29 86 -2 225
30 328 75 42 18 52 28 52 47 0 28 -37 65 -76 76 -15 4 -29 12 -31 17 -2 6 21
40 50 77 60 76 79 123 71 182 -5 34 -2 44 20 67 15 14 40 53 56 87 17 34 36
61 44 61 8 0 28 13 45 29 27 26 31 35 31 83 0 48 4 59 35 95 20 23 52 52 73
64 20 13 58 40 84 61 26 22 69 47 95 56 64 23 110 52 158 102 22 23 61 52 87
66 110 56 114 109 16 241 -46 63 -55 68 -141 83 -80 14 -109 35 -144 103 -29
58 -40 97 -54 207 -6 41 -22 109 -36 150 -41 120 -38 138 37 260 71 114 79
130 105 205 12 35 36 68 77 109 67 66 71 73 87 172 12 68 37 114 62 114 24 0
73 50 115 118 26 40 63 93 84 117 25 29 45 67 59 115 29 95 106 209 141 210
52 0 163 29 184 48 11 10 33 26 49 35 49 31 30 72 -57 126 -20 13 -36 31 -36
40 0 10 43 60 95 112 100 98 194 159 248 159 35 0 64 15 99 51 15 16 40 31 55
35 16 3 82 24 148 47 112 38 128 40 245 43 150 2 257 18 283 41 10 10 28 38
39 63 34 75 66 109 167 175 53 34 108 73 123 86 16 14 62 35 103 48 42 14 78
28 81 33 3 4 14 8 24 8 17 0 100 83 100 101 0 5 22 9 50 9 72 0 92 14 146 97
26 41 56 79 66 84 28 15 105 125 113 160 7 33 -5 72 -42 131 -13 21 -24 47
-26 58 -2 11 -6 23 -10 26 -4 4 -7 27 -7 51 0 42 1 43 33 43 41 0 50 7 76 55
37 70 24 105 -40 105 -44 0 -57 14 -54 60 4 65 -35 98 -78 65 -16 -12 -25 -10
-65 12 -52 28 -107 27 -193 -2 -49 -17 -115 -77 -121 -110 -3 -15 -10 -19 -28
-17 -107 13 -140 21 -175 43 -99 62 -178 82 -237 59 -18 -7 -52 -31 -74 -53
l-42 -39 -33 56 c-26 44 -45 62 -86 83 -59 29 -152 37 -194 16 -42 -21 -118
-53 -125 -53 -3 0 -21 13 -39 28 -28 26 -31 31 -19 47 21 29 17 78 -11 116
-13 19 -29 43 -34 54 -14 31 -62 75 -82 75 -10 0 -34 -7 -53 -16 l-34 -17 -57
61 -57 62 -67 -1 c-73 -1 -126 15 -192 57 -49 31 -116 42 -148 24 -20 -10 -22
-17 -18 -68 3 -31 9 -72 12 -91 7 -30 6 -33 -12 -27 -11 4 -53 2 -93 -4 -50
-7 -77 -7 -84 0 -19 19 -53 11 -71 -17 -17 -25 -18 -25 -28 -7 -20 38 -51 64
-76 64 -14 0 -57 -9 -96 -20 -89 -24 -134 -14 -185 44 -19 21 -58 49 -87 62
-45 20 -65 23 -170 20 -112 -2 -119 -3 -129 -25 l-12 -23 -18 28 c-10 16 -22
31 -25 34 -12 9 -54 93 -69 140 -12 35 -20 46 -39 48 -14 2 -28 -3 -33 -12
-14 -25 -206 -11 -273 19 -28 13 -69 29 -92 36 -36 12 -42 18 -54 59 -12 37
-19 46 -40 48 -22 3 -29 -3 -40 -27 -29 -66 -48 -23 -25 58 23 82 21 117 -10
148 -25 24 -28 25 -110 18 -83 -7 -85 -6 -105 19 -13 17 -30 26 -49 26 -16 0
-42 11 -60 26 -25 22 -38 26 -70 21 -38 -5 -166 -48 -204 -68 -10 -6 -42 -10
-70 -8 -28 1 -67 -1 -88 -5 -33 -7 -42 -4 -90 32 -69 52 -210 102 -288 102
-43 0 -69 6 -104 26 l-46 26 -53 -17 c-28 -10 -62 -22 -74 -28 -16 -7 -26 -7
-36 1 -20 17 -196 14 -221 -4 -11 -8 -28 -14 -36 -14 -18 0 -57 38 -74 72 -41
83 -226 78 -317 -8 -21 -21 -30 -23 -51 -15 -15 5 -29 15 -33 20 -10 18 -54
12 -85 -11 -23 -17 -50 -23 -137 -29 -59 -4 -130 -13 -158 -19 -55 -13 -234
-6 -263 10 -9 5 -42 17 -72 26 -30 9 -61 21 -68 27 -7 6 -59 11 -115 12 -87 1
-110 5 -157 27 -52 24 -118 37 -355 68 -80 11 -117 31 -130 70 -7 21 -76 60
-107 60 -16 0 -49 -21 -92 -59 -86 -76 -124 -86 -279 -70 -81 8 -189 8 -408
-1 -339 -13 -344 -12 -479 53 -30 15 -66 37 -80 49 -30 28 -106 78 -118 78 -4
0 -34 14 -67 31 -64 33 -147 45 -215 30z"/>
      <path d="M12285 6421 c-22 -10 -66 -21 -99 -25 -81 -9 -93 -18 -112 -82 -16
-55 -16 -56 7 -80 17 -18 33 -24 63 -24 63 0 107 -16 151 -55 22 -19 46 -35
53 -35 7 0 37 -15 66 -34 66 -42 90 -50 115 -36 29 15 71 80 71 109 0 27 -35
90 -62 109 -10 7 -18 20 -18 29 0 8 -8 28 -17 44 -13 21 -25 29 -46 29 -20 0
-43 12 -68 35 -45 40 -50 41 -104 16z"/>
      <path d="M11295 6246 c-89 -41 -239 -160 -276 -218 -17 -25 -44 -46 -91 -68
-74 -35 -105 -61 -118 -95 -15 -39 5 -97 52 -153 16 -18 28 -37 28 -42 0 -6
15 -10 33 -10 24 0 44 12 81 46 49 45 93 70 105 58 8 -8 6 -115 -3 -140 -13
-35 37 -62 125 -70 76 -6 129 -20 129 -34 0 -5 12 -22 26 -39 37 -44 70 -41
125 10 24 23 75 69 113 103 86 76 116 134 116 223 0 55 3 64 26 82 15 12 32
21 39 21 19 0 34 51 23 80 -5 13 -20 39 -34 57 -30 40 -67 43 -131 12 -37 -18
-47 -19 -80 -9 -61 20 -66 27 -60 91 8 70 -3 86 -68 99 -100 20 -109 20 -160
-4z"/>
      <path d="M10160 5373 c-8 -4 -27 -20 -42 -37 -24 -27 -30 -29 -45 -18 -68 47
-84 42 -130 -44 -40 -76 -45 -81 -92 -94 -58 -15 -67 -52 -26 -112 14 -20 25
-48 25 -61 0 -37 20 -85 37 -91 28 -11 80 5 114 36 88 77 109 99 109 114 0 23
28 72 62 107 35 37 88 125 88 147 0 32 -65 67 -100 53z"/>
    </g>
  </svg>
)

export const UnitedKingdomMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="823.000000pt" height="1280.000000pt" viewBox="0 0 823.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M4001 12783 c-5 -10 -21 -63 -36 -118 -43 -159 -54 -180 -108 -193
-65 -17 -117 -68 -117 -116 0 -47 58 -112 135 -152 59 -31 63 -41 24 -63 -36
-20 -65 -9 -144 54 -92 74 -111 83 -132 62 -15 -14 -15 -29 -6 -129 6 -62 9
-114 8 -116 -2 -2 -30 15 -63 37 -80 55 -94 53 -121 -10 -35 -79 -24 -118 57
-201 52 -55 63 -70 53 -82 -28 -33 -52 -27 -115 33 -81 77 -100 87 -132 67
-13 -9 -26 -27 -30 -41 -8 -31 0 -174 11 -199 18 -42 -21 -8 -61 52 -57 87
-83 112 -115 112 -41 0 -59 -36 -59 -122 0 -114 48 -254 137 -399 19 -31 41
-80 48 -110 18 -65 19 -208 4 -296 -11 -62 -12 -64 -38 -59 -122 23 -204 13
-222 -28 -18 -39 12 -79 103 -140 131 -88 127 -83 99 -104 -52 -38 -88 -52
-132 -52 -37 0 -55 7 -99 40 -62 47 -107 52 -170 20 -86 -44 -123 -145 -82
-226 11 -21 30 -50 42 -64 25 -29 53 -80 47 -86 -4 -4 -55 13 -167 55 -36 14
-78 21 -122 21 -61 0 -69 -3 -84 -25 -12 -19 -14 -38 -9 -89 l7 -64 -48 -7
c-72 -10 -94 -28 -94 -74 0 -51 40 -87 108 -96 138 -19 157 -47 96 -142 -43
-65 -85 -83 -193 -83 -57 0 -105 -5 -126 -14 -31 -13 -35 -19 -35 -52 0 -53
56 -107 135 -129 75 -22 223 -15 323 14 130 39 182 83 303 253 51 71 158 159
270 221 61 34 67 35 72 18 4 -15 -19 -35 -112 -98 l-116 -78 -40 -83 c-32 -66
-58 -101 -123 -166 l-82 -82 0 -94 c0 -52 -5 -130 -12 -175 l-11 -81 -116 -70
c-84 -51 -116 -75 -116 -89 0 -18 9 -21 75 -25 l75 -5 -57 -61 c-32 -33 -58
-65 -58 -70 0 -5 19 -28 41 -52 23 -24 40 -44 38 -45 -95 -61 -170 -164 -189
-259 -28 -140 -69 -223 -155 -317 -62 -66 -76 -111 -51 -159 22 -43 73 -57
149 -43 67 12 150 46 170 69 8 10 47 118 87 242 40 124 77 236 82 250 5 14 34
42 68 63 32 21 64 44 70 52 6 7 16 53 22 102 9 79 8 95 -10 155 l-20 67 47 30
c87 55 369 221 375 221 3 0 6 -37 6 -82 1 -161 23 -251 76 -309 l24 -25 -59 4
-59 4 -56 -170 c-31 -94 -56 -178 -55 -189 0 -10 33 -83 73 -163 l74 -145
-122 -130 c-111 -118 -189 -220 -302 -390 -34 -51 -42 -73 -53 -150 -7 -49
-14 -91 -15 -93 -2 -1 -32 -7 -67 -13 -88 -14 -107 -32 -71 -66 68 -66 249
-213 262 -213 11 0 37 26 51 51 6 11 15 19 20 17 6 -2 60 -77 122 -168 62 -91
119 -166 127 -168 10 -2 36 25 67 66 l52 69 -9 84 c-4 46 -7 85 -6 86 1 1 46
-54 100 -123 109 -139 101 -134 218 -109 49 10 82 25 120 53 64 48 130 82 159
82 11 0 32 -9 46 -20 16 -13 41 -20 66 -20 36 0 46 6 90 52 l48 51 63 -21 c36
-12 88 -22 120 -22 38 0 56 -4 56 -12 0 -10 -27 -13 -102 -13 -119 0 -172 -19
-198 -70 -39 -79 -88 -153 -190 -289 l-111 -149 18 -26 c119 -182 156 -321
114 -431 -12 -30 -21 -59 -21 -63 0 -16 65 -67 85 -67 11 1 31 7 45 15 14 8
28 14 32 15 3 0 4 -44 1 -98 -7 -114 -7 -114 90 -139 l67 -18 48 55 c27 30 61
69 75 85 22 25 28 28 37 15 16 -25 12 -34 -50 -115 -33 -43 -60 -84 -60 -92 0
-7 10 -27 21 -43 16 -20 20 -36 15 -55 -6 -25 -10 -27 -49 -21 -23 3 -72 8
-109 12 l-67 7 -22 -73 c-20 -68 -24 -87 -20 -117 0 -6 33 -35 71 -64 93 -69
94 -89 9 -150 -33 -24 -83 -62 -111 -83 -49 -38 -50 -40 -44 -81 9 -56 16 -93
25 -136 8 -34 6 -38 -45 -83 -30 -26 -54 -55 -54 -64 0 -10 16 -41 36 -70 l35
-53 -25 -30 -26 -30 -10 28 c-6 15 -18 48 -27 73 -9 25 -23 52 -30 62 -21 24
-104 48 -167 48 -50 0 -57 -3 -92 -40 -46 -48 -82 -63 -115 -46 -13 6 -51 46
-84 88 -33 42 -67 77 -75 77 -9 1 -42 -26 -75 -59 -59 -60 -60 -60 -117 -60
-32 0 -58 4 -58 9 0 5 9 28 20 52 11 24 20 51 20 59 0 18 -265 310 -282 310
-20 0 -188 -162 -188 -181 0 -11 34 -86 75 -168 l74 -149 -55 -69 c-122 -149
-147 -174 -228 -222 -45 -28 -95 -55 -110 -61 -33 -12 -33 -7 23 -140 22 -52
46 -99 54 -104 10 -7 37 7 97 50 45 32 92 66 104 74 12 8 38 27 57 42 20 16
40 28 46 28 5 0 43 -42 84 -93 l74 -92 6 -95 c4 -52 7 -143 8 -202 l1 -106
-32 -27 c-18 -15 -51 -44 -73 -64 -22 -20 -58 -51 -80 -70 -51 -43 -148 -128
-239 -209 l-71 -63 -70 7 c-38 3 -93 9 -122 13 -60 9 -58 10 -166 -125 -45
-56 -62 -71 -80 -68 -75 11 -292 46 -343 56 -76 14 -92 8 -98 -34 -7 -52 -27
-277 -29 -342 l-2 -60 54 -30 c47 -27 61 -43 107 -121 51 -87 54 -90 96 -97
24 -3 86 -7 139 -7 l97 -1 62 70 63 71 111 -3 111 -3 7 -80 c11 -134 36 -269
52 -280 10 -8 44 -8 108 -2 90 9 94 10 134 48 l41 40 47 -13 c55 -14 49 -5
105 -180 20 -65 46 -127 57 -139 19 -21 169 -151 204 -177 15 -11 35 -11 116
1 l97 15 106 106 105 107 56 -15 c31 -7 73 -22 93 -33 l37 -18 -102 -85 -103
-85 -44 -102 c-50 -116 -36 -106 -196 -138 l-110 -23 -152 97 c-249 158 -206
143 -384 130 -85 -6 -173 -8 -196 -5 -52 7 -52 7 -139 -131 -92 -146 -88 -143
-145 -143 l-49 0 -35 70 c-19 39 -41 70 -48 70 -7 0 -49 -33 -92 -74 l-79 -73
29 -104 c15 -57 30 -109 32 -115 2 -6 -68 -63 -156 -128 l-161 -117 -61 13
c-60 12 -62 12 -80 -11 -11 -13 -32 -51 -47 -83 -27 -59 -29 -60 -124 -109
-54 -27 -98 -55 -98 -61 0 -10 -26 -82 -45 -125 -1 -2 -113 -7 -251 -13 -137
-5 -265 -14 -284 -18 l-35 -8 1 -93 c1 -100 6 -111 55 -111 48 0 186 -46 244
-81 62 -37 102 -92 112 -153 5 -32 9 -36 35 -36 60 0 189 136 233 247 13 32
35 67 49 77 24 18 26 18 78 1 29 -9 65 -25 79 -36 52 -36 65 -28 116 76 41 82
50 94 68 89 36 -11 332 -153 619 -295 153 -77 287 -139 297 -139 24 0 62 49
132 171 50 88 57 108 57 155 0 50 1 53 28 56 24 3 35 19 97 139 l70 136 200
-59 c629 -187 1035 -298 1087 -298 38 0 58 46 58 132 l0 63 97 -3 c54 -1 156
-7 227 -12 187 -14 295 -13 302 4 3 7 -6 57 -19 110 -14 53 -23 96 -20 96 2 0
30 -19 61 -43 31 -23 67 -49 79 -57 11 -8 90 -64 173 -125 83 -60 160 -116
170 -123 14 -11 43 -11 142 -3 261 22 395 31 459 31 l66 0 137 -90 c188 -124
166 -125 514 25 148 63 272 115 278 115 5 0 20 -16 33 -35 34 -51 57 -46 115
24 26 32 66 76 87 97 39 38 44 39 142 51 56 6 120 12 143 12 53 1 70 21 83 96
5 33 19 110 31 170 43 224 45 236 29 246 -8 5 -30 7 -49 3 -19 -3 -62 -10 -95
-15 -188 -26 -338 -50 -346 -54 -9 -6 -14 12 -50 159 -13 54 -28 100 -34 101
-5 2 -54 7 -108 10 -54 4 -96 9 -94 11 3 3 80 8 173 12 l169 7 67 125 c37 69
67 134 68 146 0 15 -9 24 -32 32 -29 9 -21 10 55 11 l89 1 84 55 c46 30 84 57
84 60 0 12 -23 50 -52 88 -34 43 -31 44 65 21 l68 -17 46 44 c165 160 348 419
426 600 30 72 31 79 35 250 4 175 3 177 -23 230 -134 266 -458 486 -838 568
-227 49 -389 8 -446 -114 -11 -25 -21 -60 -21 -79 0 -34 0 -34 -32 -26 -46 14
-107 59 -135 102 l-25 37 30 44 c38 54 76 84 169 130 103 53 118 77 117 197 0
74 -7 115 -28 185 -30 99 -110 285 -163 381 -48 87 -45 98 25 113 152 34 138
115 -83 491 -117 198 -115 215 22 308 33 22 63 48 67 57 26 70 -25 136 -242
308 -62 50 -78 70 -113 140 -94 188 -173 295 -264 357 -49 34 -151 70 -196 70
-51 0 -109 22 -139 51 -149 150 -174 722 -58 1337 15 80 18 122 12 140 -6 18
-38 46 -92 82 -46 30 -97 73 -114 95 -48 63 -95 170 -138 314 -61 199 -112
288 -197 338 -43 25 -56 28 -133 27 -47 -1 -103 -7 -125 -13 -22 -6 -68 -11
-102 -11 -33 0 -105 -9 -160 -20 -157 -31 -233 -36 -276 -15 -40 19 -57 56
-37 80 25 30 80 38 225 31 161 -8 158 -9 189 77 13 34 23 47 37 47 10 0 63
-22 116 -50 110 -57 166 -65 198 -30 38 42 29 203 -16 291 -18 36 -81 99 -99
99 -6 0 -10 4 -10 9 0 4 100 109 223 232 304 305 476 507 578 679 59 98 80
155 90 248 13 115 37 154 134 213 62 37 85 57 103 90 51 97 14 177 -121 262
-71 45 -341 131 -497 158 -124 21 -278 39 -342 39 -64 0 -77 4 -186 60 -155
79 -184 80 -291 11 -149 -95 -140 -93 -360 -96 -107 -1 -201 0 -208 2 -42 16
17 123 87 158 l48 24 28 -22 c23 -18 41 -22 97 -22 l69 0 79 82 c46 48 79 91
79 103 0 34 -55 60 -130 60 -67 0 -103 12 -85 28 6 5 84 60 175 123 277 190
602 419 655 461 66 52 185 241 210 333 l18 65 -22 24 c-28 30 -137 60 -271 75
-52 6 -133 16 -179 21 -93 11 -540 14 -590 4 -23 -5 -37 -17 -51 -43 -10 -20
-30 -49 -43 -65 l-24 -29 -12 64 c-7 35 -19 79 -27 97 -21 52 -98 145 -157
190 -52 40 -57 42 -125 42 -58 0 -73 -3 -81 -17z m288 -8219 c5 -4 -42 -63
-51 -63 -6 -1 -141 171 -162 207 -5 9 182 -117 213 -144z"/>
      <path d="M1490 7990 c-19 -17 -40 -30 -47 -30 -7 0 -47 12 -89 26 -42 14 -86
24 -97 22 -16 -2 -35 -27 -69 -92 -26 -48 -48 -89 -50 -91 -11 -11 -123 20
-180 50 -53 28 -72 34 -83 25 -16 -13 -67 -99 -156 -263 l-64 -117 -67 5 c-38
3 -99 7 -137 10 -89 8 -93 -2 -40 -107 22 -44 38 -82 36 -84 -2 -3 -60 -20
-128 -40 -68 -19 -135 -39 -149 -44 -14 -5 -58 -18 -97 -30 -72 -21 -73 -21
-73 -54 0 -28 17 -55 86 -143 47 -59 124 -157 172 -218 47 -60 110 -140 139
-177 l54 -68 138 0 c99 0 141 4 149 13 10 12 19 49 45 192 l13 69 55 13 c30 7
72 17 94 22 22 5 41 9 42 7 4 -3 22 -139 29 -208 l5 -67 89 -66 89 -67 -34
-69 c-19 -37 -35 -75 -35 -83 0 -10 25 -22 73 -36 39 -12 85 -25 101 -31 56
-18 73 -12 101 35 l26 45 90 -66 90 -66 72 7 c40 4 78 13 87 21 9 9 40 60 68
114 l52 99 58 7 c31 4 108 10 170 14 62 4 117 12 124 19 6 6 15 57 19 114 4
57 13 166 19 244 6 77 8 148 4 158 -7 18 -16 18 -177 -9 -59 -10 -85 -10 -108
-2 -16 6 -29 14 -29 18 0 7 60 63 158 147 60 52 62 55 61 100 -3 95 -7 103
-89 147 l-75 40 -14 75 c-11 62 -11 92 -1 170 21 158 25 146 -55 195 -38 24
-104 64 -146 89 l-76 46 -104 0 c-100 -1 -105 -2 -139 -30z"/>
    </g>
  </svg>
)

export const UsaMap = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt" height="788.000000pt" viewBox="0 0 1280.000000 788.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,788.000000) scale(0.100000,-0.100000)"
      fill="currentColor" stroke="none">
      <path d="M978 7832 c-3 -40 -7 -49 -31 -60 -27 -13 -28 -16 -22 -62 6 -42 4
-50 -16 -64 -54 -38 -123 -12 -234 87 l-50 45 -22 -66 -23 -67 25 -35 c40 -58
43 -146 11 -259 -15 -51 -31 -142 -36 -201 -6 -60 -14 -120 -20 -135 -5 -14
-10 -50 -10 -78 0 -35 -13 -87 -41 -162 -22 -60 -53 -148 -69 -194 -16 -46
-40 -102 -54 -125 -13 -22 -40 -74 -61 -114 -22 -43 -56 -91 -82 -115 -61 -56
-76 -88 -68 -146 5 -41 3 -49 -19 -70 -27 -25 -27 -50 0 -145 8 -27 14 -78 14
-112 0 -53 -4 -67 -27 -95 -20 -23 -29 -48 -34 -91 -6 -51 -12 -65 -51 -106
-52 -55 -70 -113 -44 -141 21 -23 44 -80 57 -142 11 -49 10 -52 -16 -79 -25
-26 -27 -34 -22 -82 3 -29 1 -69 -5 -89 -9 -33 -7 -40 26 -86 20 -28 49 -79
65 -114 27 -59 28 -65 14 -97 -14 -34 -14 -35 18 -68 18 -19 36 -34 41 -34 4
0 10 16 14 35 7 38 31 47 54 20 12 -14 9 -70 -6 -109 -4 -10 3 -19 20 -27 25
-12 25 -12 9 -40 -21 -35 -24 -35 -62 -3 l-31 26 0 -81 0 -80 49 -41 c27 -23
52 -49 55 -57 8 -21 -19 -80 -43 -92 -11 -6 -25 -20 -31 -32 -9 -16 -5 -26 22
-58 18 -22 39 -56 47 -78 18 -52 105 -225 136 -271 29 -43 30 -54 9 -131 -10
-34 -14 -70 -10 -86 9 -37 47 -69 98 -84 24 -7 68 -26 100 -42 47 -24 58 -35
69 -66 15 -47 16 -47 116 -78 83 -26 93 -35 93 -85 0 -22 11 -38 47 -67 97
-79 120 -103 148 -154 23 -44 28 -63 27 -123 -1 -94 11 -123 57 -136 20 -5
131 -17 246 -25 116 -9 223 -18 239 -22 17 -3 58 -32 95 -67 36 -34 91 -77
121 -95 30 -19 80 -51 110 -71 67 -46 483 -278 595 -332 51 -25 89 -37 105
-34 33 6 487 -50 561 -69 31 -8 69 -15 84 -15 26 0 27 2 22 39 -11 81 29 93
286 84 l178 -6 35 -55 c19 -29 106 -127 193 -215 176 -181 223 -250 251 -373
21 -91 54 -135 133 -183 33 -20 76 -50 96 -67 97 -84 135 -68 212 89 30 60 38
68 77 81 32 11 69 14 140 10 187 -10 177 -6 212 -79 22 -46 48 -80 90 -117 32
-28 65 -65 71 -82 7 -17 27 -51 44 -76 18 -25 46 -74 63 -110 17 -36 58 -101
91 -145 34 -44 72 -109 87 -145 14 -36 48 -110 74 -165 27 -55 56 -117 64
-137 15 -38 74 -78 115 -78 11 0 34 -14 51 -30 17 -17 36 -30 43 -30 7 0 49
-4 94 -10 61 -7 92 -16 120 -35 21 -14 45 -25 55 -25 37 0 34 84 -6 155 -20
34 -24 60 -29 166 -6 114 -5 130 16 180 29 71 35 79 63 89 13 5 32 26 43 47
10 21 33 57 50 81 26 35 37 42 65 42 20 0 40 8 50 19 17 19 17 20 -13 36 -20
10 -31 23 -31 37 0 20 4 20 67 15 82 -6 101 1 172 62 31 26 91 71 133 99 42
29 84 62 93 74 15 22 14 23 -7 26 -25 4 -48 27 -48 49 0 7 12 15 28 19 15 3
34 8 42 10 11 3 19 -7 25 -28 14 -45 35 -46 157 -3 57 20 135 42 173 50 64 13
75 12 125 -5 55 -20 160 -25 210 -11 24 7 24 7 5 15 l-20 8 20 8 c11 5 45 9
75 9 46 0 61 -4 94 -29 24 -18 47 -28 57 -25 13 5 26 -6 48 -37 49 -71 58 -78
101 -78 32 0 43 5 62 30 25 35 52 39 70 10 9 -14 24 -20 49 -20 52 0 60 26 19
60 -16 14 -30 33 -30 43 0 23 5 22 48 -13 48 -38 152 -80 220 -87 90 -10 60
29 -64 81 -67 28 -79 37 -79 57 0 16 11 30 35 45 19 13 44 31 55 41 19 17 19
18 -13 27 -28 8 -36 6 -53 -12 -25 -27 -31 -27 -47 -2 -21 34 -53 40 -86 17
-26 -19 -31 -19 -55 -6 -35 20 -39 60 -9 88 37 35 65 37 125 7 66 -32 76 -32
118 0 41 31 143 63 179 57 14 -3 51 -2 82 2 50 6 58 10 82 45 15 22 29 39 32
40 3 0 13 -23 23 -51 10 -27 25 -53 34 -56 16 -6 49 24 72 64 9 16 11 16 31
-2 19 -17 23 -18 30 -4 5 9 18 27 29 40 l19 23 30 -32 c33 -36 44 -39 61 -18
16 19 89 34 130 28 22 -3 36 -12 43 -28 13 -28 49 -31 88 -9 37 21 50 18 50
-11 0 -18 13 -38 39 -63 21 -20 42 -46 46 -58 8 -28 34 -29 83 -4 20 11 50 24
65 30 17 7 43 36 70 77 l42 66 47 -5 c65 -8 102 -30 137 -80 27 -40 108 -107
234 -194 88 -61 101 -115 75 -301 -6 -45 -9 -84 -7 -86 2 -2 16 -6 31 -10 26
-6 27 -5 22 23 -6 26 -3 31 21 37 29 7 40 -2 51 -44 5 -18 -1 -31 -25 -56 -17
-17 -31 -38 -31 -45 0 -7 17 -33 38 -59 21 -26 43 -59 50 -74 6 -15 35 -42 64
-61 62 -40 99 -81 133 -148 22 -42 35 -53 107 -91 72 -37 83 -46 83 -69 0 -15
15 -48 32 -73 31 -43 35 -46 78 -46 37 0 51 6 84 35 41 38 69 113 58 157 -3
12 0 49 8 82 8 34 15 109 15 167 0 119 4 109 -111 256 -96 122 -200 287 -197
313 3 26 7 27 47 15 l24 -7 -24 33 c-13 19 -58 71 -100 115 -114 122 -186 243
-226 384 -38 131 -38 135 -18 244 23 119 44 185 75 238 16 25 32 64 36 85 5
27 21 51 54 83 25 25 57 65 71 89 17 31 49 61 109 102 133 93 125 84 113 123
-10 33 -8 39 53 129 35 53 68 98 74 102 5 3 38 11 72 16 37 6 71 18 82 29 18
18 18 19 -4 54 l-22 37 27 20 c14 12 40 46 57 76 17 29 46 66 65 81 31 23 44
26 99 24 70 -2 111 9 120 33 10 24 -16 74 -39 77 -15 2 -24 13 -30 41 -8 34
-12 37 -44 37 -40 0 -40 14 0 44 l27 20 44 -21 44 -22 37 62 c52 88 64 145 40
197 -10 23 -31 52 -47 65 -25 22 -28 30 -28 89 0 67 -10 86 -35 66 -11 -9 -19
-4 -42 28 -19 28 -38 42 -60 47 -38 7 -46 17 -53 70 -4 32 -10 41 -27 43 -28
4 -29 13 -3 30 36 22 25 59 -36 121 -31 31 -60 66 -65 79 -12 32 -11 70 2 74
9 3 8 23 -1 77 -20 121 2 210 61 245 17 10 32 16 34 14 2 -2 -5 -36 -15 -76
-20 -77 -15 -122 15 -122 12 0 15 -14 15 -74 0 -79 7 -93 39 -76 16 8 21 6 26
-9 3 -10 24 -47 45 -80 31 -48 40 -71 40 -106 0 -25 -4 -45 -10 -45 -5 0 -16
-14 -25 -30 -12 -23 -13 -35 -5 -50 6 -11 14 -20 18 -20 14 0 32 39 25 56 -4
11 2 23 16 33 19 15 25 34 35 117 8 64 20 115 35 143 26 51 22 83 -14 107 -26
17 -93 107 -140 187 -23 40 -26 51 -14 58 27 17 54 6 59 -26 4 -23 16 -36 55
-57 27 -14 52 -33 55 -42 10 -29 40 9 50 65 6 33 25 76 49 111 35 53 40 67 46
143 9 110 4 131 -39 145 -50 16 -61 33 -46 70 17 40 20 41 66 25 55 -20 67
-19 59 5 -5 17 -2 20 27 20 20 1 45 10 63 25 17 14 73 41 125 61 52 20 100 41
105 46 16 15 -41 58 -76 58 -24 0 -29 -4 -29 -25 0 -13 -10 -34 -22 -45 -39
-37 -143 -80 -207 -86 -60 -7 -61 -6 -61 18 0 29 17 48 43 48 10 0 33 16 50
36 18 20 46 42 64 49 18 7 56 32 86 54 47 36 61 41 106 41 70 0 154 46 182
101 16 32 25 39 48 39 16 0 34 7 41 14 6 8 27 20 46 26 19 6 34 14 34 18 0 3
-36 49 -79 102 l-79 97 1 59 c1 42 -4 66 -16 84 -24 35 -22 59 18 160 19 49
35 98 35 108 0 32 38 72 69 72 19 0 40 14 71 45 24 25 49 45 56 45 18 0 24 25
24 94 0 48 4 67 18 79 26 24 35 21 28 -8 -6 -23 -3 -25 24 -25 18 0 33 6 36
15 4 8 16 15 29 15 16 0 40 20 77 62 29 34 75 82 102 107 l48 46 -33 28 c-19
16 -49 35 -66 43 -18 8 -33 20 -34 27 -1 6 -2 21 -3 31 -1 13 -13 23 -40 30
-29 8 -41 18 -46 36 -4 14 -17 44 -28 67 -12 23 -22 48 -22 56 0 8 -14 62 -31
121 -38 135 -86 211 -143 227 -37 10 -40 9 -63 -20 -14 -18 -46 -38 -79 -50
-67 -26 -67 -26 -54 -2 15 28 13 31 -20 31 -25 0 -30 -4 -30 -25 0 -13 -14
-45 -32 -69 -30 -43 -32 -52 -39 -168 -4 -79 -13 -138 -24 -166 -17 -39 -17
-43 -2 -60 23 -25 21 -39 -8 -94 -14 -26 -25 -57 -25 -70 -1 -37 -143 -228
-169 -228 -10 0 -53 -9 -97 -20 -43 -12 -111 -25 -150 -30 -39 -5 -118 -25
-175 -44 -57 -19 -124 -37 -149 -41 -110 -16 -125 -28 -235 -196 -56 -84 -74
-104 -107 -117 -52 -20 -49 -48 5 -44 40 4 44 -2 25 -43 -10 -23 -8 -29 20
-58 29 -30 30 -33 16 -59 -10 -19 -23 -28 -40 -28 -24 0 -51 -22 -84 -69 -19
-26 -121 -45 -230 -42 -63 2 -90 -4 -174 -33 -55 -20 -103 -39 -108 -44 -6 -6
8 -29 31 -56 58 -67 57 -103 -6 -159 -27 -23 -75 -70 -109 -103 -77 -77 -148
-133 -219 -174 -30 -17 -79 -56 -108 -86 -63 -67 -93 -84 -148 -84 -35 0 -46
-6 -78 -40 -20 -22 -43 -40 -50 -40 -7 0 -41 17 -75 38 -33 20 -79 39 -101 42
-39 5 -40 6 -37 40 1 19 9 44 16 55 7 11 15 44 18 74 5 49 10 58 49 93 24 21
54 46 66 57 12 10 27 39 33 62 15 53 2 83 -47 114 -18 11 -32 27 -33 35 0 22
-30 151 -41 179 -9 21 -14 23 -51 18 -50 -7 -69 -24 -88 -85 -12 -39 -20 -48
-42 -50 -37 -4 -86 23 -94 52 -7 28 9 61 43 93 12 12 23 32 23 45 0 13 11 41
25 63 35 57 35 128 0 151 -29 19 -31 35 -10 68 15 22 14 24 -23 50 -29 20 -50
26 -91 26 -52 0 -53 0 -68 40 -17 42 -36 50 -73 30 -15 -8 -22 -5 -38 17 l-20
28 -12 -27 c-7 -15 -26 -32 -41 -39 -31 -12 -35 -19 -18 -36 6 -6 9 -26 7 -44
-2 -29 -7 -34 -30 -37 -26 -3 -28 -6 -28 -52 0 -60 -14 -71 -38 -31 -16 30
-32 40 -32 22 0 -4 -27 -42 -59 -84 -33 -41 -57 -77 -55 -80 13 -12 6 -136 -9
-157 -12 -17 -13 -28 -4 -56 8 -26 7 -44 -1 -68 -12 -33 -10 -37 48 -125 59
-89 60 -92 60 -160 0 -133 -40 -249 -113 -328 -39 -42 -41 -43 -103 -43 -67 0
-104 20 -104 55 0 9 -11 38 -25 65 -20 40 -25 64 -25 129 0 62 -5 90 -23 130
-28 58 -29 149 -3 199 11 22 14 46 9 94 -5 59 -3 66 22 96 24 29 26 35 15 67
-10 29 -8 45 8 98 23 70 32 107 27 107 -2 0 -18 -20 -36 -45 -32 -44 -94 -95
-117 -95 -29 0 -23 33 15 88 24 34 50 92 67 144 31 102 66 151 101 142 17 -4
26 0 35 15 15 29 34 26 49 -6 l13 -28 14 36 c18 44 60 69 116 69 39 0 45 3 55
30 21 56 72 52 129 -10 21 -22 40 -40 44 -40 4 0 15 18 25 39 l18 40 86 -5
c47 -3 101 -7 119 -9 28 -3 32 -1 32 21 0 22 -4 24 -47 24 -30 0 -60 8 -80 20
-29 17 -33 25 -33 61 l0 42 -21 -27 c-18 -23 -27 -26 -84 -26 -64 0 -93 15
-82 43 15 40 -39 55 -83 22 -26 -19 -46 -25 -85 -25 -57 0 -106 -28 -158 -90
l-28 -33 -23 22 c-17 16 -35 21 -75 21 -49 0 -54 3 -94 46 -46 50 -32 48 -160
28 -33 -5 -39 -3 -43 14 -3 11 -17 25 -30 31 -40 18 -24 41 29 41 24 0 127
103 127 126 0 20 -30 2 -127 -77 -129 -105 -216 -168 -241 -174 -14 -3 -40
-19 -58 -34 -53 -47 -194 -52 -194 -7 0 7 18 29 40 49 22 19 40 41 40 48 0 7
-13 0 -29 -15 -21 -20 -36 -27 -51 -23 -12 3 -47 -6 -83 -24 -64 -30 -193 -66
-203 -57 -6 7 85 130 139 186 42 44 201 153 280 193 53 27 76 59 43 59 -8 0
-22 7 -31 16 -12 13 -30 15 -83 12 -57 -4 -70 -2 -89 14 l-21 20 -16 -30 c-38
-74 -83 -82 -137 -26 -49 51 -62 57 -110 44 -56 -15 -80 -8 -140 46 -43 38
-56 45 -78 39 -57 -16 -195 0 -288 32 -36 12 -44 20 -53 51 -5 20 -10 47 -10
58 0 12 -9 32 -20 45 l-19 24 -1 -31 c0 -16 -6 -38 -13 -49 -13 -17 -42 -18
-748 -12 -890 8 -1249 25 -1974 98 -482 48 -539 58 -1880 338 -689 144 -944
201 -1005 225 -19 8 -52 21 -72 30 l-37 16 -3 -48z"/>
    </g>
  </svg>
)

export default ({ map }) => {
  switch (map) {
    case 'africa': {
      return <AfricaMap />
    }

    case 'fr': {
      return <FranceMap />
    }

    case 'de': {
      return <GermanyMap />
    }

    case 'india': {
      return <IndiaMap />
    }

    case 'it': {
      return <ItalyMap />
    }

    case 'south_america': {
      return <SouthAmericaMap />
    }

    case 'es': {
      return <SpainMap />
    }

    case 'uk': {
      return <UnitedKingdomMap />
    }

    case 'us': {
      return <UsaMap />
    }

    default: {
      return null
    }
  }
}
