import React, { useState, useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import { InvoiceTicket } from '@fnd/components/Invoice'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper'
import classNames from 'classnames'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useParams } from 'react-router-dom'
import Empty from '@fnd/components/Empty'

function Invoice({ className }) {
  const [invoice, setInvoice] = useState()
  const [loading, setLoading] = useState(false)
  const { invoiceId } = useParams()

  const classes = classNames({
    [className]: className,
  })

  useEffect(() => {
    const getInvoice = async () => {
      setLoading(true)

      try {
        const invoiceData = await spotimatchEndpoints.getInvoice(invoiceId)
        setInvoice(invoiceData)
      }
      catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    getInvoice()
  }, [invoiceId])

  return (
    <Wrapper id="invoice" className={classes}>
      {loading && <Spinner />}

      {invoice ? <InvoiceTicket
        className="mb-10"
        accessToken={invoice?.access_token}
        invoiceId={invoice?._id}
        userId={invoice?.user_id}
        description={invoice?.description}
        amount={invoice?.amount}
        cover={invoice?.cover}
        discountPercent={invoice?.discount_percentage}
        discountAmount={invoice?.discount_amount}
        expirationDate={invoice?.expiration_date}
        discountExpirationDate={invoice?.discount_expiration}
        status={invoice?.status}
      /> : (
        <Empty />
      )}
    </Wrapper>
  )
}

export default Invoice
