import React from 'react'
import { useIntl } from 'react-intl'
import parse from 'html-react-parser'
import classNames from 'classnames'
import Button from '@fnd/components/Button'

const VipCampaignHeader = ({ className }) => {
  const intl = useIntl()

  const classes = classNames({
    'vip-campaign-header': true,
    'pt-20 pb-32': true,
    [className]: className,
  })

  const handleCtaClick = () => {
    const stepper = document.querySelector('.stepper')
    if (stepper) {
      stepper.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  return (
    <div className={classes}>
      <div className="grid md:grid-cols-2 items-center gap-16">
        <div className="flex flex-col items-start gap-4">
          <h1 className="m-0 max-w-[20ch]">
            {parse(intl.formatMessage({ id: 'vip_campaign.header.title' }))}
          </h1>

          <h2 className="font-light text-2xl opacity-80 m-0">
            {parse(intl.formatMessage({ id: 'vip_campaign.header.subtitle' }))}
          </h2>

          <p className="text-2xl font-semibold">
            {parse(intl.formatMessage({ id: 'vip_campaign.header.text' }))}
          </p>

          <Button
            label={intl.formatMessage({ id: 'messages.learn_more' })}
            variant="primary"
            icon="arrow-right"
            className="mt-4 md:min-w-[200px]"
            onClick={handleCtaClick}
          />
        </div>

        <div>
          <img
            className="rounded-xl border-outline"
            src="/images/vip/vip_campaign_mockup.jpg"
            alt="VIP Campaign"
          />
        </div>
      </div>

    </div>
  )
}

export default VipCampaignHeader
