import React from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { PLAN_CONFIG } from '@fnd/constants'
import { useIntl } from 'react-intl'

const PlansMockup = ({
  className,
}) => {
  const intl = useIntl()

  const classes = classNames({
    'plans-mockup': true,
    'flex flex-col gap-4': true,
    'iso-right': true,
    [className]: className,
  })

  const cardClasses = classNames({
    'flex flex-col items-start gap-2': true,
    'bg-primary p-4 rounded-lg border-outline': true,
    'transform transition-all duration-300 hover:-translate-x-8': true,
    'shadow-md hover:shadow-lg': true,
  })

  return (
    <div className={classes}>
      <div className={`${cardClasses} opacity-50 grayscale cursor-not-allowed`}>
        <h3 className={`inline-flex items-center gap-2 m-0 text-${PLAN_CONFIG.free.color}`}>
          <Icon className="text-xl" name={PLAN_CONFIG.free.icon} />
          {PLAN_CONFIG.free.title}
        </h3>

        <p className="font-light opacity-20 text-balance m-0">
          {intl.formatMessage({
            id: `plans.card.${PLAN_CONFIG.free.slug}.caption`
          })}
        </p>
      </div>

      <div className={cardClasses}>
        <h3 className={`inline-flex items-center gap-2 m-0 text-${PLAN_CONFIG.artist.color}`}>
          <Icon className="text-xl" name={PLAN_CONFIG.artist.icon} />
          {PLAN_CONFIG.artist.title}
        </h3>

        <p className="font-light opacity-80 text-balance m-0">
          {intl.formatMessage({
            id: `plans.card.${PLAN_CONFIG.artist.slug}.caption`
          })}
        </p>
      </div>

      <div className={cardClasses}>
        <h3 className={`inline-flex items-center gap-2 m-0 text-${PLAN_CONFIG.agency.color}`}>
          <Icon className="text-xl" name={PLAN_CONFIG.agency.icon} />
          {PLAN_CONFIG.agency.title}
        </h3>

        <p className="font-light opacity-80 text-balance m-0">
          {intl.formatMessage({
            id: `plans.card.${PLAN_CONFIG.agency.slug}.caption`
          })}
        </p>
      </div>
    </div>
  )
}

export default PlansMockup
