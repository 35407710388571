import React from 'react'
import { useIntl } from 'react-intl'
import parse from 'html-react-parser'
import Badge from '@fnd/components/Badge'
import Card from '@fnd/components/Card'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import ReportForm from './ReportForm'
import Wrapper from '@fnd/components/Wrapper/Wrapper'

function Report() {
  const intl = useIntl()

  const features = [
    {
      icon: '📊',
      text: intl.formatMessage({ id: 'report.page.features.1' }),
    },
    {
      icon: '📻',
      text: intl.formatMessage({ id: 'report.page.features.2' }),
    },
    {
      icon: '🫶',
      text: intl.formatMessage({ id: 'report.page.features.3' }),
    }
  ]

  return (
    <Wrapper>
      <SectionTitle
        title={intl.formatMessage({ id: 'report.page.title' })}
        subtitle={intl.formatMessage({ id: 'report.page.subtitle' })}
        center
        badge={
          <Badge
            variant="green-light"
            label={intl.formatMessage({ id: 'navigation.report' })}
            icon="check"
          />
        }
      />
      <div className="report-body">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-8/12 mx-auto">
            <p className="text-lg">
              {parse(intl.formatMessage({ id: 'report.page.description' }))}
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-8 my-8">
          {features.map((feature, index) => (
            <div className="w-full md:w-4/12" key={index}>
              <Card className="text-center p-5">
                <span className="text-4xl">
                  {feature.icon}
                </span>
                <p className="text-center mt-2">
                  {parse(feature.text)}
                </p>
              </Card>
            </div>
          ))}
        </div>

        <div className="flex flex-wrap items-center mt-10 mb-12">
          <div className="w-full md:w-8/12 mx-auto">
            <ReportForm />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Report
