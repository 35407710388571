import React, { useMemo } from 'react'
import classNames from 'classnames'
export const BackgroundSquare = ({
  squareSize = 90,
  gridGap = 5,
  cornerRadius = 4,
  minOpacity = 0.1,
  maxOpacity = 0.5,
  colorVariable = 'primary-text-color',
  rows = 5,
  columns = 5,
  className = ''
}) => {
  const patternSize = squareSize + gridGap

  const squares = useMemo(() => {
    const squaresArray = []
    for (let i = 0; i < rows * columns; i++) {
      const randomOpacity = Math.random() * (maxOpacity - minOpacity) + minOpacity
      squaresArray.push(randomOpacity)
    }
    return squaresArray
  }, [rows, columns, minOpacity, maxOpacity])

  const classes = classNames({
    'w-full h-full': true,
    [className]: className
  })

  return (
    <div className={classes}>
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="randomSquarePattern"
            width={patternSize * columns}
            height={patternSize * rows}
            patternUnits="userSpaceOnUse"
          >
            {squares.map((opacity, index) => {
              const x = (index % columns) * patternSize + gridGap / 2
              const y = Math.floor(index / columns) * patternSize + gridGap / 2
              return (
                <rect
                  key={index}
                  x={x}
                  y={y}
                  width={squareSize}
                  height={squareSize}
                  style={{
                    fill: `var(--${colorVariable})`,
                    fillOpacity: opacity
                  }}
                  rx={cornerRadius}
                  ry={cornerRadius}
                />
              )
            })}
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#randomSquarePattern)" />
      </svg>
    </div>
  )
}

export default BackgroundSquare
