import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import {
  COUNTERS,
  PLAN_CONFIG,
  PLAN_PRICES,
  PLAN_FEATURES_ARTIST as planFeaturesArtist,
  PLAN_FEATURES_AGENCY as planFeaturesAgency,
  PLANS,
  STRIPE_PLAN_SUBSCRIPTIONS,
} from '@fnd/constants'
import Button from '@fnd/components/Button'
import { PlanCard } from '@fnd/components/Plans'
import {
  useUserContext,
  userPlanSelector,
} from '@fnd/modules/User'
import { useVipCampaignStore } from '@fnd/store'

const VipCampaignPlans = ({
  campaign,
  setValue,
  className,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const { calculatePaymentTotal, setItem, resetItems } = useVipCampaignStore()

  const { plan } = useUserContext(({ user }) => ({
    plan: userPlanSelector(user),
  }))

  const intl = useIntl()
  const { locale } = intl

  const PLAN_FEATURES_ARTIST = planFeaturesArtist(intl)
  const PLAN_FEATURES_AGENCY = planFeaturesAgency(intl)

  const classes = classNames({
    [className]: className,
  })

  const togglePlan = (value) => {
    if (value === selectedPlan) {
      setSelectedPlan(null)
      setValue('addOns', [])
      resetItems()
      return
    }

    const addOn = {
      planId: STRIPE_PLAN_SUBSCRIPTIONS[value],
      plan: value,
      name: PLAN_CONFIG[value].title,
      price: PLAN_PRICES[value].vipPrice,
    }

    setItem(addOn)
    setValue('addOns', [addOn])
    setSelectedPlan(value)
  }

  useEffect(() => {
    if (campaign?.addOns?.length > 0) {
      setSelectedPlan(campaign?.addOns[0].plan)
    }

    if (plan?.name === PLANS.AGENCY) {
      resetItems()
    }
  }, [])

  useEffect(() => {
    calculatePaymentTotal(campaign, selectedPlan)
  }, [campaign.addOns])

  if (!plan || plan?.name === PLANS.AGENCY) return null

  return (
    <div className={classes}>
      <div className="flex flex-col items-start gap-2 mb-4">
        <h3 className="m-0">
          {intl.formatMessage({ id: 'vip_campaign.plans.title' })}
        </h3>

        <p className="font-light text-lg opacity-80 m-0">
          {intl.formatMessage({ id: 'vip_campaign.plans.text' })}
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        {plan?.name === PLANS.FREE && (
          <PlanCard
            className={classNames({
              'plan-card cursor-pointer': true,
              [PLAN_CONFIG.artist.color]: PLAN_CONFIG.artist.color,
              active: selectedPlan === PLANS.ARTIST
            })}
            title={PLAN_CONFIG.artist.title}
            slug={PLANS.ARTIST}
            icon={PLAN_CONFIG.artist.icon}
            color={PLAN_CONFIG.artist.color}
            duration={intl.formatMessage({ id: 'messages.year' }).toLowerCase()}
            displayMonthly={false}
            features={PLAN_FEATURES_ARTIST}
            caption={intl.formatMessage({ id: 'plans.card.artist.caption' })}
            price={PLAN_PRICES.artist.salePrice}
            salePrice={PLAN_PRICES.artist.vipPrice}
            socialProof={
              <FormattedMessage
                id="plans.card.agency.social_proof"
                values={{ plans: COUNTERS.ARTIST_PLAN_COUNT }}
              />
            }
            cta={
              <Button
                label={selectedPlan === PLANS.ARTIST ? (
                  <FormattedMessage id="messages.selected" />
                ) : (
                  <FormattedMessage id="messages.add" />
                )}
                icon={selectedPlan === PLANS.ARTIST ? 'check' : 'plus'}
                onClick={() => togglePlan(PLANS.ARTIST)}
              />
            }
          />
        )}

        {(plan?.name === PLANS.FREE || plan?.name === PLANS.ARTIST) && (
          <PlanCard
            className={classNames({
              'plan-card cursor-pointer': true,
              [PLAN_CONFIG.artist.color]: PLAN_CONFIG.artist.color,
              active: selectedPlan === PLANS.AGENCY
            })}
            title={PLAN_CONFIG.agency.title}
            slug={PLANS.AGENCY}
            icon={PLAN_CONFIG.agency.icon}
            color={PLAN_CONFIG.agency.color}
            duration={intl.formatMessage({ id: 'messages.year' }).toLowerCase()}
            displayMonthly={false}
            features={PLAN_FEATURES_AGENCY}
            caption={intl.formatMessage({ id: 'plans.card.free.caption' })}
            price={PLAN_PRICES.agency.salePrice}
            salePrice={PLAN_PRICES.agency.vipPrice}
            socialProof={
              <FormattedMessage
                id="plans.card.agency.social_proof"
                values={{ plans: COUNTERS.AGENCY_PLAN_COUNT }}
              />
            }
            cta={
              <Button
                label={selectedPlan === PLANS.AGENCY ? (
                  <FormattedMessage id="messages.selected" />
                ) : (
                  <FormattedMessage id="messages.add" />
                )}
                icon={selectedPlan === PLANS.AGENCY ? 'check' : 'plus'}
                onClick={() => togglePlan(PLANS.AGENCY)}
              />
            }
          />
        )}
      </div>
    </div>
  )
}

export default VipCampaignPlans
