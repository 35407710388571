import React from 'react'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'

const StepperItem = ({
  currentStep,
  enableNavigation = true,
  index,
  set,
  step,
  ...props
}) => {

  const classes = classNames({
    'step-item': true,
    'cursor-pointer': enableNavigation,
    'completed': index < currentStep.index,
    'active': currentStep.key === step.key
  })

  const handleStepClick = () => {
    if (!enableNavigation) return
    set(step.key)
  }

  return (
    <li className={classes} onClick={handleStepClick}>
      <span className="step-index">
        {step?.icon ? <Icon name={step.icon} className="icon" /> : index + 1}
      </span>

      {step?.label && <span className="step-label">
        {step.label}
      </span>}
    </li>
  )
}

export default StepperItem
