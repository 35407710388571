import React from 'react'
import Icon from '@fnd/components/Icon'
import Field from '@fnd/components/Field'
import { TIKTOK_CODE_TUTORIAL_URL } from '@fnd/constants'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'

const VipFormTikTok = ({
  control,
  errors,
}) => {
  const intl = useIntl()

  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-xl m-0">
        <Icon name="tiktok" type="brand" className="mr-2" />
        TikTok
      </h4>

      <div className="grid md:grid-cols-2 gap-4">
        <Controller
          name="info.tiktok.handle"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              iconBefore={<Icon name="user-circle" />}
              label={intl.formatMessage({
                id: 'field.tiktok_handle.label',
              })}
              errors={errors?.info?.tiktok?.handle}
              {...field}
            />
          )}
        />

        <Controller
          name="info.tiktok.videoUrl"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              iconBefore={<Icon name="link" />}
              label={intl.formatMessage({
                id: 'vip_campaign.field.tiktok_video_url.label',
              })}
              errors={errors?.info?.tiktok?.videoUrl}
              {...field}
            />
          )}
        />

        <Controller
          name="info.tiktok.videoCode"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              className="md:col-span-2"
              iconBefore={<Icon name="key" />}
              label={intl.formatMessage({
                id: 'vip_campaign.field.tiktok_video_code.label',
              })}
              description={
                <span className="inline-flex items-center gap-2">
                  {intl.formatMessage({
                    id: 'vip_campaign.field.tiktok_video_code.description',
                  })}
                  <a
                    href={TIKTOK_CODE_TUTORIAL_URL}
                    className="underline text-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage({
                      id: 'vip_campaign.field.tiktok_video_code.cta',
                    })}
                  </a>
                </span>
              }
              errors={errors?.info?.tiktok?.videoCode}
              {...field}
            />
          )}
        />
      </div>
    </div>
  )
}

export default VipFormTikTok
