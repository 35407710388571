import React, { useEffect } from 'react'
import {
  CompareSection,
  PlansHeader,
  Plans
} from '@fnd/components/Plans'
import {
  HowItWorks,
  PromoCompare,
  Mentions,
  Reviews
} from '@fnd/components/Home'
import Faq from '@fnd/screens/Faq'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import classNames from 'classnames'


const Pricing = ({
  landing,
  className
}) => {
  useEffect(() => {
    if (landing) {
      document.body.classList.add('landing')
    }

    return () => {
      document.body.classList.remove('landing')
    }
  }, [landing])

  const classes = classNames({
    [className]: className,
  })

  return (
    <Wrapper id="plans" className={classes}>
      <PlansHeader />

      <Plans />

      <HowItWorks showTitle />

      <PromoCompare className="mt-10" />

      <CompareSection />

      <Reviews />

      <Mentions />

      <Faq
        showTitle
        className="pb-16"
      />
    </Wrapper>
  )
}

export default Pricing
