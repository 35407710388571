import React from 'react'
import { useIntl } from 'react-intl'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import Card from '@fnd/components/Card'
import Icon from '@fnd/components/Icon'
import SectionTitle from '@fnd/components/SectionTitle'
import parse from 'html-react-parser'
import classNames from 'classnames'

const PromoCompare = ({ className }) => {
  const intl = useIntl()

  const classes = classNames({
    section: true,
    container: true,
    [className]: className,
  })

  const compareList = {
    traditional: [
      {
        title: 'promo_compare.traditional.safe.title',
        text: 'promo_compare.traditional.safe.text',
        icon: 'music-alt-slash'
      },
      {
        title: 'promo_compare.traditional.playlist.title',
        text: 'promo_compare.traditional.playlist.text',
        icon: 'volume-slash'
      }
    ],
    organic: [
      {
        title: 'promo_compare.organic.safe.title',
        text: 'promo_compare.organic.safe.text',
        icon: 'check-circle'
      },
      {
        title: 'promo_compare.organic.playlist.title',
        text: 'promo_compare.organic.playlist.text',
        icon: 'volume-up'
      }
    ]
  }

  return (
    <div id="promo-compare" className={classes}>
      <SectionTitle
        center
        tag="h3"
        className="pt-8"
        headingClasses="text-gradient-light-blue-fade text-balance max-w-[32ch] mx-auto"
        title={intl.formatMessage({ id: 'promo_compare.title' })}
        subtitle={intl.formatMessage({ id: 'promo_compare.subtitle' })}
        badge={
          <Badge
            variant="blue-light"
            label={intl.formatMessage({ id: 'promo_compare.badge' })}
            icon="volume-up"
          />
        }
      />

      <Card className="md:p-8 p-4">
        <div className="grid md:grid-cols-2 gap-16">
          <div>
            <h5>
              {intl.formatMessage({ id: 'promo_compare.traditional.title' })}
            </h5>

            <p className="text-lg mb-6">
              {intl.formatMessage({ id: 'promo_compare.traditional.text' })}
            </p>

            <img
              src="/images/home/matchfy_fake_promo.jpg"
              alt="Matchfy Fake Promo"
              className="rounded-xl border mb-4"
            />

            {compareList?.traditional.length > 0 && (
              <div className="flex flex-col gap-3">
                {compareList.traditional.map((item, index) => (
                  <div key={index} className="flex items-start gap-3 border-outline p-3 bg-secondary rounded-lg">
                    <Icon name={item.icon} />

                    <div className="flex flex-col gap-1">
                      <h6 className="text-balance mb-0">
                        {intl.formatMessage({ id: item.title })}
                      </h6>

                      <p className="text-balance">
                        {parse(intl.formatMessage({ id: item.text }))}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <h5 className="text-blue">
              {intl.formatMessage({ id: 'promo_compare.organic.title' })}
            </h5>

            <p className="text-lg mb-6">
              {intl.formatMessage({ id: 'promo_compare.organic.text' })}
            </p>

            <img
              src="/images/home/matchfy_legit_promo.jpg"
              alt="Matchfy Legit Promo"
              className="rounded-xl border mb-4"
            />

            {compareList?.organic.length > 0 && (
              <div className="flex flex-col gap-3">
                {compareList.organic.map((item, index) => (
                  <div key={index} className="flex items-start gap-3 border-outline p-3 bg-primary rounded-xl">
                    <Icon className="text-blue" name={item.icon} />

                    <div className="flex flex-col gap-1">
                      <h6 className="text-balance mb-0">
                        {intl.formatMessage({ id: item.title })}
                      </h6>

                      <p className="text-balance">
                        {parse(intl.formatMessage({ id: item.text }))}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <Alert variant="info" className="mt-4">
          <span>
            {parse(intl.formatMessage({ id: 'promo_compare.tip' }))}
          </span>
        </Alert>
      </Card>

    </div>
  )
}

export default PromoCompare
