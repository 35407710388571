import React from 'react'
import { PlanCard } from '@fnd/components/Plans'
import { FormattedMessage, useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import { isUserAuthorizedSelector, useUserContext } from '@fnd/modules/User'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import {
  AnalysisFeatures,
  AnalysisProcess,
  AnalysisSlider,
} from '@fnd/components/Analysis'
import { useQuery } from '@tanstack/react-query'

const Analysis = () => {
  const intl = useIntl()
  const features = [
    { text: intl.formatMessage({ id: 'analysis.card.get_analysis' }) },
    { text: intl.formatMessage({ id: 'analysis.card.identify' }) },
    { text: intl.formatMessage({ id: 'analysis.card.check_if' }) },
    { text: intl.formatMessage({ id: 'analysis.card.find_out' }) },
  ]

  const { data: { analysisPrice } } = useQuery({
    queryKey: ['analysis'],
    queryFn: () => spotimatchEndpoints.getAnalysisPrice().toPromise(),
    initialData: { analysisPrice: 0 },
  })

  const { isUserAuthorized } = useUserContext(
    ({ user, updateUserProfile }) => ({
      isUserAuthorized: isUserAuthorizedSelector(user),
      updateUser: (next) => updateUserProfile(next),
    })
  )

  return (
    <Wrapper id="analysis">
      <SectionTitle
        title={intl.formatMessage({ id: 'analysis.title' })}
        subtitle={intl.formatMessage({ id: 'analysis.subtitle' })}
        center
        badge={
          <Badge
            icon="file-chart-line"
            variant="primary-light"
            label={intl.formatMessage({ id: 'navigation.analysis' })}
          />
        }
      />
      <div className="flex flex-wrap justify-around pb-8">
        <PlanCard
          title={intl.formatMessage({ id: 'navigation.analysis' })}
          icon="file-chart-line"
          color="primary"
          caption={
            <FormattedMessage id="analysis.card.professional_feedback" />
          }
          features={features}
          captionIcon="file-chart-pie"
          price={analysisPrice}
          layout="analysis"
          slug="analysis"
          isActive={false}
          disabled={!isUserAuthorized}
        />
      </div>
      <div className="w-full md:w-3/4 mx-auto">
        <AnalysisProcess className="mb-32" />
        <AnalysisFeatures className="mb-16" />
        <AnalysisSlider className="mb-32" />
      </div>
    </Wrapper>
  )
}

export default Analysis
