import React from 'react'
import classNames from 'classnames'

const DeezerLogoIcon = ({ className }) => (
  <svg className={classNames({ [className]: className })} viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M125.806 22.8848C127.194 14.8411 129.23 9.78294 131.486 9.77325H131.491C135.697 9.78775 139.106 27.3294 139.106 48.9872C139.106 70.6447 135.692 88.2009 131.481 88.2009C129.755 88.2009 128.162 85.22 126.879 80.2247C124.852 98.5103 120.646 111.081 115.777 111.081C112.009 111.081 108.628 103.53 106.358 91.6212C104.808 114.269 100.907 130.338 96.3475 130.338C93.4862 130.338 90.8775 123.97 88.9462 113.603C86.6237 135.004 81.2586 150 75.0014 150C68.7445 150 63.3701 135.009 61.057 113.603C59.1398 123.97 56.5313 130.338 53.6554 130.338C49.0963 130.338 45.2047 114.269 43.6453 91.6212C41.3753 103.53 38.0037 111.081 34.2265 111.081C29.3621 111.081 25.1511 98.5153 23.1243 80.2247C21.851 85.2347 20.2486 88.2009 18.5222 88.2009C14.3112 88.2009 10.8966 70.6447 10.8966 48.9872C10.8966 27.3294 14.3112 9.77325 18.5222 9.77325C20.7827 9.77325 22.8048 14.8459 24.2069 22.8848C26.4531 9.01478 30.1015 0 34.2265 0C39.1243 0 43.3687 12.7492 45.3766 31.2619C47.3413 17.788 50.3219 9.19838 53.6604 9.19838C58.3385 9.19838 62.316 26.0926 63.7898 49.6588C66.5604 37.5759 70.5711 29.9961 75.0111 29.9961C79.4511 29.9961 83.4618 37.5809 86.2277 49.6588C87.7062 26.0926 91.6787 9.19838 96.3572 9.19838C99.6906 9.19838 102.667 17.788 104.641 31.2619C106.644 12.7492 110.888 0 115.786 0C119.897 0 123.56 9.01963 125.806 22.8848ZM0 45.1303C0 35.4488 1.93623 27.5982 4.32549 27.5982C6.71476 27.5982 8.65099 35.4488 8.65099 45.1303C8.65099 54.8116 6.71476 62.6622 4.32549 62.6622C1.93623 62.6622 0 54.8116 0 45.1303ZM141.349 45.1303C141.349 35.4488 143.285 27.5982 145.674 27.5982C148.064 27.5982 150 35.4488 150 45.1303C150 54.8116 148.064 62.6622 145.674 62.6622C143.285 62.6622 141.349 54.8116 141.349 45.1303Z" fill="#A238FF" />
  </svg>
)

export default DeezerLogoIcon
