import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Icon from '@fnd/components/Icon'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'
import SubNavItem from './SubNavItem'
import classNames from 'classnames'


const NavigationDropdown = ({
  icon,
  label,
  to,
  items,
  className
}) => {
  const location = useLocation()
  const classes = classNames('sub-menu-trigger', className)

  return (
    <li className={classes}>
      <NavLink
        to={to}
        className={classNames({
          'active': location.pathname === to || items.some(item => location.pathname === item.to)
        })}
      >
        {icon && <Icon name={icon} />}
        {label}
      </NavLink>

      <ul className="sub-menu">
        {items.map((item, index) => (
          <SubNavItem
            key={index}
            to={item.to}
            icon={item.icon}
            color={item.color}
            iconType={item.iconType ?? DEFAULT_ICON_TYPE}
            title={item.title}
            subtitle={item.subtitle}
          />
        ))}
      </ul>
    </li>
  )
}

export default NavigationDropdown
