import React from 'react'
import Icon from '@fnd/components/Icon'
import Field from '@fnd/components/Field'
import { CAMPAIGN_BUDGET_OPTIONS } from '../config'
import { Controller, useForm } from 'react-hook-form'
import { useVipCampaignStore } from '@fnd/store'
import { formatCurrency } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'

const VipFormInstagram = ({
  control,
  errors,
  plan,
  profile,
  setValue
}) => {
  const { form } = useVipCampaignStore()
  const budgetConfig = CAMPAIGN_BUDGET_OPTIONS.find((item) => item.id === 'instagram')
  const intl = useIntl()


  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-xl m-0">
        <Icon name="instagram" type="brand" className="mr-2" />
        Instagram
      </h4>

      <div className="grid md:grid-cols-2 gap-4">
        <Controller
          name="info.instagram.postUrl"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="url"
              iconBefore={<Icon name="link" />}
              label={intl.formatMessage({
                id: 'field.instagram_post_reel.label',
              })}
              errors={errors?.info?.instagram?.name}
              {...field}
            />
          )}
        />

        <Controller
          name="info.instagram.name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              iconBefore={<Icon name="user-circle" />}
              label={intl.formatMessage({
                id: 'field.name.label',
              })}
              errors={errors?.info?.instagram?.name}
              {...field}
            />
          )}
        />

        <Controller
          name="info.instagram.phone"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="phone"
              label={intl.formatMessage({
                id: 'field.phone.label',
              })}
              errors={errors?.info?.instagram?.name}
              {...field}
            />
          )}
        />

        <Controller
          name="info.instagram.email"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="email"
              iconBefore={<Icon name="envelope" />}
              label="Email"
              errors={errors?.info?.instagram?.name}
              {...field}
            />
          )}
        />

        <Controller
          name="budget.instagram"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              type="slider"
              label="Budget"
              className="md:col-span-2"
              min={budgetConfig?.min}
              max={budgetConfig?.max}
              step={budgetConfig?.step}
              showLabels={true}
              defaultValue={[parseFloat(budgetConfig?.max / 2)]}
              formatLabelFn={formatCurrency}
              {...field}
            />
          )}
        />
      </div>
    </div>
  )
}

export default VipFormInstagram
