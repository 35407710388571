import React from 'react'
import Card from '@fnd/components/Card'
import Icon from '@fnd/components/Icon'
import { getCampaignOption } from '../utils'
import { useIntl } from 'react-intl'

const VipInfoInstagram = () => {
  const intl = useIntl()

  const campaignOption = getCampaignOption('instagram', intl)

  return (
    <Card className="bg-primary p-4 border-outline">
      <h3 className="inline-flex gap-3 text-xl mb-2">
        <Icon name="instagram" type="brands" />
        {campaignOption.label} - {intl.formatMessage({ id: 'messages.how_it_works' })}
      </h3>

      <p className="text-sm text-balance opacity-90">
        {intl.formatMessage({ id: 'vip_campaign.info.instagram' })}
      </p>

      <p className="text-sm text-balance opacity-90">
        {intl.formatMessage({ id: 'vip_campaign.info.instagram.how_it_works' })}
      </p>
    </Card>
  )
}

export default VipInfoInstagram
