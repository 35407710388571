import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import classNames from "classnames"

const Checkbox = React.forwardRef(({
  className,
  label,
  labelClasses,
  id,
  ...props
}, ref) => {
  const classes = classNames({
    "flex items-center gap-2": true,
    [className]: className,
  })

  const labelCn = classNames({
    "m-0 text-sm cursor-pointer": true,
    [labelClasses]: labelClasses,
  })

  return (
    <div className={classes}>
      <CheckboxPrimitive.Root
        ref={ref}
        id={id}
        className={classNames(
          "checkbox relative peer h-5 w-5 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary"
        )}
        {...props}
      />

      {label && (
        <label
          htmlFor={id}
          className={labelCn}
        >
          {label}
        </label>
      )}
    </div>
  )
})

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
export default Checkbox
