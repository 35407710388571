import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import { Swiper } from 'swiper'
import parse from 'html-react-parser'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import { Pagination } from 'swiper/modules'

const Artists = ({ className }) => {
  useEffect(() => {
    new Swiper('.slider-artists', {
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      spaceBetween: 0,
      breakpoints: {
        992: { slidesPerView: 3 },
        640: { slidesPerView: 2 },
        480: { slidesPerView: 1 },
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    })
  }, [])

  const artists = [
    {
      image: '/images/home/artists/artist_amistat.jpg',
      name: 'Amistat',
      quote: `We first started working with Matchfy a few months ago and our monthly listeners were very low. Over the course of a few months we reached over <b>50K</b> and got added to some good playlists!`,
    },
    {
      image: '/images/home/artists/artist_hardbros.jpg',
      name: 'Hardbros',
      quote: `Matchfy è una bomba! Grazie a questa piattaforma siamo riusciti a mettere la nostra <b>Namaste</b> in tantissime playlist e abbiamo raggiunto in soli <b>3 mesi 100k plays</b> e <b>20k ascoltatori mensili</b>!`,
    },
    {
      image: '/images/home/artists/artist_replicage.jpg',
      name: 'Replicage',
      quote: `Thanks to Matchfy we reached more than <b>600k plays</b> and we&apos;re so happy to keep working with them. Can't wait to promote another one and can't wait to hear it!`,
    },
    {
      image: '/images/home/artists/artist_jung_belial.jpg',
      name: 'Jung Belial',
      quote: `Thanks to Matchfy&apos;s VIP service, we managed to get a <b>Golden Record in Italy.</b> Congrats to the Team!`,
    },
    {
      image: '/images/home/artists/artist_phil_weeks.jpg',
      name: 'Phil Weeks',
      quote: `Since I started using Matchfy, my music has been included in several playlists I thought I didn't have access to.`,
    },
    {
      image: '/images/home/artists/artist_planet_funk.jpg',
      name: 'Planet Funk',
      quote: ` Matchfy allowed us to include our music in two playlists we thought we didn't have access to. <b>Plays have increased by 40%</b> since then!`,
    },
    {
      image: '/images/home/artists/artist_banana_grume.jpg',
      name: 'Banana Grume',
      quote: `Simple and effective, Matchfy is the best way to approach playlist curators`,
    },
    {
      image: '/images/home/artists/artist_visory.jpg',
      name: 'Visory Records',
      quote: `Thanks to Matchfy&apos;s VIP service, we managed to get a <b>Golden Record in Italy.</b> Congrats to the Team!`,
    },
  ]

  const intl = useIntl()

  const classes = classNames({
    artists: true,
    section: true,
    container: true,
    [className]: className,
  })

  return (
    <div id="artists" className={classes}>
      <SectionTitle
        tag="h3"
        title={intl.formatMessage({ id: 'home.trusted_by.title' })}
        subtitle={intl.formatMessage({ id: 'home.trusted_by.subtitle' })}
        center
        badge={
          <Badge
            variant="primary-light"
            icon="star"
            label={intl.formatMessage({ id: 'navigation.my_artists' })}
          />
        }
      />

      <div className="slider slider-artists">
        <div className="swiper-wrapper">
          {artists.map((artist, index) => (
            <div className="swiper-slide" key={index}>
              <div className="artist-quote">
                <q>{parse(artist.quote)}</q>
                <div className="artist-info">
                  <div className="artist-image">
                    <img src={artist.image} alt={artist.name} />
                  </div>
                  <h5>{artist.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  )
}

export default Artists
