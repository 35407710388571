import React from 'react'
import { useIntl } from 'react-intl'
import { MATCHFY_DISTRO_URL, PLANS } from '@fnd/constants'
import { DistroLogo } from '@fnd/components/Logo/Logo'
import Auth from '@fnd/components/Auth'
import Button from '@fnd/components/Button'
import Card from '@fnd/components/Card'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import parse from 'html-react-parser'

export const DistributionStartNow = () => {
  const intl = useIntl()

  return (
    <section className="max-w-screen-md mx-auto my-16">
      <Auth
        login
        plans={[PLANS.ARTIST, PLANS.AGENCY]}
        fallback={<AccountUpgrade
          icon="music-slash"
          btnVariant="gradient"
          plan={PLANS.ARTIST}
          message={intl.formatMessage({ id: 'profile.upgrade.distribution' }, {
            plan: PLANS.ARTIST
          })}
        />}
      >
        <Card className="px-6 py-8 border-outline">
          <DistroLogo className="max-w[70%] md:max-w-[400px] mx-auto" />

          <div className="flex flex-col gap-4 mt-6">
            <p className="text-lg text-balance text-center mb-3">
              {parse(intl.formatMessage({ id: 'distribution.cta.text' }))}
            </p>

            <Button
              large
              to={MATCHFY_DISTRO_URL}
              target="_blank"
              icon="rocket"
              rel="noopener noreferrer"
              variant="gradient"
              className="max-w-[300px] mx-auto"
              label={intl.formatMessage({ id: 'distribution.cta.button' })}
            />
          </div>
        </Card>
      </Auth>
    </section>
  )
}

export default DistributionStartNow
