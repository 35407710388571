import React from 'react'
import Auth from '@fnd/components/Auth'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Card from '@fnd/components/Card'
import Icon from '@fnd/components/Icon'
import SectionTitle from '@fnd/components/SectionTitle'
import { useIntl, FormattedMessage } from 'react-intl'
import { ROUTES } from '@fnd/screens/constants'
import parse from 'html-react-parser'
import classNames from 'classnames'

const FeatureItem = ({ icon, title, desc, auto, variant }) => (
  <li className="feature-item">
    {icon && (
      <Icon
        name={icon}
        className="text-2xl mt-[0.1rem]"
      />
    )}

    <div className="flex flex-col gap-1">
      <h4 className="inline-flex items-center text-xl text-balance mb-0">
        {title}

        {auto && (
          <Badge
            variant={`${variant}-light`}
            className="uppercase font-bold ml-3"
            icon="sparkles"
            label="Auto"
          />
        )}
      </h4>

      <p className="text-sm md:text-base opacity-90 text-balance">
        {parse(desc)}
      </p>
    </div>
  </li>
)

const Features = ({
  showTitle = false,
  className
}) => {
  const intl = useIntl()

  const features = [
    {
      image: '/images/home/matchfy_features_artists.jpg',
      title: 'home.features.artist.title',
      color: 'primary',
      ctaLabel: 'home.features.artist.cta',
      ctaLink: ROUTES.MATCH,
      list: [
        {
          icon: 'music',
          title: 'home.features.artist.list.1.title',
          body: 'home.features.artist.list.1.body'
        },
        {
          icon: 'user-plus',
          title: 'home.features.artist.list.2.title',
          body: 'home.features.artist.list.2.body',
          auto: true
        },
        {
          icon: 'fast-forward',
          title: 'home.features.artist.list.3.title',
          body: 'home.features.artist.list.3.body',
          auto: true
        }
      ]
    },
    {
      image: '/images/home/matchfy_features_curators.jpg',
      title: 'home.features.curator.title',
      color: 'secondary',
      ctaLabel: 'home.features.curator.cta',
      ctaLink: `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLISTS}`,
      list: [
        {
          icon: 'comment-music',
          title: 'home.features.curator.list.1.title',
          body: 'home.features.curator.list.1.body',
          auto: true
        },
        {
          icon: 'list-music',
          title: 'home.features.curator.list.2.title',
          body: 'home.features.curator.list.2.body',
          auto: true
        },
        {
          icon: 'sack-dollar',
          title: 'home.features.curator.list.3.title',
          body: 'home.features.curator.list.3.body'
        }
      ]
    }
  ]

  const classes = classNames({
    features: true,
    section: true,
    'container': true,
    [className]: className,
  })

  return (
    <div id="features" className={classes}>
      {showTitle && (
        <SectionTitle
          tag="h3"
          center
          title={intl.formatMessage({ id: 'features.title' })}
          subtitle={intl.formatMessage({ id: 'features.subtitle' })}
          badge={
            <Badge
              variant="primary-light"
              icon="list-music"
              label={intl.formatMessage({ id: 'affiliate.how_does_it_work.title' })}
            />
          }
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
        {features.map((feature, index) => (
          <Card
            key={index}
            className={`card-features card-${feature.color}`}
            image={feature.image}
            overlayTitle={<FormattedMessage id={feature.title} />}
            overlay={true}
            overlayColor={feature.color}
            footer={
              <Auth
                login
                loginClasses="button-wide"
                loginVariant={feature.color}
              >
                <Button wide to={feature.ctaLink} variant={feature.color}>
                  <FormattedMessage id={feature.ctaLabel} />
                </Button>
              </Auth>
            }
          >
            {feature.list.map((item, index) => (
              <FeatureItem
                key={index}
                auto={item.auto}
                icon={item.icon}
                variant={feature.color}
                title={intl.formatMessage({ id: item.title })}
                desc={intl.formatMessage({ id: item.body })}
              />
            ))}
          </Card>
        ))}
      </div>
    </div >
  )
}

export default Features
