import React, { useState } from 'react'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Badge from '@fnd/components/Badge'
import { ChartItem } from '@fnd/components/Rank'
import SectionTitle from '@fnd/components/SectionTitle'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { useTabs, TabLinks } from '@fnd/components/Tabs'
import { buildSpotifyTrackUrl } from '@fnd/core/libs/platforms'
import qs from 'query-string'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERIES } from '@fnd/constants'
import { useIntl } from 'react-intl'

const PopularSubmissions = () => {
  const [query, setQuery] = useState({
    type: 'weekly',
    limit: 10,
    match_type: 'match'
  })

  const queryClient = useQueryClient()
  const intl = useIntl()

  const limitOptions = [
    {
      id: 10,
      icon: 'crown',
      label: 'Top 10',
      onClick: () => setQuery({ ...query, limit: 10 })
    },
    {
      id: 50,
      icon: 'list',
      label: 'Top 50',
      onClick: () => setQuery({ ...query, limit: 50 })
    },
  ]

  const matchTypes = [
    {
      id: 'match',
      label: 'Match',
      icon: 'music',
      onClick: () => setQuery({ ...query, match_type: 'match' })
    },
    {
      id: 'vip',
      label: 'VIP',
      icon: 'gem',
      onClick: () => setQuery({ ...query, match_type: 'vip' })
    }
  ]

  const timeFrames = [
    {
      id: 'daily',
      label: intl.formatMessage({ id: 'messages.daily' }),
      icon: 'calendar-day',
      onClick: () => setQuery({ ...query, type: 'daily' })
    },
    {
      id: 'weekly',
      label: intl.formatMessage({ id: 'messages.weekly' }),
      icon: 'calendar-week',
      onClick: () => setQuery({ ...query, type: 'weekly' })
    },
    {
      id: 'monthly',
      label: intl.formatMessage({ id: 'messages.monthly' }),
      icon: 'calendar',
      onClick: () => setQuery({ ...query, type: 'monthly' })
    }
  ]

  const limitTabs = {
    tabs: limitOptions,
    initialTabId: query.limit,
  }

  const matchTypeTabs = {
    tabs: matchTypes,
    initialTabId: query.match_type,
  }

  const timeFrameTabs = {
    tabs: timeFrames,
    initialTabId: query.type,
  }

  const limitFilter = useTabs(limitTabs)
  const matchTypeFilter = useTabs(matchTypeTabs)
  const timeFrameFilter = useTabs(timeFrameTabs)

  const { data: popularSubmissions, isPending, isLoading } = useQuery({
    queryKey: [QUERIES.SUBMISSION.POPULAR, { ...query }],
    queryFn: () => {
      const q = qs.stringify({ ...query })
      return spotimatchEndpoints.getPopularSubmissions(q)
    },
  }, queryClient)

  return (
    <Wrapper>
      <SectionTitle
        title={intl.formatMessage({ id: 'popular.title' })}
        subtitle={intl.formatMessage({ id: 'popular.subtitle' })}
        headingClasses="text-gradient-gold-fade"
        badge={
          <Badge
            variant="gold-light"
            label={intl.formatMessage({ id: 'navigation.popular' })}
            icon="star"
          />
        }
        center
      />

      <div className="flex flex-wrap justify-center gap-3 mb-8">
        <TabLinks
          {...limitFilter.tabProps}
          inline
          variant="toggle"
          className="tab-light"
          selectedTabIndex={limitOptions.findIndex((type) => type.id === query.limit)}
          showIndicator={false}
          showHover={false}
        />

        <TabLinks
          {...matchTypeFilter.tabProps}
          inline
          variant="toggle"
          className="tab-light"
          selectedTabIndex={matchTypes.findIndex((type) => type.id === query.match_type)}
          showIndicator={false}
          showHover={false}
        />

        <TabLinks
          {...timeFrameFilter.tabProps}
          inline
          variant="toggle"
          className="tab-light"
          selectedTabIndex={timeFrames.findIndex((type) => type.id === query.type)}
          showIndicator={false}
          showHover={false}
        />
      </div>

      <div className="max-w-3xl mx-auto mb-8">
        {(isPending || isLoading) && <Spinner />}

        {popularSubmissions?.length > 0 && (
          <ul className="chart-list">
            {popularSubmissions.map((submission, index) => (
              <ChartItem
                key={index}
                position={index + 1}
                url={buildSpotifyTrackUrl(submission?._id)}
                title={submission?.track?.name}
                image={submission?.track?.image}
                positionDelta={submission?.positionDelta}
                chartStreak={submission?.chartStreak}
                artists={submission?.artists}
                previewUrl={submission?.track?.preview_url}
                total={submission?.total_submissions}
                averageMatchRate={submission?.avg_matchRate}
                timeFrame={query.type}
              />
            ))}
          </ul>
        )}
      </div>
    </Wrapper>
  )
}

export default PopularSubmissions
