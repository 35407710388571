import React, { memo, useMemo } from 'react'
import parse from 'html-react-parser'
import { FormattedMessage } from 'react-intl'
import Spinner from '@fnd/components/Spinner'
import { isEmpty } from 'lodash-es'
import Icon from '@fnd/components/Icon'
import { Placeholder } from '@fnd/components/Placeholder'

function Thumbnail({ error, entity, isLoading, isShrink }) {
  const TrackOwners = () => {
    let trackOwners = []
    if (!isEmpty(entity.owners)) {
      entity.owners.forEach((owner) => {
        trackOwners.push(owner.name)
      })
      return (<p className="mt-1">{trackOwners.join(', ')}</p>)
    }
    return null
  }

  return (
    <div className="thumbnail-image">
      {isLoading && <Spinner />}

      {isEmpty(entity) && !isLoading && !error && (
        <Placeholder
          variant="large"
          icon="music"
          label={<FormattedMessage id="match.empty_track_url_message" />}
        />
      )}

      {!isLoading && error && (
        <Placeholder
          variant="large"
          icon="music"
          label={<FormattedMessage id="match.not_valid_url_message" />}
        />
      )}

      {!isLoading && !isEmpty(entity) && !error && (
        <a
          className="thumbnail-link"
          href={entity.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className={`thumbnail-bg ${!isShrink ? 'thumbnail-bg-auto' : ''}`}
            style={{
              position: 'relative',
              backgroundImage: `url("${entity.picture}")`,
            }}
          >
            <div className="overlay" />
            {entity.featured && (
              <div className="badge badge-green">
                <Icon name="star" />{' '}
                <FormattedMessage id="match.filters.display.verified" />
              </div>
            )}
            <div className="thumbnail-inner text-center">
              <h3 className="thumbnail-title font-medium m-0">
                {entity.title}
              </h3>
              {entity.description && (
                <p className="thumbnail-album text-lg font-light mt-2">
                  {parse(entity.description)}
                </p>
              )}
              <TrackOwners />
            </div>
          </div>
        </a>
      )}
    </div>
  )
}

export default memo(Thumbnail)
