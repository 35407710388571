import React, { useEffect } from 'react'
import { AuthArtist } from '@fnd/components/Auth'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import Genres from '@fnd/modules/Analysis/Search/Genres'
import Button from '@fnd/components/Button'
import classNames from 'classnames'
import MatchTrack from './MatchTrack'
import MatchRecent from './MatchRecent'
import { PLANS } from '@fnd/constants'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { useMatchStore, useRecentMatchStore } from '@fnd/store'
import { FormattedMessage } from 'react-intl'


export const MatchForm = ({
  onSubmit,
  btnLabel = 'Match',
  btnVariant = 'gradient',
  matchTypeEnabled,
  className
}) => {
  const {
    track, setTrack,
    trackId, setTrackId,
    genres, setGenres,
    isMatching, setIsMatching,
    matchType, setMatchType,
    canMatch, setCanMatch,
  } = useMatchStore()

  const { addMatch } = useRecentMatchStore()

  const { profile } = useUserContext(
    ({ user }) => ({
      profile: userProfileSelector(user),
    })
  )

  useEffect(() => {
    setMatchType(matchTypeEnabled)
    return () => {
      setIsMatching(false)
      setMatchType(null)
    }
  }, [])

  useEffect(() => {
    setIsMatching(false)
    return () => {
      setIsMatching(false)
    }
  }, [track, genres])


  const handleTrackChange = (track) => {
    setTrack(track)
    setTrackId(track.id)
  }

  const handleClear = () => {
    setTrack(null)
    setGenres(null)
  }

  const populateMatch = (match) => {
    setTrack(match.track)
    setGenres(match.genres)
    setTrackId(match.track.id)
  }

  const handleSubmit = () => {
    addMatch({ track, genres, type: matchType })
    setIsMatching(true)

    if (onSubmit) {
      onSubmit({
        track,
        genres
      })
    }
  }

  const classes = classNames({
    'match-form': true,
    [className]: className,
  })

  const handleArtistSelect = () => {
    setCanMatch(true)
  }

  return (
    <AuthArtist
      onArtistSelect={() => handleArtistSelect()}
      className="mb-8"
    >
      <div className={classes}>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="flex flex-col items-start gap-8 md:col-span-2">
            <SearchSelect
              entity="track"
              className="w-full"
              title={<FormattedMessage id="match.first_step" />}
              name="track"
              tooltipText={
                <img
                  src='/images/matchfy_select_tutorial.gif'
                  alt='tutorial'
                  loading='lazy'
                  className='max-w-full sm:max-w-4xl rounded-sm'
                />
              }
              label={<FormattedMessage id="messages.search_track" />}
              defaultValue={trackId}
              artistIds={profile?.spotify_artist_id}
              limitBy={profile?.plan?.name !== PLANS.AGENCY ? 'artist' : null}
              value={trackId}
              onDataChange={handleTrackChange}
              onClear={handleClear}
              isDisabled={false}
            />

            <MatchRecent
              artists={profile?.spotify_artist_id}
              plan={profile?.plan?.name}
              onClick={populateMatch}
            />

            <Genres
              title="Step 2"
              className="w-full"
              genres={genres}
              onGenreSelect={(genres) => setGenres(genres)}
            />
          </div>

          <MatchTrack />
        </div>

        <Button
          icon="list-music"
          variant={btnVariant}
          onClick={handleSubmit}
          className="w-full mt-6"
          label={btnLabel}
          disabled={!trackId || !genres?.length || isMatching || !canMatch}
        />
      </div>
    </AuthArtist>
  )
}

export default MatchForm
