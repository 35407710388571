import Button from '@fnd/components/Button/Button'
import Emoji from '@fnd/components/Emoji'
import { CompareSection } from '@fnd/components/Plans'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Faq, { Faqs } from '@fnd/screens/Faq'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Reviews from '@fnd/components/Home/Reviews'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { useConsent } from '@fnd/context/ConsentProvider'
import parse from 'html-react-parser'
import Badge from '@fnd/components/Badge'

const Affiliate = () => {
  const intl = useIntl()
  const { handleIntercomToggle } = useConsent()

  return (
    <Wrapper id="affiliate">
      <SectionTitle
        title={intl.formatMessage({ id: 'affiliate.page.title' })}
        subtitle={intl.formatMessage({ id: 'affiliate.page.subtitle' })}
        badge={
          <Badge
            variant="gold-light"
            label={intl.formatMessage({ id: 'navigation.affiliate' })}
            icon="sack-dollar"
          />
        }
        center
      />

      <div className="w-full md:w-10/12 mx-auto">
        <p className="text-xl leading-8 text-center">
          {parse(intl.formatMessage({ id: 'affiliate.intro' }))}
        </p>
      </div>

      <div className="w-full md:w-8/12 mt-10 mx-auto">
        <h4 className="mt-8">
          {parse(intl.formatMessage({ id: 'affiliate.how_does_it_work.title' }))}
        </h4>

        <ul className="list-card">
          <li>
            <Emoji className="text-4xl block mb-2" symbol="🫰" label="Hand with Index Finger and Thumb Crossed" />
            {parse(intl.formatMessage({ id: 'affiliate.how_does_it_work.1' }))}
          </li>
          <li>
            <Emoji className="text-4xl block mb-2" symbol="💶" label="Euro Banknote" />
            {parse(intl.formatMessage({ id: 'affiliate.how_does_it_work.2' }))}
          </li>
        </ul>

        <img
          className="rounded-xl border-outline my-8"
          src="/images/affiliate/affiliate_banner.jpg"
          alt="Matchfy.io Affiliate Program Banner"
        />

        <h4>
          {parse(intl.formatMessage({ id: 'affiliate.why.title' }))}
        </h4>

        <ul className="list-card mb-6">
          <li>
            <Emoji className="text-4xl block mb-2" symbol="📈" label="Chart Increasing" />
            {parse(intl.formatMessage({ id: 'affiliate.why.1' }))}
          </li>
          <li>
            <Emoji className="text-4xl block mb-2" symbol="🎙️" label="Studio Microphone" />
            {parse(intl.formatMessage({ id: 'affiliate.why.2' }))}
          </li>
        </ul>

        <p className="text-lg mb-3">
          {parse(intl.formatMessage({ id: 'affiliate.cta_text.1' }))}
        </p>

        <p className="text-lg">
          {parse(intl.formatMessage({ id: 'affiliate.cta_text.2' }))}
        </p>

        <Button
          label={<FormattedMessage id="affiliate.cta" />}
          className="button-wide mt-5 toggle-intercom"
          onClick={handleIntercomToggle}
        />
      </div>

      <Reviews />

      <CompareSection />

      <Faq
        showTitle
        className="pb-16"
      />
    </Wrapper>
  )
}

export default Affiliate
