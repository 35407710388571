import React from "react"
import Button from "@fnd/components/Button"

export function TableResetButton({
  table,
  btnIcon = 'redo',
  btnVariant = 'light',
}) {
  return (
    <Button
      variant={btnVariant}
      icon={btnIcon}
      onClick={() => table.resetColumnFilters()}
    >
      Reset
    </Button>
  )
}
