import React, { useState, useEffect } from 'react'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import Emoji from '@fnd/components/Emoji'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Confetti from 'react-confetti'
import { PAYMENT_REDIRECT_TIMEOUT } from '@fnd/constants'
import { EVENTS, trackEvent } from '@fnd/core/libs/event-tracker'
import { useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { ROUTES } from '@fnd/screens/constants'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  useUserContext,
} from '@fnd/modules/User'
import {
  useDiscountStore,
  useVipCampaignStore
} from '@fnd/store'
import * as Sentry from '@sentry/react'

const PaymentRedirect = () => {

  const intl = useIntl()
  const { resetForm } = useVipCampaignStore()
  const { resetDiscount } = useDiscountStore()
  const [status, setStatus] = useState(null)

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isUserAuthorized: isUserAuthorizedSelector(user),
  }))

  useEffect(() => {
    const handlePaymentRedirect = async (profileData) => {
      const params = new URLSearchParams(window.location.search)
      const intentId = params.get('payment_intent')
      const intentSecret = params.get('payment_intent_client_secret')
      const redirectStatus = params.get('redirect_status')

      if (!intentId || !intentSecret || !redirectStatus) return

      const paymentIntent = await spotimatchEndpoints
        .getPaymentIntent(intentId)
        .toPromise()

      setStatus(redirectStatus)

      if (
        intentId &&
        intentSecret &&
        redirectStatus &&
        paymentIntent?.status &&
        paymentIntent?.metadata
      ) {
        try {
          const { amount, plan } = paymentIntent.metadata
          let transactionStatus = 'FAILED'

          switch (redirectStatus) {
            case 'succeeded':
              transactionStatus = 'COMPLETED'
              break
            case 'processing':
              transactionStatus = 'PENDING'
              break
            default:
              transactionStatus = 'FAILED'
              break
          }

          if (transactionStatus === 'COMPLETED') {
            await trackEvent(EVENTS.PURCHASE, {
              status: transactionStatus,
              price: amount / 100,
              plan,
              profile: profileData,
            }, intentSecret)
          }

          setTimeout(() => {
            resetDiscount()
            switch (plan) {
              case 'analysis':
                window.location.href = ROUTES.ANALYSIS
                break
              case 'profile_featured':
                window.location.href = ROUTES.CURATORS
                break
              case 'playlist_export':
                window.location.href = ROUTES.MATCH
                break
              case 'vip':
                resetForm()
                window.location.href = `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.VIP_CAMPAIGNS}`
                break
              default:
                window.location.href = ROUTES.MATCH
                break
            }
          }, PAYMENT_REDIRECT_TIMEOUT)
        } catch (e) {
          Sentry.captureException(e)
          console.error(e)
        }
      }
    }

    if (!profile || !profile?.spotify_id) return
    handlePaymentRedirect(profile)
  }, [profile])

  const getContent = () => {
    switch (status) {
      case 'succeeded':
        return {
          title: intl.formatMessage({ id: 'payment.success.title' }),
          subtitle: intl.formatMessage({ id: 'payment.success.subtitle' }),
          emoji: <Emoji symbol="🎉" label="party popper" />,
        }
      case 'failed':
        return {
          title: intl.formatMessage({ id: 'payment.failed.title' }),
          subtitle: intl.formatMessage({ id: 'payment.failed.subtitle' }),
          emoji: <Emoji symbol="😢" label="crying face" />,
        }
      case 'processing':
        return {
          title: intl.formatMessage({ id: 'payment.processing.title' }),
          subtitle: intl.formatMessage({ id: 'payment.processing.subtitle' }),
          emoji: <Emoji symbol="🤔" label="thinking face" />,
        }
      default:
        return {
          title: intl.formatMessage({ id: 'payment.awaiting.title' }),
          subtitle: intl.formatMessage({ id: 'payment.awaiting.subtitle' }),
          emoji: <Emoji symbol="🤔" label="thinking face" />,
        }
    }
  }

  return (
    <Wrapper id="payment-success" className="pb-48">
      {status === 'succeeded' && (
        <Confetti numberOfPieces={100} recycle={true} />
      )}

      <SectionTitle
        title={getContent().title}
        subtitle={getContent().subtitle}
        emoji={getContent().emoji}
        center
      />
    </Wrapper>
  )
}

export default PaymentRedirect
