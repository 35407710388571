import React from 'react'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Faq from './Faq'
import Icon from '@fnd/components/Icon'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { useConsent } from '@fnd/context/ConsentProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'

export const Support = ({ className }) => {
  const { consent, handleIntercomToggle } = useConsent()

  const classes = classNames({
    support: true,
    section: true,
    container: true,
    [className]: className,
  })

  const intl = useIntl()

  return (
    <Wrapper id="support" className={classes}>
      <SectionTitle
        tag="h1"
        title={intl.formatMessage({ id: 'support.title' })}
        subtitle={intl.formatMessage({ id: 'support.subtitle' })}
        center
        badge={
          <Badge
            variant="primary-light"
            icon="headset"
            label={intl.formatMessage({ id: 'navigation.support' })}
          />
        }
      />

      <div className="w-1/1 lg:w-3/4 mx-auto">
        <div className="flex flex-col items-center justify-center max-w-2xl mx-auto mb-8">
          <p className="text-lg text-center">
            <FormattedMessage id="support.description" />
          </p>

          <Button
            className="toggle-intercom mt-5 md:min-w-[300px]"
            onClick={handleIntercomToggle}
          >
            <FormattedMessage id="support.cta" />
            {!consent &&
              <Icon
                name="exclamation-triangle"
                className="text-orange ml-2"
              />}
          </Button>
        </div>

        <Faq className="pb-16" />
      </div>
    </Wrapper>
  )
}

export default Support
