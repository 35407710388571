import { useIntl } from "react-intl"
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import React from "react"
import Button from '@fnd/components/Button/Button'
import Wrapper from '@fnd/components/Wrapper'
import useUserContext from '../modules/User/core/useUserContext'
import { isUserAuthorizedSelector, isBlockedUserSelector } from '@fnd/modules/User'
import { useConsent } from '@fnd/context/ConsentProvider'
import { Navigate } from 'react-router-dom'
import { ROUTES } from './constants'

export default () => {
  const { handleIntercomToggle } = useConsent()
  const intl = useIntl()

  const { isBlocked, isLogged } = useUserContext(
    ({ user }) => ({
      isBlocked: isBlockedUserSelector(user),
      isLogged: isUserAuthorizedSelector(user),
    })
  )

  if (isLogged && !isBlocked) {
    return <Navigate to={ROUTES.HOME} />
  }
  return (
    <Wrapper id="analysis">
      <SectionTitle
        title={intl.formatMessage({ id: 'block_user.title' })}
        subtitle={intl.formatMessage({ id: 'block_user.subtitle' })}
        icon="ban"
        center
      />

      <div className="flex items-center justify-center">
        <Button
          label={intl.formatMessage({ id: "block_user.cta" })}
          className="mb-10 toggle-intercom"
          onClick={handleIntercomToggle}
        />
      </div>
    </Wrapper>
  )
}
