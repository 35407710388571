import React from 'react'
import classNames from 'classnames'

const DeviceMockup = ({
  className,
  image,
  alt = '',
  children,
}) => {
  const classes = classNames({
    'device-mockup': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="pin pin-left-1"></div>
      <div className="pin pin-left-2"></div>
      <div className="pin pin-left-3"></div>
      <div className="pin pin-right"></div>

      <div className="device-mockup-inner">
        {image && (
          <img src={image} alt={alt} />
        )}

        {children && children}
      </div>
    </div>
  )
}

export default DeviceMockup
