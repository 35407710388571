import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { MatchSwitch } from '@fnd/components/Match'
import RadioList from './RadioList'

export function RadioMatch() {
  useEffect(() => {
    document.body.style.setProperty('--theme-primary-color', 'var(--blue-color)')
    document.body.style.setProperty('--theme-primary-color-rgb', 'var(--secondary-color-rgb)')
    document.body.style.setProperty('--theme-secondary-color', 'var(--blue-color)')
    document.body.style.setProperty('--theme-secondary-color-rgb', 'var(--secondary-color-rgb)')

    return () => {
      document.body.style.setProperty('--theme-primary-color', 'var(--primary-color)')
      document.body.style.setProperty('--theme-primary-color-rgb', 'var(--primary-color-rgb)')
      document.body.style.setProperty('--theme-secondary-color', 'var(--secondary-color)')
      document.body.style.setProperty('--theme-secondary-color-rgb', 'var(--secondary-color-rgb)')
    }
  }, [])

  return (
    <Wrapper>
      <Auth
        login
        emptyState
      >
        <MatchSwitch
          initial="radio"
          className="mb-8"
        />

        <RadioList />
      </Auth>
    </Wrapper>
  )
}


export default RadioMatch
