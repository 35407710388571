import React, { useEffect, useState } from 'react'
import Modal from "@fnd/components/Modal/Modal"
import { ModalHeader } from "@fnd/components/Modal"
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useQuery } from '@tanstack/react-query'
import { usePaginationStore } from '@fnd/store'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { QUERIES, PLANS, RADIO_PREVIEW_LIMIT, PER_PAGE } from '@fnd/constants'
import { DataTable } from '@fnd/components/Table'
import { getColumns } from './RadioTableColumns'
import filters from './RadioTableFilters'
import RadioDetail from "./RadioDetail"
import { useIntl } from 'react-intl'


export function RadioList() {
  const {
    total,
    pagination,
    setPagination,
    setTotal,
    enablePagination,
    disablePagination
  } = usePaginationStore()

  const [currentItem, setCurrentItem] = useState(null)
  const [limit, setLimit] = useState(false)
  const [isAgency, setIsAgency] = useState(false)
  const intl = useIntl()

  const { profile } = useUserContext(
    ({ user }) => ({
      profile: userProfileSelector(user)
    })
  )

  const { data: radios, isLoading, isFetching } = useQuery({
    queryKey: [QUERIES.RADIO.LIST, pagination.pageIndex, pagination.pageSize],
    queryFn: async () => {
      const res = await spotimatchEndpoints.getRadios({
        pageParam: pagination.pageIndex,
        limit: pagination.pageSize
      })

      setTotal(res.total)
      return res
    },
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24,
    keepPreviousData: true,
    enabled: !limit
  })

  const handleModalClose = () => {
    setCurrentItem(null)
  }

  useEffect(() => {
    if (profile?.plan?.name === PLANS.AGENCY) {
      setIsAgency(true)
    }
  }, [profile])

  useEffect(() => {
    if (!isAgency && radios?.data?.length >= RADIO_PREVIEW_LIMIT) {
      setLimit(true)
      disablePagination()
    } else {
      setLimit(false)
      enablePagination()
    }
  }, [radios, isAgency])

  return (
    <div className="mb-12">
      <DataTable
        rowIdKey="_id"
        columns={getColumns(intl, { setCurrentItem })}
        data={radios?.data || []}
        filters={filters}
        isLoading={isLoading || isFetching}
        pagination={pagination}
        setPagination={setPagination}
        total={total}
      />

      {!isAgency &&
        <AccountUpgrade
          btnVariant="gradient"
          message={intl.formatMessage({ id: 'profile.upgrade.magazine' }, {
            plan: "agency"
          })}
          plan={PLANS.AGENCY}
        />}

      {currentItem ? (
        <Modal
          key={currentItem.id}
          isOpen={true}
          onClose={handleModalClose}
        >
          <ModalHeader
            icon="radio"
            title={currentItem.name}
            onClose={handleModalClose}
          />
          <div className="p-4">
            <RadioDetail
              contacts={currentItem.contacts}
              description={currentItem.description}
              location={currentItem.location}
              name={currentItem.name}
              references={currentItem.references}
              stationType={currentItem.station_type}
              tags={currentItem.tags}
              website={currentItem.website}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  )
}


export default RadioList
