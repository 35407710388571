import React from 'react'
import { MATCH_CHANNELS } from '@fnd/screens/constants'
import { useTabs, TabLinks } from '@fnd/components/Tabs'
import classNames from 'classnames'
import { useIntl } from 'react-intl'


export function MatchSwitch({
  initial = 'spotify',
  className
}) {
  const intl = useIntl()

  const matchTabs = MATCH_CHANNELS.map((channel) => ({
    ...channel,
    label: intl.formatMessage({ id: channel.label }),
  }))

  const tabsData = {
    tabs: matchTabs,
    initialTabId: initial,
  }

  const tabs = useTabs(tabsData)

  const classes = classNames({
    'match-switch': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <TabLinks {...tabs.tabProps} />
    </div>
  )
}

export default MatchSwitch
