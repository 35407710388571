import React, { useState, useEffect } from 'react'
import Badge from '@fnd/components/Badge'
import Auth from '@fnd/components/Auth'
import Podium from '@fnd/components/Rank/Podium'
import { RankTable } from '@fnd/components/Rank'
import Spinner from '@fnd/components/Spinner'
import SectionTitle from '@fnd/components/SectionTitle'
import Wrapper from '@fnd/components/Wrapper'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { spotifyEndpoints } from '@fnd/core/spotify'
import parse from 'html-react-parser'

function Rank({ className }) {
  const [rank, setRank] = useState([])
  const [winners, setWinners] = useState([])
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const classes = classNames({
    [className]: className,
  })

  useEffect(() => {
    const getRank = async () => {
      setLoading(true)
      const rankData = await spotimatchEndpoints.getCuratorRank().toPromise()

      const curators = (await Promise.allSettled(Object.keys(rankData).map(async (key) => {
        const rankItem = rankData[key]
        const { owner } = rankItem
        const curator = await spotifyEndpoints.getUserProfile(owner).toPromise()

        return {
          ...curator,
          ...rankItem
        }
      }))).filter(({ status }) => status === 'fulfilled').map(({ value }) => value)

      const winners = curators.slice(0, 5)
        .map((winner, position) => ({ ...winner, position }))

      setRank(curators)
      setWinners(winners)
      setLoading(false)
    }

    getRank()
  }, [])

  return (
    <Wrapper id="rank" className={classes}>
      <SectionTitle
        title={intl.formatMessage({ id: 'rank.page.title' })}
        subtitle={intl.formatMessage({ id: 'rank.page.subtitle' })}
        headingClasses="text-gradient-gold-fade"
        center
        badge={
          <Badge
            variant="gold-light"
            label={intl.formatMessage({ id: 'navigation.rank' })}
            icon="trophy"
          />
        }
      />

      <div className="text-lg text-center max-w-3xl mx-auto">
        {parse(intl.formatMessage({ id: 'rank.page.description' }))}
      </div>

      <Auth login emptyState className="mt-8">
        {loading && <Spinner />}

        {!loading && <Podium winners={winners} />}
        {!loading && <RankTable className="my-8" ranking={rank} />}
      </Auth>
    </Wrapper>
  )
}

export default Rank
