import React, { useState } from 'react'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { DEVELOPMENT } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
} from '@fnd/modules/User'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import Emoji from '@fnd/components/Emoji'
import { FormattedMessage } from 'react-intl'

function ConfirmEmail() {
  const [isClicked, setIsClicked] = useState(false)

  const { profile, isAuthorized } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  if (!isAuthorized || profile.verified || isClicked || DEVELOPMENT) {
    return null
  }

  return (
    <Alert className="text-center mt-3 z-1">
      <p className="m-0">
        <Emoji symbol="👋" label="Waving Hand" className="mr-2" />
        <FormattedMessage id="messages.hi" /> {profile.spotify_username}
        {'! '}
        <FormattedMessage id="confirm_email.text" />
      </p>

      <div className="flex flex-col lg:flex-row gap-2 w-100 mt-3 md:ml-4">
        <Button
          className="flex-1"
          variant="outline-primary"
          onClick={() => setIsClicked(true)}
        >
          <FormattedMessage id="confirm_email.later" />
        </Button>
        <Button
          className="flex-1"
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`}
          onClick={() => setIsClicked(true)}
        >
          <FormattedMessage id="confirm_email.cta" />
        </Button>
      </div>
    </Alert>
  )
}

export default ConfirmEmail
