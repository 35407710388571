import React, { useEffect } from 'react'
import Button from '@fnd/components/Button'
import Badge from '@fnd/components/Badge'
import { StepperFooter } from '@fnd/components/Stepper'
import { CheckboxCard } from '@fnd/components/Field'
import { Controller, useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'react-intl'
import { useVipCampaignStore } from '@fnd/store'
import {
  CAMPAIGN_EXCLUSIVE_TYPOLOGIES as exclusiveTypologies,
  CAMPAIGN_TYPOLOGY_OPTIONS as typologyOptions,
  CAMPAIGN_TYPOLOGY_WITH_TARGET as typologyWithTarget,
} from '../config'
import {
  VipInfoInstagram,
  VipInfoPodcast,
  VipInfoSpotify,
  VipInfoSpotifyAlgo,
  VipInfoTikTok,
  VipInfoTikTokTalents,
  VipInfoYouTube
} from '../Info'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { SignButton } from '@fnd/components/Sign'
import { isUserAuthorizedSelector } from '@fnd/modules/User'
import { useSearchParams } from 'react-router-dom'

const VipCampaignStep1 = ({ set, next }) => {
  const {
    form,
    setForm,
    resetAddons,
    resetAlgo,
    resetCountry
  } = useVipCampaignStore()
  const intl = useIntl()
  const CAMPAIGN_TYPOLOGY_OPTIONS = typologyOptions(intl)

  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  const [searchParams] = useSearchParams()

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      ...form
    },
  })

  const onSubmit = (data) => {
    setForm({
      ...form,
      ...data
    })

    if (data.typologies.some(typology => typologyWithTarget.includes(typology))) {
      next()
    } else {
      set('vip-step-3')
    }
  }

  const typologies = watch('typologies')

  useEffect(() => {
    resetAddons()
    resetCountry()

    if (!typologies.includes('spotify')) {
      resetAlgo()
    }
  }, [typologies])

  useEffect(() => {
    const typeParam = searchParams.get('type')
    if (typeParam) {
      setValue('typologies', [typeParam])
    }
  }, [searchParams])


  const handleTypologyClick = (value) => {
    const isSelected = typologies.includes(value)

    if (exclusiveTypologies.includes(value)) {
      const updatedTypologies = isSelected ? [] : [value]
      setValue('typologies', updatedTypologies, { shouldValidate: true })
    } else {
      const updatedTypologies = isSelected ? typologies.filter(item => item !== value) : [...typologies, value]
      const regularTypologies = updatedTypologies.filter(item => !exclusiveTypologies.includes(item))
      setValue('typologies', regularTypologies, { shouldValidate: true })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid md:grid-cols-4 gap-8">
        <div className="md:col-span-2">
          <div className="flex flex-col gap-2 mt-2 mb-6">
            <h3 className="text-3xl m-0">
              {intl.formatMessage({ id: 'vip_campaign.step1.title' })}
            </h3>
            <p className="font-light text-lg opacity-80 m-0">
              {intl.formatMessage({ id: 'vip_campaign.step1.text' })}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {CAMPAIGN_TYPOLOGY_OPTIONS.map((option) => option?.enabled && (
              <Controller
                key={option.value}
                name="typologies"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CheckboxCard
                    {...field}
                    name="typologies"
                    setValue={setValue}
                    getValues={getValues}
                    multiple={true}
                    className="text-balance"
                    onClick={handleTypologyClick}
                    badge={option.new && (
                      <Badge
                        label="New"
                        icon="sparkles"
                        variant="primary"
                        className="absolute top-4 right-4"
                      />
                    )}
                    {...option}
                  />
                )}
              />
            ))}
          </div>
        </div>

        <div className="relative md:pt-[6.5rem] md:col-span-2">
          <div className="flex flex-col gap-4">
            {typologies.includes('spotify') && (
              <VipInfoSpotify />
            )}

            {typologies.includes('spotifyAlgo') && (
              <VipInfoSpotifyAlgo />
            )}

            {typologies.includes('instagram') && (
              <VipInfoInstagram />
            )}

            {typologies.includes('podcast') && (
              <VipInfoPodcast />
            )}


            {typologies.includes('tiktok') && (
              <VipInfoTikTok />
            )}

            {typologies.includes('tiktokTalents') && (
              <VipInfoTikTokTalents />
            )}

            {typologies.includes('youtube') && (
              <VipInfoYouTube />
            )}
          </div>
        </div>
      </div>

      <StepperFooter className="mt-4">
        {isAuthorized ? (
          <Button type="submit" icon="arrow-right">
            {intl.formatMessage({ id: 'messages.next' })}
          </Button>
        ) : (
          <SignButton
            variant="primary"
            label={<FormattedMessage id="home.main.start_now" />}
            onClick={() => set('vip-step-2')}
          />
        )}
      </StepperFooter>
    </form>
  )
}

export default VipCampaignStep1
