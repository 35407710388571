import React, { useEffect, useState } from 'react'
import { DataTable } from '@fnd/components/Table'
import Modal from '@fnd/components/Modal/Modal'
import { ModalHeader } from '@fnd/components/Modal'
import Spinner from '@fnd/components/Spinner'
import TikTokCard from './TikTokCard'
import TikTokDetail from './TikTokDetail'
import { ViewSwitch } from '@fnd/components/View'
import { getColumns } from './TikTokTableColumns'
import filters from './TikTokTableFilters'
import { getMediaUrl } from '@fnd/core/libs/media'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useMatchStore, usePaginationStore } from '@fnd/store'
import { useInView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { TIKTOK_PREVIEW_LIMIT, PLANS, QUERIES } from '@fnd/constants'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'


export function TikTokList() {
  const [view, setView] = useState('grid')
  const [isAgency, setIsAgency] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const { total, setTotal } = usePaginationStore()
  const { genres, trackId, isMatching, matchType } = useMatchStore()
  const intl = useIntl()

  const { profile } = useUserContext(
    ({ user }) => ({
      profile: userProfileSelector(user)
    })
  )

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const {
    data,
    isFetching,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [QUERIES.TIKTOK.LIST],
    queryFn: async (params) => {
      const res = await spotimatchEndpoints.getTikToks({
        ...params,
        genres,
        trackId
      })

      setTotal(res.total)
      return res
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return
      return lastPage.next_page
    },
    enabled: isMatching && matchType == 'tiktok',
  })

  const handleCardClick = (tiktok) => {
    setCurrentItem(tiktok)
  }

  const handleModalClose = () => {
    setCurrentItem(null)
  }

  useEffect(() => {
    if (inView && hasNextPage && isAgency) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  useEffect(() => {
    if (profile?.plan?.name === PLANS.AGENCY) {
      setIsAgency(true)
    }
  }, [profile])

  const tikToks = data?.pages?.map((page) => page.data).flat() || []

  return (
    <div className="mb-12">
      {tikToks && tikToks.length > 0 && (
        <>
          <ViewSwitch
            className="mb-4"
            view={view}
            setView={setView}
          />

          {view === 'list' && (
            <DataTable
              rowIdKey="_id"
              pagination={{ disabled: true }}
              columns={getColumns(intl, { setCurrentItem })}
              data={tikToks}
              filters={filters}
              isLoading={isLoading || isFetching}
              total={total}
            />
          )}

          {view === 'grid' && (
            <div className="tiktok-grid grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8">
              {tikToks.map((tiktok, index) => (
                <TikTokCard
                  key={tiktok.tiktok_id}
                  preview={!isAgency && index > TIKTOK_PREVIEW_LIMIT}
                  tikTokId={tiktok.tiktok_id}
                  tikTokHandle={tiktok.tiktok_handle}
                  contacts={tiktok.contacts}
                  region={tiktok.region}
                  tags={tiktok.tags}
                  video={getMediaUrl(tiktok.video)}
                  cover={getMediaUrl(tiktok.cover)}
                  avatar={getMediaUrl(tiktok.avatar)}
                  nickname={tiktok.nickname}
                  followers={tiktok.followers}
                  heartCount={tiktok.heart_count}
                  videoCount={tiktok.video_count}
                  onClick={() => handleCardClick(tiktok)}
                />
              ))}
            </div>
          )}
        </>
      )}

      {currentItem ? (
        <Modal
          key={currentItem.id}
          isOpen={true}
          onClose={() => handleModalClose()}
        >
          <ModalHeader
            title={currentItem.nickname}
            onClose={() => handleModalClose()}
          />
          <div className="p-4">
            <TikTokDetail
              key={currentItem.tiktok_id}
              preview={!isAgency}
              tikTokId={currentItem.tiktok_id}
              tikTokHandle={currentItem.tiktok_handle}
              contacts={currentItem.contacts}
              agencyContacts={currentItem.agency_contacts}
              region={currentItem.region}
              tags={currentItem.tags}
              cover={getMediaUrl(currentItem.cover)}
              video={getMediaUrl(currentItem.video)}
              avatar={getMediaUrl(currentItem.avatar)}
              nickname={currentItem.nickname}
              followers={currentItem.followers}
              heartCount={currentItem.heart_count}
              videoCount={currentItem.video_count}
              profileType={currentItem.profile_type}
            />
          </div>
        </Modal>
      ) : null}

      {isLoading && <Spinner />}

      <div ref={ref} />

      {!isAgency && tikToks?.length > 0 && (
        <AccountUpgrade
          btnVariant="gradient"
          message={intl.formatMessage(
            { id: 'profile.upgrade.tiktok' },
            {
              plan: 'agency',
            }
          )}
          plan={PLANS.AGENCY}
        />
      )}
    </div>
  )
}


export default TikTokList
