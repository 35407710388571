import React, { useEffect } from 'react'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import { useMatchStore, useRecentMatchStore } from '@fnd/store'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { PLANS } from '@fnd/constants'


export const MatchRecent = ({
  artists,
  plan,
  className,
  onClick
}) => {
  const {
    clearMatches,
    recentMatches,
    removeMatch
  } = useRecentMatchStore()
  const { matchType } = useMatchStore()
  const intl = useIntl()

  const handleClick = (match) => {
    if (onClick) {
      onClick(match)
    }
  }

  const classes = classNames({
    'match-recent': true,
    [className]: className,
  })

  useEffect(() => {
    if (plan !== PLANS.AGENCY && recentMatches?.length > 0) {
      recentMatches.forEach(match => {
        const trackArtistIds = match?.track?.artists?.map(artist => artist.id)
        if (!artists.some(artist => trackArtistIds.includes(artist.id))) {
          removeMatch(match.track.id)
        }
      })
    }
  }, [artists, plan])

  if (!recentMatches || !recentMatches.filter(m => m.type === matchType).length) {
    return null
  }

  return (
    <div className={classes}>
      <h5 className="text-xl">
        {intl.formatMessage({ id: 'match.recent' })}
      </h5>

      <div className="flex items-center flex-wrap gap-2">
        {recentMatches.map((match) => {
          if (matchType === match?.type) {
            return (
              <Badge
                key={match?.track?.id}
                variant="light"
                className="match-recent-item"
                label={match?.track?.name}
                image={match?.track?.album?.images?.[0]?.url}
                clearable
                onClear={() => removeMatch(match?.track?.id)}
                onClick={() => handleClick(match)}
              />
            )
          }
        })}

        <Button
          small
          variant="ghost"
          onClick={clearMatches}
          label={intl.formatMessage({ id: 'match.recent.clear_all' })}
        />
      </div>
    </div>
  )
}

export default MatchRecent
