import Button from '@fnd/components/Button/Button'
import {
  Artists,
  Mentions,
  Reviews,
} from '@fnd/components/Home'
import Faq from '@fnd/screens/Faq'
import React from 'react'
import { useIntl } from 'react-intl'
import { DeviceMockup } from '@fnd/components/Mockup'
import {
  VipCampaignHeader,
  VipCampaign
} from '@fnd/components/VipCampaign'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import parse from 'html-react-parser'

const Vip = () => {
  const intl = useIntl()

  const handleCtaClick = () => {
    const stepper = document.querySelector('.stepper')
    if (stepper) {
      stepper.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  return (
    <Wrapper id="vip">
      <VipCampaignHeader />

      <VipCampaign className="mb-8" />

      <div className="w-full mx-auto mt-10 md:w-8/12">
        <h3 className="mt-8">
          {parse(
            intl.formatMessage({
              id: 'vip_campaign.how_does_it_work.title',
            })
          )}
        </h3>

        <ol className="list-badges">
          <li>
            <h4 className="text-primary sm:text-2xl mb-2">
              {parse(intl.formatMessage({ id: 'vip_campaign.how_does_it_work.1.title' }))}
            </h4>

            {parse(intl.formatMessage({ id: 'vip_campaign.how_does_it_work.1.text' }))}
          </li>

          <li>
            <h4 className="text-primary sm:text-2xl mb-2">
              {parse(intl.formatMessage({ id: 'vip_campaign.how_does_it_work.2.title' }))}
            </h4>

            {parse(intl.formatMessage({ id: 'vip_campaign.how_does_it_work.2.text' }))}
          </li>

          <li>
            <h4 className="text-primary sm:text-2xl mb-2">
              {parse(intl.formatMessage({ id: 'vip_campaign.how_does_it_work.3.title' }))}
            </h4>

            {parse(intl.formatMessage({ id: 'vip_campaign.how_does_it_work.3.text' }))}
          </li>
        </ol>

        <p className="text-xl my-8">
          {parse(
            intl.formatMessage({
              id: 'vip_campaign.payment.text',
            })
          )}
        </p>

        <div className="grid sm:grid-cols-3 gap-8 mb-8">
          <DeviceMockup
            image="/images/vip/matchfy_spotify_screen.jpg"
            alt="VIP Campaign Spotify Analytics"
          />

          <DeviceMockup
            image="/images/vip/matchfy_tiktok_screen.jpg"
            alt="VIP Campaign TikTok Analytics"
          />

          <DeviceMockup
            image="/images/vip/matchfy_youtube_screen.jpg"
            alt="VIP Campaign YouTube Analytics"
          />
        </div>

        <div className="text-center mb-8">
          <h4>
            {parse(
              intl.formatMessage({ id: 'vip_campaign.cta.title' })
            )}
          </h4>

          <Button
            icon="gem"
            label={intl.formatMessage({ id: 'vip_campaign.cta.button' })}
            onClick={handleCtaClick}
            className="button-cta mt-2"
          />
        </div>
      </div>

      <Artists />

      <Reviews />

      <Mentions />

      <Faq
        showTitle
        className="pb-16"
      />
    </Wrapper>
  )
}

export default Vip
