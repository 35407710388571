import { APP_NAME, APP_URL, APP_THEME_COLOR } from "@fnd/constants"
import { AVAILABLE_LANGUAGES, ROUTES_SEO } from "@fnd/screens/constants"
import React from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"

const getSeoRoute = (path) => {
  const ogImagePath = `${APP_URL}/images/og`
  const defaultOgImage = `${ogImagePath}/og_default.jpg`
  const pathKey = Object.keys(ROUTES_SEO).find(key => key === path)

  if (!pathKey) return ["default", defaultOgImage]

  const { key, image } = ROUTES_SEO[pathKey]
  return [key, `${ogImagePath}/${image}`]
}

export default () => {
  const intl = useIntl()
  const navigator = useLocation()
  const [key, image] = getSeoRoute(navigator.pathname)

  const url = `${APP_URL}${navigator.pathname}`
  const pageTitle = intl.formatMessage({ id: `navigation.${key}` })
  const title = `${APP_NAME} | ${pageTitle}`
  const description = intl.formatMessage({ id: `og.description.${key}` })
  const locale = AVAILABLE_LANGUAGES.find(({ value }) => value === intl.locale)?.bpc47
  const alternate = AVAILABLE_LANGUAGES.filter(({ value }) => value !== intl.locale)

  return (
    <>
      <Helmet>
        <html lang={locale} className="notranslate" translate="no" />

        <title>{title}</title>

        <meta property="og:site_name" content="Matchfy.io" />

        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />

        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta property="og:image" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta name="twitter:image" content={image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content={`Og image for ${key}`} />
        <meta property="og:image:type" content="image/jpg" />

        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:type" content="website" />
        <meta property="robots" content={key === "default" ? "noindex, nofollow" : "index, follow"} />


        <meta property="og:locale" content={locale} />

        {alternate.map(({ bpc47 }) => (
          <meta property="og:locale:alternate" content={bpc47} key={bpc47} />
        ))}

        <meta name="theme-color" content={APP_THEME_COLOR} />
        <meta name="msapplication-TileColor" content={APP_THEME_COLOR} />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
      </Helmet>
    </>
  )
}
