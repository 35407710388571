import React, { useState, useEffect } from "react"
import Icon from "@fnd/components/Icon"
import Badge from "@fnd/components/Badge"
import Button from "@fnd/components/Button"
import { Checkbox } from "@fnd/components/Field/Checkbox"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@fnd/components/Command/Command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@fnd/components/Popover/Popover"
import classNames from "classnames"
import { useIntl } from "react-intl"

export function TableArrayFilter({
  column,
  title,
  icon,
  options,
  className
}) {
  const facets = column?.getFacetedUniqueValues()
  const [selectedValues, setSelectedValues] = useState(
    new Set(column?.getFilterValue() || [])
  )

  const intl = useIntl()

  const handleSelect = (value, isSelected) => {
    const newSelectedValues = new Set(selectedValues)

    if (isSelected) {
      newSelectedValues.delete(value)
    } else {
      newSelectedValues.add(value)
    }

    setSelectedValues(newSelectedValues)
    column?.setFilterValue(Array.from(newSelectedValues))
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="faceted-filter"
          className={classNames({ [className]: className })}
        >
          <Icon name={icon ? icon : 'plus'} className="mr-2" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 ml-2 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    small
                    variant="primary-light"
                    className="ml-2"
                  >
                    {selectedValues.size}{' '}
                    {intl.formatMessage({ id: 'messages.selected' })}
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge
                        variant="primary-light"
                        key={option.value}
                      >
                        {intl.formatMessage({ id: option.label })}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="popover-content w-[200px]" align="start">
        <Command>
          <CommandInput placeholder={title} />

          <CommandList>
            <CommandEmpty>
              {intl.formatMessage({ id: 'messages.no_results' })}
            </CommandEmpty>

            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value)

                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => handleSelect(option.value, isSelected)}
                  >
                    <Checkbox
                      checked={isSelected}
                      className="mr-2"
                    />

                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}

                    <span>
                      {option.label}
                    </span>

                    {facets.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                )
              })}
            </CommandGroup>

            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => column?.setFilterValue(undefined)}
                    className="justify-center text-center"
                  >
                    {intl.formatMessage({ id: 'messages.clear_filters' })}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
