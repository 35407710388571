import React from 'react'
import Progress from '@fnd/components/Progress'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

function ArtistPopularity({ popularity, className }) {
  const intl = useIntl()

  const classes = classNames({
    'artist-popularity': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <Progress
        label={intl.formatMessage({ id: 'artist.popularity.label' })}
        progress={popularity}
        color="green"
      />
    </div>
  )
}

export default ArtistPopularity
