export const ANALYSIS_TOUR_STEPS = {
  tourId: 'analysis',
  route: '/analysis',
  delay: 1000,
  steps: [
    {
      title: 'tour.analysis.0.title',
      text: 'tour.analysis.0.text'
    }
  ]
}

export const ACCELERATE_TOUR_STEPS = {
  tourId: 'accelerate',
  route: '/accelerate',
  delay: 1000,
  steps: [
    {
      tourId: 'accelerate',
      title: 'tour.accelerate.0.title',
      text: 'tour.accelerate.0.text'
    }
  ]
}

export const CURATOR_TOUR_STEPS = {
  tourId: 'curator',
  route: '/curators',
  delay: 1000,
  steps: [
    {
      tourId: 'curator',
      title: 'tour.curators.0.title',
      text: 'tour.curators.0.text'
    }
  ]
}

export const PROFILE_PLAYLIST_TOUR_STEPS = {
  tourId: 'profile-playlist',
  route: '/my-profile/playlists',
  delay: 5000,
  steps: [
    {
      tourId: 'profile-playlist',
      attachTo: { element: '.playlist-verify', on: 'bottom' },
      title: 'tour.profile_playlist.0.title',
      text: 'tour.profile_playlist.0.text'
    },
    {
      attachTo: { element: '.playlist-requests', on: 'bottom' },
      title: 'tour.profile_playlist.1.title',
      text: 'tour.profile_playlist.1.text'
    }
  ]
}

export const PROFILE_OVERVIEW_TOUR_STEPS = {
  tourId: 'profile-overview',
  route: '/my-profile/overview',
  delay: 1000,
  steps: [
    {
      title: 'tour.profile_overview.0.title',
      text: 'tour.profile_overview.0.text'
    },
    {
      attachTo: { element: '.profile-sidebar-header .credit-balance', on: 'right' },
      attachToMobile: { element: '.credit-balance', on: 'bottom' },
      title: 'tour.profile_overview.credits.title',
      text: 'tour.profile_overview.credits.text'
    },
    {
      attachTo: { element: '#nav-profile-overview', on: 'right' },
      attachToMobile: { element: '#nav-profile-overview', on: 'bottom' },
      title: 'tour.profile_overview.1.title',
      text: 'tour.profile_overview.1.text'
    },
    {
      attachTo: { element: '#nav-profile-artists', on: 'right' },
      attachToMobile: { element: '#nav-profile-artists', on: 'bottom' },
      title: 'tour.profile_overview.2.title',
      text: 'tour.profile_overview.2.text'
    },
    {
      attachTo: { element: '#nav-profile-activity', on: 'right' },
      attachToMobile: { element: '#nav-profile-activity', on: 'bottom' },
      title: 'tour.profile_overview.3.title',
      text: 'tour.profile_overview.3.text'
    },
    {
      attachTo: { element: '#nav-profile-tracks', on: 'right' },
      attachToMobile: { element: '#nav-profile-tracks', on: 'bottom' },
      title: 'tour.profile_overview.4.title',
      text: 'tour.profile_overview.4.text'
    },
    {
      attachTo: { element: '#nav-profile-playlists', on: 'right' },
      attachToMobile: { element: '#nav-profile-playlists', on: 'bottom' },
      title: 'tour.profile_overview.5.title',
      text: 'tour.profile_overview.5.text'
    }
  ]
}

export const MATCH_TOUR_STEPS = {
  tourId: 'match',
  route: '/match',
  delay: 1000,
  steps: [
    {
      attachTo: { element: '#analysis-step-1', on: 'bottom' },
      title: 'tour.match.0.title',
      text: 'tour.match.0.text'
    },
    {
      attachTo: { element: '#analysis-step-2', on: 'bottom' },
      title: 'tour.match.1.title',
      text: 'tour.match.1.text'
    },
    {
      attachTo: { element: '.button-gradient', on: 'bottom' },
      title: 'tour.match.2.title',
      text: 'tour.match.2.text'
    },
  ]
}

export const VERIFY_EMAIL_TOUR_STEPS = {
  tourId: 'verify-email',
  route: '/my-profile/overview',
  delay: 1000,
  steps: [
    {
      attachTo: { element: '.info-email', on: 'bottom' },
      title: 'tour.verify_email.0.title',
      text: 'tour.verify_email.0.text'
    }
  ]
}

export const TOURS = [
  ANALYSIS_TOUR_STEPS,
  ACCELERATE_TOUR_STEPS,
  CURATOR_TOUR_STEPS,
  PROFILE_PLAYLIST_TOUR_STEPS,
  PROFILE_OVERVIEW_TOUR_STEPS,
  MATCH_TOUR_STEPS,
  VERIFY_EMAIL_TOUR_STEPS
]
