import React, { useEffect, useState } from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import Info from '@fnd/components/Info'
import { MatchMessage } from '@fnd/components/Match'
import Spinner from '@fnd/components/Spinner'
import {
  getUsername,
  getPlatformUrl
} from '@fnd/core/libs/platforms'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useMatchStore } from '@fnd/store'
import { toastFeedback } from '@fnd/core/libs/toast'


export function MagazinePush({
  email,
  contacts,
  facebook,
  instagram,
  twitter,
  tags,
  website,
  magazineId,
}) {
  const { track, trackId } = useMatchStore()
  const [push, setPush] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()

  const handlePush = async () => {
    try {
      setIsLoading(true)

      const res = await spotimatchEndpoints.pushMagazineQueue(magazineId, trackId)

      if (res?.magazine) {
        setPush(true)
        toastFeedback('success', intl.formatMessage({ id: 'feedback.success.magazine_push' }))
      }

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      const error = e?.response?.data?.message || 'feedback.error.default'
      toastFeedback('error', intl.formatMessage({ id: error }))
    }
  }

  useEffect(() => {
    const checkQueue = async () => {
      try {
        const res = await spotimatchEndpoints.checkMagazineQueue(magazineId, trackId)
        if (res?.magazine) {
          setPush(true)
        }
      } catch (e) {
        console.log(e)
      }
    }

    checkQueue()
  }, [])

  const genres = tags.filter(tag => tag !== 'all')

  const MagazinePushed = () => {
    return (
      <>
        {contacts && contacts?.length > 0 && <div className="magazine-contact-list">
          {contacts.map(contact => (
            <div
              key={contact.email}
              className="magazine-contact"
              target="_blank" rel="noopener noreferrer"
            >
              <Icon
                name="user-circle"
                className="text-primary"
              />
              <div className="flex flex-col">
                {contact?.name}

                <div className="flex flex-wrap items-center gap-3 mt-[0.1rem]">
                  {contact?.email.split(',') && contact?.email.split(',').map((email, index) => (
                    <a
                      key={index}
                      href={`mailto:${email}`}
                      className="font-medium text-sm underline truncate opacity-80 hover:opacity-100"
                    >
                      {email}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>}

        {genres && genres?.length > 0 && <Info
          icon="tag"
          className="capitalize"
          label={<FormattedMessage id="field.tags.label" />}
          value={genres.join(', ')}
        />}

        {website && <Info
          icon="browser"
          label="Website"
          value={
            <a
              href={getPlatformUrl('website', website)}
              target="_blank" rel="noopener noreferrer"
            >
              {website}
            </a>
          }
        />}

        {email && <Info
          icon="envelope"
          label="Email"
          value={
            <a
              href={`mailto:${email}`}
              target="_blank" rel="noopener noreferrer"
            >
              {email}
            </a>}
        />}

        {facebook && <Info
          icon="facebook"
          iconType="brands"
          label="Facebook"
          value={
            <a
              href={getPlatformUrl('facebook', facebook)}
              target="_blank" rel="noopener noreferrer"
            >
              {facebook}
            </a>
          }
        />}

        {instagram && <Info
          icon="instagram"
          iconType="brands"
          label="Instagram"
          value={
            <a
              href={getPlatformUrl('instagram', instagram)}
              target="_blank" rel="noopener noreferrer"
            >
              {getUsername(instagram)}
            </a>
          }
        />}

        {twitter && <Info
          icon="twitter"
          iconType="brands"
          label="Twitter"
          value={
            <a
              href={getPlatformUrl('twitter', twitter)}
              target="_blank" rel="noopener noreferrer"
            >
              {getUsername(twitter)}
            </a>
          }
        />}

        <MatchMessage
          typology="magazine"
          className="mt-4"
        />
      </>
    )
  }

  if (isLoading) return (
    <Spinner />
  )

  return (
    <>
      {push && <MagazinePushed />}

      {!push && <Button
        variant="gradient"
        className="w-full mt-3"
        loading={isLoading}
        label={<FormattedMessage id="magazine.push.cta" />}
        onClick={handlePush}
      />}
    </>
  )
}


export default MagazinePush
