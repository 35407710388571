import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import Button from '@fnd/components/Button'
import Badge from '@fnd/components/Badge'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Video from '@fnd/components/Video'
import { isMobile } from 'react-device-detect'
import { Swiper, SwiperSlide } from 'swiper/react'

export const HowItWorks = ({ showTitle, className }) => {
  const [sliderRef, setSliderRef] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const videoRefs = useRef([])

  const intl = useIntl()

  const classes = classNames({
    section: true,
    container: true,
    [className]: className,
  })

  const slides = [
    {
      title: intl.formatMessage({ id: 'home.how_it_works.slide1.title' }),
    },
    {
      title: intl.formatMessage({ id: 'home.how_it_works.slide2.title' }),
    },
    {
      title: intl.formatMessage({ id: 'home.how_it_works.slide3.title' }),
    },
    {
      title: intl.formatMessage({ id: 'home.how_it_works.slide4.title' }),
    }
  ]

  const startVideo = (index) => {
    const video = videoRefs.current[index]
    if (video) {
      video.seekTo(0)
    }
  }

  const handleVideoChange = () => {
    if (currentSlide < slides.length - 1) {
      sliderRef.slideNext()
    } else {
      sliderRef.slideTo(0)
    }
  }

  return (
    <div id="how-it-works" className={classes}>
      {showTitle && (
        <SectionTitle
          tag="h3"
          center
          title={intl.formatMessage({ id: 'home.how_it_works.title' })}
          subtitle={intl.formatMessage({ id: 'home.how_it_works.subtitle' })}
          badge={
            <Badge
              variant="primary-light"
              icon="list-music"
              label={intl.formatMessage({ id: 'affiliate.how_does_it_work.title' })}
            />
          }
        />
      )}

      <Swiper
        className="slider"
        onSwiper={setSliderRef}
        spaceBetween={20}
        onSlideChange={(swiper) => {
          const activeIndex = swiper.activeIndex
          setCurrentSlide(activeIndex)
          startVideo(activeIndex)
        }}
      >
        {slides.map((_slide, index) => (
          <SwiperSlide key={index}>
            <Video
              ref={(el) => videoRefs.current[index] = el}
              autoPlay={true}
              playing={true}
              muted={true}
              controls={false}
              playsinline={true}
              onEnded={handleVideoChange}
              src={`/video/matchfy_how_to_step_${index + 1}.mp4`}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="slider-navigation">
        {slides.map((slide, index) => (
          <Button
            key={index}
            variant="clear"
            className={classNames('slider-navigation-item', {
              active: currentSlide === index,
            })}
            onClick={() => {
              sliderRef.slideTo(index)
              startVideo(index)
            }}
          >
            <span className="index">
              {index + 1}
            </span>
            <h6 className="m-0">
              {slide.title}
            </h6>
          </Button>
        ))}
      </div>
    </div>
  )
}

export default HowItWorks
