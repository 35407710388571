import { Accordion, AccordionItem } from '@fnd/components/Accordion'
import Auth from '@fnd/components/Auth'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { spotifyEndpoints } from '@fnd/core/spotify'
import { ROUTES } from '@fnd/screens/constants'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import TrackPlayer from './TrackPlayer'
import TrackPlaysInstructions from './TrackPlaysInstructions'
import TrackPlaysNavigation from './TrackPlaysNavigation'

export function TrackDetail() {
  const navigate = useNavigate()
  const { trackId } = useParams()
  if (!trackId) navigate(ROUTES.TRACKS)

  const [track, setTrack] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const intl = useIntl()

  const fetchTrack = async () => {
    try {
      const trackData = await spotifyEndpoints.getTrack(trackId).toPromise()
      setTrack(trackData)
      setIsLoading(false)
    }
    catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTrack()
  }, [trackId])

  return (
    <Wrapper>
      <Auth login emptyState>
        <div className="container max-w-xl mb-12">

          <Accordion>
            <AccordionItem label={intl.formatMessage({ id: 'vip_plays.accordion.title' })}>
              <TrackPlaysInstructions />
            </AccordionItem>
          </Accordion>

          {track &&
            <TrackPlayer
              titleTag="h1"
              track={track}
              showInfo={true}
            />}

          {!isLoading && !track && <Empty
            message={intl.formatMessage({ id: 'empty_state.no_track_found' })}
          />}

          {isLoading && <Spinner />}

          <TrackPlaysNavigation className="mt-6" />
        </div>
      </Auth>
    </Wrapper>
  )
}


export default TrackDetail
