import React from 'react'
import { getCampaignOption } from '../utils'
import parse from 'html-react-parser'
import { useIntl } from 'react-intl'

const VipBudgetSpotify = () => {
  const intl = useIntl()

  const campaignOption = getCampaignOption('spotify', intl)

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="inline-flex flex-col items-start md:flex-row md:items-center gap-4">
        <h3 className="text-2xl m-0">
          {campaignOption.label}
        </h3>
      </div>

      <p className="font-light opacity-90 text-balance mt-0 mb-3">
        {parse(intl.formatMessage({ id: 'vip_campaign.budget.spotify.text' }))}
      </p>

      <p className="font-light opacity-90 text-balance mt-0 mb-3">
        {parse(intl.formatMessage({ id: 'vip_campaign.budget.spotify.text_2' }))}
      </p>

      <p className="font-light opacity-90 text-balance mt-0 mb-0">
        {parse(intl.formatMessage({ id: 'vip_campaign.budget.spotify.text_3' }))}
      </p>
    </div>
  )
}

export default VipBudgetSpotify
