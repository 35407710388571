import React, { useState, useEffect } from 'react'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { useUserContext } from '@fnd/modules/User'
import { PLANS } from '@fnd/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { ROUTES } from './constants'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'

const SingleSignOnCallback = () => {
  const { user: { profile } } = useUserContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const redirect = sessionStorage.getItem('redirect')
  const navigate = useNavigate()

  const intl = useIntl()

  const redirectTo = async () => {
    const { jwt } = await spotimatchEndpoints.authorizeDistribution()
    sessionStorage.removeItem('redirect')
    window.location.href = `${redirect}?jwt=${jwt}`
  }

  useEffect(() => {
    if (!profile) {
      return
    }

    if (!redirect) {
      navigate(ROUTES.DISTRIBUTION)
    }

    if (profile?.plan?.name === PLANS.ARTIST || profile?.plan?.name === PLANS.AGENCY) {
      try {
        setError(null)
        setLoading(true)
        redirectTo()
      } catch (error) {
        setLoading(false)
        setError('')
        console.log('error', error)
      }
    } else {
      setError('unauthorized')
    }
  }, [profile])

  return (
    <Wrapper id="sso-callback">
      {loading && <Spinner />}

      {error && (
        <AccountUpgrade
          icon="music-slash"
          btnVariant="gradient"
          plan={PLANS.ARTIST}
          message={intl.formatMessage({ id: 'profile.upgrade.distribution' }, {
            plan: PLANS.ARTIST
          })}
        />
      )}
    </Wrapper>
  )
}

export default SingleSignOnCallback
