import React from "react"
import { Checkbox } from "@fnd/components/Field/Checkbox"
import Badge from "@fnd/components/Badge"
import Icon from "@fnd/components/Icon"
import { TableColumnHeader } from "@fnd/components/Table/TableColumnHeader"
import { DataTableRowActions } from "./RadioTableActions"
import { truncate } from 'lodash-es'

export const getColumns = (intl, actionProps = {}) => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_all' })}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_row' })}
      />
    ),
    show: true,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "name",
    accessorKey: "name",
    header: ({ column }) => (
      <TableColumnHeader
        column={column}
        title="Name"
      />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <div className="flex flex-col items-start">
          <span className="font-medium">
            {item.name}
          </span>
          {item?.description && (
            <span className="text-sm opacity-80">
              {item.description.length > 80 ? truncate(item.description, { length: 100 }) : item.description}
            </span>
          )}
        </div>
      )
    },
    enableSorting: true,
    enableHiding: true,
  },
  {
    id: "station_type",
    accessorKey: "station_type",
    header: ({ column }) => (
      <TableColumnHeader
        column={column}
        title="Station Type"
      />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <Badge
          key={item.station_type}
          variant="blue-light"
          label={item.station_type}
        />
      )
    },
  },
  {
    id: "tags",
    accessorKey: "tags",
    header: ({ column }) => (
      <TableColumnHeader
        column={column}
        title="Tags"
      />
    ),
    cell: ({ row }) => {
      const item = row.original
      if (!item.tags || item.tags.length === 0) return null

      return (
        <div className="flex flex-wrap gap-2">
          {item.tags.map((tag) => (
            <Badge
              key={tag}
              variant="blue-light"
              label={tag}
            />
          ))}
        </div>
      )
    },
    filterFn: (row, id, value) => {
      const rowValue = row.getValue(id)
      if (!rowValue) return false
      return value.some((tag) => rowValue.includes(tag))
    },
  },
  {
    id: "website",
    accessorKey: "website",
    header: ({ column }) => (
      <TableColumnHeader
        column={column}
        title="Website"
      />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <a
          href={item.website}
          target="_blank"
          rel="noreferrer"
          className="inline-flex items-center gap-2 text-blue underline"
        >
          {item.website}
          <Icon name="external-link" />
        </a>
      )
    },
  },
  {
    id: "actions",
    cell: ({ row }) =>
      <DataTableRowActions
        row={row}
        {...actionProps}
      />,
  },
]

export default getColumns
