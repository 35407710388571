import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { LogoFull, LogoIcon } from '@fnd/components/Logo/Logo'
import LanguageDropdown from '@fnd/components/LanguageDropdown/LanguageDropdown'
import { EVENTS, SPOTIFY_SUPPORT_URL, DEFAULT_ICON_TYPE } from '@fnd/constants'
import { ROUTES, NAV, MATCH_DROPDOWN, RANK_DROPDOWN } from '@fnd/screens/constants'
import { CAMPAIGN_TYPOLOGY_OPTIONS } from '@fnd/components/VipCampaign/config'
import ConfirmEmail from '@fnd/components/ConfirmEmail'
import Icon from '@fnd/components/Icon'
import NavigationToggle from './NavigationToggle'
import NavigationAlternate from './NavigationAlternate'
import NavigationDropdown from './NavigationDropdown'
import ThemeToggle from './ThemeToggle'
import eventEmitter from '@fnd/core/libs/eventEmitter'


const Navigation = ({ navigationToggleHandler }) => {
  const intl = useIntl()
  const [showError429, setShowError429] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    eventEmitter.on(EVENTS.ERROR_429, () => {
      setShowError429(true)
    })
    return () => {
      window.removeEventListener('scroll', handleScroll)
      eventEmitter.off(EVENTS.ERROR_429)
    }
  }, [])

  const handleScroll = () => {
    const navbar = document.getElementById('navbar')
    if (navbar) {
      if (window.scrollY > 25) {
        navbar.classList.add('nav-fixed')
      } else {
        navbar.classList.remove('nav-fixed')
      }
    }
  }

  return (
    <header id="navbar" className="nav">
      <nav>
        <div className="nav-left">
          <div className="nav-logo">
            <div className="nav-toggle">
              <NavigationToggle click={navigationToggleHandler} />
            </div>
            <Link to={ROUTES.HOME}>
              <span className="hidden lg:block"><LogoFull /></span>
              <span className="block lg:hidden"><LogoIcon /></span>
            </Link>
          </div>
          <ul>
            <NavigationDropdown
              to={ROUTES.MATCH}
              icon="music"
              label={intl.formatMessage({ id: 'navigation.match' })}
              items={MATCH_DROPDOWN(intl)}
            />

            {NAV.map((item) => item.show && (
              <li key={item.link}>
                <NavLink
                  to={item.link}
                  className={({ isActive }) => (isActive ? ' active' : '')}
                >
                  {item.icon && <Icon name={item.icon} />}
                  <FormattedMessage id={item.title} />
                </NavLink>
              </li>
            ))}

            <NavigationDropdown
              to={ROUTES.VIP}
              icon="gem"
              label="VIP Promo"
              items={CAMPAIGN_TYPOLOGY_OPTIONS(intl).filter((item) => item.enabled)
                .map((item) => ({
                  icon: item.icon,
                  iconType: item?.iconType ?? DEFAULT_ICON_TYPE,
                  title: item.label,
                  subtitle: item.subtitle,
                  to: `${ROUTES.VIP}?type=${item.value}`,
                }))}
            />

            <NavigationDropdown
              to={ROUTES.RANK}
              icon="trophy"
              label={intl.formatMessage({ id: 'navigation.rank' })}
              items={RANK_DROPDOWN(intl)}
            />
          </ul>
        </div>

        <div className="nav-right">
          <ul>
            <li className="nav-item flex items-center">
              <NavigationAlternate />
            </li>
          </ul>
          <ThemeToggle />
          <LanguageDropdown />
        </div>
      </nav>

      <ConfirmEmail />

      {showError429 && (
        <div className="error-banner text-center">
          <p>
            {intl.formatMessage({ id: "error_login.subtitle" })}{'. '}
            <a
              target="_blank"
              rel="noreferrer"
              href={SPOTIFY_SUPPORT_URL}
              className="font-semibold underline"
            >
              {intl.formatMessage({ id: "error_login.cta" })}
            </a>
          </p>
        </div>
      )}
    </header>
  )
}

export default Navigation
