import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'

export default function VideoAutoplay({
  src,
  autoplay,
  ...props
}) {
  const [videoSrc, setVideoSrc] = useState()
  const [isPlaying, setIsPlaying] = useState(false)

  const handleMouseOver = () => {
    setIsPlaying(true)
    setVideoSrc(src)
  }

  const handleMouseOut = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    if (autoplay) {
      setVideoSrc(src)
    }

    setIsPlaying(autoplay)
  }, [autoplay])

  return (
    <ReactPlayer
      playing={isPlaying}
      url={videoSrc}
      width="100%"
      height="100%"
      controls={false}
      muted={true}
      playsinline={true}
      loop={true}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      {...props}
    />
  )
}
