import React, { useEffect, useState } from 'react'

import {
  isUserAuthorizedSelector,
  useUserContext,
} from '@fnd/modules/User'
import Navigation from './Navigation'
import NavigationBackdrop from './NavigationBackdrop'
import NavigationDrawer from './NavigationDrawer'
import NavigationTabs from './NavigationTabs'

function Header() {
  const [drawerOpen, setDrawerState] = useState(false)
  const { isAuthorized } = useUserContext(
    ({ user }) => ({
      isAuthorized: isUserAuthorizedSelector(user),
    })
  )

  useEffect(() => {
    if (drawerOpen) {
      document.body.classList.add('lock-scroll')
    } else {
      document.body.classList.remove('lock-scroll')
    }
  }, [drawerOpen])

  return (
    <>
      <Navigation navigationToggleHandler={() => setDrawerState(!drawerOpen)} />
      <NavigationDrawer
        show={drawerOpen}
        closeDrawer={() => setDrawerState(false)}
      />
      {drawerOpen && (
        <NavigationBackdrop
          backdropClickHandler={() => setDrawerState(false)}
        />
      )}
      {isAuthorized ? <NavigationTabs /> : null}
    </>
  )
}

export default Header
