import React, { useContext } from 'react'
import { ThemeContext } from '@fnd/context/ThemeProvider'
import Button from '@fnd/components/Button'

const ThemeToggle = () => {
  const { currentTheme, applyTheme } = useContext(ThemeContext)

  const toggleTheme = (e) => {
    e.preventDefault()
    if (currentTheme === 'dark') {
      applyTheme('light')
    } else {
      applyTheme('dark')
    }
  }

  return (
    <Button
      icon={currentTheme === 'light' ? 'moon' : 'lightbulb'}
      variant="ghost"
      onClick={toggleTheme}
      aria-label="Theme Toggle"
      className="theme-toggle hidden md:inline-flex"
    />
  )
}

export default ThemeToggle
