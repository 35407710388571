import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import TikTokList from './TikTokList'
import { MatchForm, MatchSwitch } from '@fnd/components/Match'


export function TikTokMatch() {
  useEffect(() => {
    document.body.style.setProperty('--theme-primary-color', 'var(--tiktok-primary-color)')
    document.body.style.setProperty('--theme-primary-color-rgb', 'var(--tiktok-primary-color-rgb)')
    document.body.style.setProperty('--theme-secondary-color', 'var(--tiktok-secondary-color)')
    document.body.style.setProperty('--theme-secondary-color-rgb', 'var(--tiktok-secondary-color-rgb)')

    return () => {
      document.body.style.setProperty('--theme-primary-color', 'var(--primary-color)')
      document.body.style.setProperty('--theme-primary-color-rgb', 'var(--primary-color-rgb)')
      document.body.style.setProperty('--theme-secondary-color', 'var(--secondary-color)')
      document.body.style.setProperty('--theme-secondary-color-rgb', 'var(--secondary-color-rgb)')
    }
  }, [])

  return (
    <Wrapper>
      <Auth
        login
        emptyState
      >
        <MatchSwitch
          initial="tiktok"
          className="mb-8"
        />

        <MatchForm
          btnLabel="Match 3000+ TikTok stars"
          btnVariant="gradient"
          className="mb-8"
          matchTypeEnabled="tiktok"
        />

        <TikTokList />
      </Auth>
    </Wrapper>
  )
}


export default TikTokMatch
