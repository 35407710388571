import React from "react"
import {
  TableResetButton,
  TableViewOptions
} from "@fnd/components/Table"
import Field from "@fnd/components/Field"
import { useIntl } from "react-intl"
import { spotimatchEndpoints } from "@fnd/core/spotimatch"

export default function ({ table }) {
  const isFiltered = table.getState().columnFilters.length > 0
  const intl = useIntl()

  return (
    <div className="table-filters">
      <div className="flex flex-col md:flex-row flex-wrap items-center gap-2 w-full">
        <Field
          placeholder="Filter by TikTok handle"
          value={(table.getColumn("tiktok_handle")?.getFilterValue()) ?? ""}
          onChange={(event) =>
            table.getColumn("tiktok_handle")?.setFilterValue(event.target.value)
          }
          className="w-full flex-1"
        />
      </div>

      <div className="flex items-start md:flex-row flex-1 w-full md:items-center gap-2">
        <TableResetButton table={table} />

        <TableViewOptions table={table} />
      </div>
    </div>
  )
}
