import React from 'react'
import Icon from '@fnd/components/Icon'
import Field from '@fnd/components/Field'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'


const VipFormYouTube = ({
  control,
  errors,
}) => {
  const intl = useIntl()

  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-xl m-0">
        <Icon name="youtube" type="brand" className="mr-2" />
        YouTube
      </h4>

      <div className="grid md:grid-cols-2 gap-4">
        <Controller
          name="info.youtube.channelUrl"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              iconBefore={<Icon name="user-circle" />}
              label={intl.formatMessage({
                id: 'vip_campaign.field.channel_url.label',
              })}
              errors={errors?.info?.youtube?.channelUrl}
              {...field}
            />
          )}
        />

        <Controller
          name="info.youtube.videoUrl"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Field
              iconBefore={<Icon name="link" />}
              label={intl.formatMessage({
                id: 'vip_campaign.field.video_url.label',
              })}
              errors={errors?.info?.youtube?.videoUrl}
              {...field}
            />
          )}
        />
      </div>
    </div>
  )
}

export default VipFormYouTube
