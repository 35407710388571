import React, { useMemo } from 'react'
import { isEmpty } from 'lodash-es'
import Thumbnail from '@fnd/components/Thumbnail'
import { useMatchStore } from '@fnd/store'

function MatchTrack() {
  const { track } = useMatchStore()

  const thumbnailEntity = useMemo(() => {
    if (isEmpty(track)) {
      return {}
    }

    return {
      url: track.external_urls.spotify,
      picture: track.album.images[0].url,
      title: track.name,
      description: track.album.name,
      owners: track.artists,
    }
  }, [track])

  return (
    <>
      <Thumbnail
        entity={thumbnailEntity}
      />
    </>

  )
}

export default MatchTrack
