import React from 'react'
import { COMPANY_NAME } from '@fnd/constants'

const ServiceLevelAgreement = () => {
  return (
    <div className="container max-w-3xl mx-auto">
      <div className="flex flex-wrap mb-4 items-center">
        <div className="terms">
          <h1>Service-level Agreement</h1>
          <p>
            Hereby is the list of services the provider will furnish with the
            definition of the service standards the provider is obligated to
            meet.
          </p>

          <div className="flex flex-col items-start">
            <h2>
              Subscriptions
            </h2>
            <p>
              {COMPANY_NAME} / Matchfy.io do not take any responsibility in case
              users do not receive a response from the curators or curators ask
              users for more money in order to include their tracks in the
              playlists. These are not valid reasons for reimbursement. It is
              therefore the users responsibility to contact the curators and, if
              necessary, to deal with them.
            </p>
          </div>

          <div className="flex flex-col items-start">
            <h2>Prohibited Uses of Automation</h2>
            <p>
              The use of automated systems or software, including but not limited to bots, spiders, crawlers, scrapers, or any other data mining tools, is strictly forbidden on matchfy.io, whether for browsing, data extraction, or any other purpose, without our prior written consent. This prohibition extends to any form of automation that interacts with our website or app in a manner that mimics or replaces user interaction or collects data. Users must not engage in any activity that places undue burden or strain on our services or that is designed to disrupt or impair the functioning of our infrastructure, including excessive automated requests.
            </p>

            <p>
              Violation of these terms can lead to immediate suspension or termination of your account, legal action, and other enforcement actions, at our discretion.
            </p>
          </div>

          <div className="flex flex-col items-start">
            <h2>Consumer Right to Withdrawal</h2>
            <p>
              In accordance with the Consumer Code, Legislative Decree No. 206/2005, we inform you that if you are a consumer, you have the right to withdraw from the contract entered with {COMPANY_NAME} (matchfy.io) without any penalty and without specifying the reason, by notifying your intention to withdraw to {COMPANY_NAME} within fourteen (14) days from the day you received the Order Confirmation email, or from the subscription notice, or from the delivery in the case of purchasing goods.
            </p>

            <h2>Subscription Use and Refund Policy</h2>

            <p>
              Once you activate any subscription type — ARTIST, AGENCY, or VIP — you gain full access to the portal and all data within. It is important to note that once you begin using the service, even if only for promoting a single song, you are no longer eligible for a refund. This policy ensures that the service, which provides substantial value through immediate access to its resources, is not misused. Users may cancel their subscription service at any time, but no refunds will be issued for the period the service was active.
            </p>
            <p>
              If you decide to activate the subscription before the expiration of the 14-day period from the date of the order confirmation, you will agree to waive your right of withdrawal and will no longer be able to withdraw (art. 59, sections &ldquo;a&rdquo; and &ldquo;o&rdquo; of the Consumer Code). It is highlighted that activation is identified as the action of using the services.
            </p>

            <p>
              To exercise your right of withdrawal within the permissible fourteen-day window, you must provide us with a clear statement via email. It should include your personal details and those related to the order, indicating your intention to withdraw from the contract and requesting the refund of the price paid within 14 days. You may use the following template:
            </p>

            <div className="border-outline rounded p-5 bg-secondary mt-3 mb-6">
              <p>
                &quot;I, (Name), hereby notify the withdrawal from the sales contract of the subscription/goods/Gift Card (please specify).&quot;
              </p>

              <p>
                Please include:<br />
                Name of consumer<br />
                Consumer&apos;s address<br />
                Date<br />
                Signature of the consumer (if submitting by mail)
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start">
            <h2>VIP Service</h2>
            <p>
              The following statements appy for any VIP Service provided by{' '}
              {COMPANY_NAME}.
            </p>
            <ul>
              <li>
                We do not guarantee any amount of plays throughout our campaign,
                as the number of plays cannot be tracked by us and this is a full
                ORGANIC promotion.
              </li>
              <li>
                Usually the average plays provided to the previous campaigns are
                15.000 / 20.000 in the first month. Some of the tracks promoted by
                using this service reach milions of plays.
              </li>
              <li>
                However the final outcome of the campaign is proportional to the
                quality of the track and the satisfaction level of the new
                listeners.
              </li>
              <li>
                The Promo will run for 30 days and you will be able to see the
                results from your activity section.
              </li>
              <li>
                If you not feel sure to start this promotion you can request a
                more in-depth analysis from our team of specialists from the
                support section, the analysis is not free of charge.
              </li>
              <li>
                When you have done with the payment our VIP manager will contact
                you via mail and the promo will start within 48 hours.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceLevelAgreement
