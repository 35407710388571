import React, { useEffect, useState } from 'react'
import Auth from '@fnd/components/Auth'
import Empty from '@fnd/components/Empty'
import { useNavigate, useParams } from 'react-router-dom'
import AnalysisWidget from '@fnd/modules/Analysis/AnalysisWidget'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTES } from '@fnd/screens/constants'
import { Tabs, TabContent, useTabs } from '@fnd/components/Tabs'
import { CuratorBanner } from '@fnd/components/Card'
import TrackDetails from '@fnd/components/PlaylistDetails/TracksDetails'
import PlaylistInfo from './PlaylistInfo'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import {
  useCuratorStore,
  usePlaylistStore
} from '@fnd/store'


export function PlaylistDetail() {
  const navigate = useNavigate()
  const { playlistId } = useParams()
  if (!playlistId) navigate(ROUTES.CURATORS)

  const [matchScore, setMatchScore] = useState(null)
  const [matchValues, setMatchValues] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const intl = useIntl()

  const { currentCurator, loadCurator } = useCuratorStore()

  const {
    currentPlaylist,
    loadSpotifyPlaylist,
    getTracks
  } = usePlaylistStore()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const handlePlaylistMatch = (matchData) => {
    setMatchScore(matchData?.score)
    setMatchValues(matchData?.values)
  }

  const fetchData = async () => {
    setIsLoading(true)

    const playlistData = await loadSpotifyPlaylist(playlistId)
    const curatorId = playlistData?.owner?.id

    if (curatorId) {
      await loadCurator(curatorId)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const tabsData = {
    tabs: [
      {
        id: 'playlist-info',
        icon: 'arrow-right',
        label: <FormattedMessage id="match.playlist_popup.submit.title" />,
        children: (
          <PlaylistInfo
            curator={currentCurator}
            playlist={currentPlaylist}
            profile={profile}
            score={matchScore}
            values={matchValues}
            tracks={getTracks(playlistId)}
          />
        ),
      },
      {
        id: 'playlist-tracks',
        icon: 'list-music',
        label: <FormattedMessage id="profile.my_playlists.table.track_list" />,
        children: (
          <TrackDetails
            playlistId={playlistId}
          />
        ),
      },
    ],
    initialTabId: 'playlist-info',
  }

  const tabs = useTabs(tabsData)

  if (isLoading) return <Spinner />

  if (!currentPlaylist) return (
    <Wrapper>
      <Empty
        message={intl.formatMessage({
          id: 'empty_state.no_playlists_found'
        })}
      />
    </Wrapper>
  )

  return (
    <Wrapper>
      <Auth login emptyState>
        <div className="mb-12">
          {currentCurator && (
            <CuratorBanner
              className="mb-4"
              back
              curator={currentCurator}
            />
          )}

          {currentPlaylist && (
            <AnalysisWidget
              className="mb-8"
              playlist={currentPlaylist}
              onSubmit={handlePlaylistMatch}
            />
          )}

          <Tabs {...tabs.tabProps} />
          <TabContent {...tabs.contentProps} className="px-0 py-8">
            {tabs.selectedTab.children}
          </TabContent>

          {!isLoading && !currentPlaylist && (
            <Empty
              message={intl.formatMessage({
                id: 'empty_state.no_playlists_found'
              })}
            />
          )}

          {isLoading && <Spinner />}
        </div>
      </Auth>
    </Wrapper>
  )
}


export default PlaylistDetail
