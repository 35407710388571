import React from 'react'
import PodiumStep from './PodiumStep'

export default function Podium({ winners }) {
  const podium = [8, 6, 4, 2, 0, 1, 3, 5, 7, 9]
    .reduce((podiumOrder, position) => [...podiumOrder, winners[position]], [])
    .filter(Boolean)

  return (
    <div className="podium">
      {podium.map((winner) => (
        <PodiumStep
          key={winner.id}
          podium={podium}
          winner={winner}
        />
      ))}
    </div>
  )
}
