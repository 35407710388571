import React, { useState, useEffect } from 'react'
import Confetti from 'react-confetti'
import Auth from '@fnd/components/Auth'
import Emoji from '@fnd/components/Emoji'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { useIntl } from 'react-intl'
import parse from 'html-react-parser'
import { useParams, useNavigate } from 'react-router-dom'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useQuery } from '@tanstack/react-query'
import { userProfileSelector, useUserContext } from '@fnd/modules/User'
import { QUERIES } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'

function Token() {
  const [updated, setUpdated] = useState(false)
  const navigate = useNavigate()
  const { code } = useParams()
  if (!code) navigate(ROUTES.HOME)
  const intl = useIntl()

  const { profile, updateUserReferral } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateUserReferral: (next) =>
        updateUserProfile({ referral: { $set: next } }),
    })
  )

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [QUERIES.TOKEN.GET],
    queryFn: () => spotimatchEndpoints.getTokenByCode(code),
    retry: false,
    enabled: !!profile?.referral && !updated,
  })

  const updateProfile = async () => {
    if (profile?.referral && data.score > 0) {
      updateUserReferral({
        ...profile?.referral,
        score: profile.referral.score + data.score,
      })
      setUpdated(true)
    }
  }

  useEffect(() => {
    if (isSuccess && !updated) {
      updateProfile()
    }

    if (isError) {
      navigate(ROUTES.HOME)
    }
  }, [isSuccess, isError])

  if (isLoading) {
    return (
      <Wrapper className="pb-48">
        <Spinner />
      </Wrapper>)
  }

  if (!isError) {
    return (
      <Auth login emptyState>
        <Wrapper className="pb-48">
          <SectionTitle
            title={parse(intl.formatMessage(
              { id: 'token.page.title' },
              { credits: data?.score }
            ))}
            subtitle={intl.formatMessage({ id: 'token.page.subtitle' })}
            emoji={<Emoji symbol="🎉" label="party popper" />}
            center
          />

          <Confetti numberOfPieces={100} recycle={true} />
        </Wrapper>
      </Auth>
    )
  }

}

export default Token
