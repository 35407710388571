import React from 'react'
import classNames from 'classnames'
import Badge from '@fnd/components/Badge'
import { Placeholder } from '@fnd/components/Placeholder'
import { formatNumber } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'
import TrackPlayer from '@fnd/components/Track/TrackPlayer'

export function ChartItem({
  className,
  position,
  url,
  title,
  image,
  total,
  artists,
  positionDelta,
  chartStreak,
  previewUrl,
  averageMatchRate,
  timeFrame,
}) {
  const classes = classNames({
    'chart-item': true,
    [`position-${position}`]: position,
    [className]: className
  })

  const getBadgeVariant = (position) => {
    if (position === 1) return 'gold'
    if (position === 2) return 'silver'
    if (position === 3) return 'bronze'
    return 'light'
  }

  const getPositionDeltaProps = (positionDelta) => {
    if (positionDelta > 0) {
      return {
        variant: 'green-light',
        icon: 'arrow-up',
      }
    }

    if (positionDelta < 0) {
      return {
        variant: 'red-light',
        icon: 'arrow-down',
      }
    }

    return {
      variant: 'light',
      icon: 'equals',
    }
  }

  const intl = useIntl()

  return (
    <li className={classes}>
      <div className="chart-item-cover">
        <div className="chart-item-image">
          <TrackPlayer
            cover={image}
            audioSrc={previewUrl}
          />

          {!image && <>
            <Placeholder
              className="playlist-cover"
              icon="music"
            />
          </>}
        </div>
      </div>

      <div className="chart-item-content">
        <div className="flex flex-wrap items-center gap-1 mb-2">
          {position && <Badge
            variant={getBadgeVariant(position)}
            label={intl.formatMessage({ id: 'chart.position' })}
            value={position}
            icon="signal-alt-3"
          />}

          <Badge {...getPositionDeltaProps(positionDelta)} />
        </div>

        <a href={url} target="_blank" rel="noopener noreferrer">
          <h3 className="mb-2">
            {title}
          </h3>
        </a>

        {artists?.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-3">
            {artists.map((artist, index) => (
              <a
                key={index}
                href={artist?.url}
                target="_blank"
                className="flex items-center gap-1"
                rel="noopener noreferrer"
              >
                <span className="text-sm opacity-80">
                  {artist?.name}
                </span>
              </a>
            ))}
          </div>
        )}

        <div className="flex flex-wrap gap-1 mt-auto">
          {chartStreak && timeFrame && (
            <Badge
              icon="fire"
              variant="light"
              label={intl.formatMessage({ id: `chart.streak.${timeFrame}` })}
              value={chartStreak}
            />
          )}

          {total && (
            <Badge
              variant="light"
              label={intl.formatMessage({ id: 'chart.total_accepted' })}
              value={formatNumber(total)}
              icon="check"
            />
          )}

          {averageMatchRate && averageMatchRate > 0 && (
            <Badge
              variant="light"
              label={intl.formatMessage({ id: 'chart.match_average' })}
              value={averageMatchRate.toFixed(0) + '%'}
              icon="equals"
            />
          )}
        </div>
      </div>
    </li>
  )
}

export default ChartItem
