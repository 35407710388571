import React from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import {
  DistributionFaqs,
  DistributionMockup,
  DistributionStartNow
} from '@fnd/components/Distribution'
import Card from '@fnd/components/Card'
import {
  DSPsLogosMockup,
  PlansMockup,
  RoyaltiesMockup
} from '@fnd/components/Mockup'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import parse from 'html-react-parser'

const Distribution = () => {
  const intl = useIntl()

  return (
    <Wrapper>
      <SectionTitle
        title={intl.formatMessage({ id: 'distribution.title' })}
        subtitle={intl.formatMessage({ id: 'distribution.subtitle' })}
        headingClasses="text-gradient-fade"
        badge={
          <Badge
            variant="primary-light"
            label="Music Distribution"
            icon="album-collection"
          />
        }
        center
      />

      <DistributionMockup />

      <section className="max-w-screen-md mx-auto my-16">
        <p className="text-xl">
          {parse(intl.formatMessage({ id: 'distribution.intro_text' }))}
        </p>
      </section>

      <section className="max-w-screen-2xl mx-auto my-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Card
            className="p-6 border-outline"
            imageClasses="aspect-[16/10] rounded-lg border-outline overflow-hidden mb-4"
            bgClasses="bg-radial"
            background={<RoyaltiesMockup className="h-full justify-center p-4" />}
          >
            <h3 className="text-3xl text-balance text-primary mb-3">
              {intl.formatMessage({ id: 'distribution.feature.1.title' })}
            </h3>

            <p className="text-balance">
              {parse(intl.formatMessage({ id: 'distribution.feature.1.text' }))}
            </p>
          </Card>

          <Card
            className="p-6 border-outline"
            imageClasses="aspect-[16/10] rounded-lg border-outline overflow-hidden mb-4"
            bgClasses="bg-radial"
            background={<PlansMockup />}
          >
            <h3 className="text-3xl text-balance text-secondary mb-3">
              {intl.formatMessage({ id: 'distribution.feature.2.title' })}
            </h3>

            <p className="text-balance">
              {parse(intl.formatMessage({ id: 'distribution.feature.2.text' }))}
            </p>
          </Card>

          <Card
            className="p-6 border-outline"
            imageClasses="aspect-[16/10] rounded-lg overflow-hidden mb-4"
            bgClasses="bg-radial h-full justify-center p-4"
            background={<DSPsLogosMockup />}
          >
            <h3 className="text-3xl text-balance text-blue mb-3">
              {intl.formatMessage({ id: 'distribution.feature.3.title' })}
            </h3>

            <p className="text-balance">
              {parse(intl.formatMessage({ id: 'distribution.feature.3.text' }))}
            </p>
          </Card>
        </div>
      </section>

      <DistributionStartNow />

      <DistributionFaqs showTitle />
    </Wrapper>
  )
}

export default Distribution
