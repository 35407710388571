import { useState, useEffect, useCallback } from 'react'

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const useIncrementalDisplay = (
  items,
  itemsPerPageRange = [5, 15],
  delayRange = [500, 1500]
) => {
  const [displayedItems, setDisplayedItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    if (items && items.length > 0 && displayedItems < items) {
      setHasMore(true)
      const randomDelay = displayedItems.length === 0 ? 0 : getRandomInt(delayRange[0], delayRange[1])
      const randomItemsPerPage = getRandomInt(itemsPerPageRange[0], itemsPerPageRange[1])

      const timer = setTimeout(() => {
        const newItems = items.slice(displayedItems.length, displayedItems.length + randomItemsPerPage)
        setDisplayedItems((prev) => [...prev, ...newItems])
        setPage((prev) => prev + 1)
        setHasMore(displayedItems.length + newItems.length < items.length)
      }, randomDelay)

      return () => clearTimeout(timer)
    }
  }, [items, displayedItems, itemsPerPageRange, delayRange, page])

  const resetDisplayedItems = useCallback(() => {
    setDisplayedItems([])
    setPage(1)
    setHasMore(false)
  }, [])

  return { displayedItems, resetDisplayedItems, hasMore }
}

export default useIncrementalDisplay
