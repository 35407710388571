import React, { useEffect } from 'react'
import Banner, { BannerReload } from '@fnd/components/Banner'
import Footer from '@fnd/components/Footer'
import Header from '@fnd/components/Header'
import { Sign } from '@fnd/components/Sign'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Main from '@fnd/screens/Main'
import { ROUTES } from '@fnd/screens/constants'
import {
  useAffiliateStore,
  useAppStore,
  useMetadataStore
} from '@fnd/store'
import '@fnd/styles/style.scss'
import * as Sentry from '@sentry/react'
import { AnimatePresence } from 'framer-motion'
import { Toaster } from 'react-hot-toast'
import { useUserContext } from '@fnd/modules/User'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import ReactGA from 'react-ga4'
import {
  EVENTS,
  GA4_ID,
  HOTJAR_ID,
  MAINTENANCE,
  PRODUCTION,
  SENTRY_DSN,
} from './constants'
import { useConsent } from '@fnd/context/ConsentProvider'
import BlockUserPage from '@fnd/screens/BlockUserPage'
import ErrorLogin from '@fnd/screens/ErrorLogin'
import eventEmitter from './core/libs/eventEmitter'
import { hotjar } from 'react-hotjar'
import { useIntl } from 'react-intl'
import { onlyUpdateForKeys } from 'recompose'
import SingleSignOnCallback from './screens/SingleSignOnCallback'

export const Layout = ({ user }) => {
  const intl = useIntl()
  const { locale } = intl

  const { pathname } = useLocation()
  const { consent } = useConsent()
  const [searchParams] = useSearchParams()
  const { affiliate, setAffiliate } = useAffiliateStore()
  const { setMetadata } = useMetadataStore()
  const { version, setVersion } = useAppStore()

  const { updateUser } = useUserContext(
    ({ updateUserProfile }) => ({
      updateUser: (next) => updateUserProfile(next),
    })
  )

  // Event emitter
  useEffect(() => {
    const handleUpdateUser = async () => {
      try {
        const { profile: updatedProfile } = await spotimatchEndpoints.getMe().toPromise()

        updateUser({
          plan: { $set: updatedProfile.plan },
          referral: { $set: updatedProfile.referral },
          featured: { $set: updatedProfile.featured },
        })
      } catch (error) {
        console.error('Failed to update user.', error)
      }
    }

    eventEmitter.once(EVENTS.VERSION, (ver) => {
      if (ver !== version) {
        setVersion(ver)

        if (consent && ver && PRODUCTION) {
          const scope = Sentry.getCurrentScope()
          scope.addEventProcessor((event) => {
            event.release = `matchfy-production-fnd@${ver}`
            return event
          })
        }
      }
    })

    eventEmitter.on(EVENTS.USER_NEEDS_UPDATE, () => {
      handleUpdateUser()
    })
  }, [])

  // User consent
  useEffect(() => {
    if (consent && PRODUCTION && !MAINTENANCE) {
      if (SENTRY_DSN) {
        Sentry.init({
          dsn: SENTRY_DSN,
          integrations: [
            Sentry.replayIntegration({
              maskAllText: false,
            }),
            Sentry.reactRouterV6BrowserTracingIntegration({
              useEffect: React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            }),
          ],
          tracesSampleRate: 1.0,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        })
      }

      if (HOTJAR_ID) {
        hotjar.initialize(HOTJAR_ID, 6)
      }

      if (GA4_ID) {
        ReactGA.initialize(GA4_ID, { debug: !PRODUCTION })
        ReactGA.send({
          hitType: 'pageview',
          page: window.location.pathname + window.location.search,
        })
      }
    }
  }, [consent])

  // Sentry
  useEffect(() => {
    if (consent && SENTRY_DSN && PRODUCTION) {
      Sentry.setTag('locale', locale)

      if (
        user?.profile &&
        user?.profile?.id &&
        user?.profile?.email &&
        user?.profile?.spotify_username
      ) {
        Sentry.setUser({
          id: user.profile.id,
          spotify_id: user.profile.spotify_id,
          email: user.profile.email,
          username: user.profile.spotify_username,
        })

        Sentry.setTag('plan', user.profile.plan.name)
      }
    }
  }, [consent, locale, user])

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Affiliate
  useEffect(() => {
    const fetchAffiliate = async () => {
      const affiliate = await spotimatchEndpoints.getAffiliateByCode(code)
      setAffiliate(affiliate)
    }

    const applyAffiliate = async () => {
      if (
        user?.profile &&
        user?.profile?.spotify_id?.length > 0 &&
        affiliate?.userReferral &&
        affiliate?.userReferral.length > 0 &&
        user?.profile?.plan?.affiliateUser?.length === 0
      ) {
        await spotimatchEndpoints.redeemAffiliateCode(
          affiliate?.code,
          user?.profile?.spotify_id
        )
      }
    }

    const code = searchParams.get('code')
    if (code) fetchAffiliate()
    applyAffiliate()
  }, [user?.profile, searchParams])

  // Track UTM params
  useEffect(() => {
    const utm_source = searchParams.get('utm_source')
    const utm_campaign = searchParams.get('utm_campaign')
    const utm_content = searchParams.get('utm_content')
    const gclid = searchParams.get('gclid')


    if (utm_source && utm_campaign && utm_content && gclid) {
      setMetadata({
        utm_source,
        utm_campaign,
        utm_content,
        gclid
      })
    }
  }, [searchParams])

  return (
    <div id="layout">
      <Header />

      <AnimatePresence mode="wait">
        <main>
          <Routes>
            <Route path={ROUTES.BLOCKED} element={<BlockUserPage />} />
            <Route path={ROUTES.ROOT} element={<Main user={user} />} />
            <Route path={ROUTES.SPOTIFY_SIGN_CALLBACK} element={<Sign />} />
            <Route path={ROUTES.SPOTIFY_SSO_CALLBACK} element={<SingleSignOnCallback />} />
            <Route path={ROUTES.ERROR_LOGIN} element={<ErrorLogin />} />
          </Routes>
        </main>
      </AnimatePresence>

      <Banner />
      <BannerReload />

      <Footer />

      <Toaster position="bottom-right" />
    </div>
  )
}

export default onlyUpdateForKeys(['user'])(Layout)
