import ConditionalLink from '@fnd/components/ConditionalLink/ConditionalLink'
import Badge from '@fnd/components/Badge'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import sanityClient from '@fnd/core/sanity/client'
import { mentionQuery } from '@fnd/core/sanity/queries'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

const Mentions = ({ className }) => {
  const [mentions, setMentions] = useState([])
  const intl = useIntl()

  const classes = classNames({
    reviews: true,
    section: true,
    'container mx-auto': true,
    [className]: className,
  })

  useEffect(() => {
    const fetchMentions = async () => {
      try {
        const data = await sanityClient.fetch(mentionQuery)
        setMentions(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMentions()
  }, [])

  const renderMentions = mentions.map((item, i) => {
    if (!item?.image) return null

    const { invertLogo } = item

    const classes = classNames({
      'mentions-grid-item': true,
      'invert-logo': invertLogo
    })

    return (
      <div key={i} className={classes}>
        <ConditionalLink
          to={`${item.url}?ref=matchfy`}
          condition={item.url && item.url.length > 0}
          external={true}
        >
          <div className="mentions-grid-item-image">
            <img src={item.image.url} alt={item.title} />
          </div>
          <h5>{item?.title}</h5>
        </ConditionalLink>
      </div>
    )
  })

  const gridClasses = classNames({
    grid: true,
    'grid-cols-2': true,
    'md:grid-cols-3': true,
    'lg:grid-cols-6': true,
    'gap-x-4 gap-y-8 md:gap-4': true,
  })

  return (
    <div id="mentions" className={classes}>
      <SectionTitle
        tag="h3"
        center
        title={intl.formatMessage({ id: 'home.mentioned_by.title' })}
        subtitle={intl.formatMessage({ id: 'home.mentioned_by.subtitle' })}
        badge={
          <Badge
            variant="blue-light"
            label={intl.formatMessage({ id: 'messages.mentions' })}
            icon="pencil-alt"
          />
        }
      />
      <div className={gridClasses}>
        {mentions && mentions.length > 0 ? renderMentions : null}
      </div>
    </div>
  )
}

export default Mentions
