import React from 'react'
import { DEVELOPMENT } from '@fnd/constants'

export default function Development({ children }) {
  if (DEVELOPMENT) {
    return (
      <>
        {children}
      </>
    )
  }
}
