import { FOOTER_NAV_1, FOOTER_NAV_2 } from '@fnd/screens/constants'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const FooterNavigation = () => {
  return (
    <>
      <ul key="footer-nav-1" className="footer-menu">
        {FOOTER_NAV_1.map((nav) => nav.show && (
          <li key={nav.link}>
            <Link to={nav.link}>
              <FormattedMessage id={nav.title} />
            </Link>
          </li>
        ))}
      </ul>
      <ul key="footer-nav-2" className="footer-menu">
        {FOOTER_NAV_2.map((nav) => nav.show && (
          <li key={nav.link}>
            <Link to={nav.link}>
              <FormattedMessage id={nav.title} />
            </Link>
          </li>
        ))}
        <li key="cookie-preferences">
          <a className="iubenda-cs-preferences-link">
            <FormattedMessage id="messages.cookie_preferences" />
          </a>
        </li>
      </ul>
    </>
  )
}

export default FooterNavigation
