import React, { useEffect } from 'react'
import Auth from '@fnd/components/Auth'
import { MatchForm, MatchSwitch } from '@fnd/components/Match'
import SpotifyList from './Spotify/SpotifyList'
import Wrapper from '@fnd/components/Wrapper/Wrapper'

function Analysis() {
  useEffect(() => {
    document.body.style.setProperty('--theme-primary-color', 'var(--green-color)')
    document.body.style.setProperty('--theme-primary-color-rgb', 'var(--green-color-rgb)')
    document.body.style.setProperty('--theme-secondary-color', 'var(--dark-green-color)')
    document.body.style.setProperty('--theme-secondary-color-rgb', 'var(--dark-green-color-rgb)')

    return () => {
      document.body.style.setProperty('--theme-primary-color', 'var(--primary-color)')
      document.body.style.setProperty('--theme-primary-color-rgb', 'var(--primary-color-rgb)')
      document.body.style.setProperty('--theme-secondary-color', 'var(--secondary-color)')
      document.body.style.setProperty('--theme-secondary-color-rgb', 'var(--secondary-color-rgb)')
    }
  }, [])

  return (
    <Wrapper>
      <Auth
        login
        emptyState
      >
        <MatchSwitch
          initial="spotify"
          className="mb-8"
        />

        <MatchForm
          btnLabel="Match 500+ playlists"
          btnVariant="gradient"
          className="mb-8"
          matchTypeEnabled="spotify"
        />

        <SpotifyList />
      </Auth>
    </Wrapper>
  )
}

export default Analysis
