import React, { useEffect } from 'react'
import { useTabs, TabLinks } from '@fnd/components/Tabs'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

export function ViewSwitch({
  view,
  setView,
  className
}) {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()

  const switchTabs = [
    {
      id: 'grid',
      label: intl.formatMessage({ id: 'messages.grid' }),
      icon: 'border-all',
      onClick: () => handleViewChange('grid')
    },
    {
      id: 'list',
      label: intl.formatMessage({ id: 'messages.list' }),
      icon: 'list',
      onClick: () => handleViewChange('list')
    }
  ]

  const handleViewChange = (newView) => {
    setView(newView)
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('view', newView)
    // navigate(`?${searchParams.toString()}`)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const initialView = searchParams.get('view')
    if (initialView && (initialView === 'grid' || initialView === 'list')) {
      setView(initialView)
    }
  }, [location.search, setView])

  const tabsData = {
    tabs: switchTabs,
    initialTabId: view,
  }

  const tabs = useTabs(tabsData)

  const classes = classNames({
    'tab-switch': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <TabLinks
        {...tabs.tabProps}
        inline
        variant="toggle"
        selectedTabIndex={view === 'grid' ? 0 : 1}
        showIndicator={false}
        showHover={false}
      />
    </div>
  )
}

export default ViewSwitch
