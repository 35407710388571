import React from "react"
import Button from "@fnd/components/Button"
import { useIntl } from "react-intl"
import { toastFeedback } from "@fnd/core/libs/toast"

export function TableExportButton({
  exportFn,
  table,
  btnIcon = 'file-export',
  btnVariant = 'theme-primary',
}) {
  const selectedRowsCount = Object.keys(table.getState().rowSelection).length
  const intl = useIntl()

  const handleFileExport = async () => {
    const selectedRows = table.getState().rowSelection

    if (selectedRows.length === 0) {
      toastFeedback('error', intl.formatMessage({ id: 'feedback.error.export_selection' }))
      return
    }

    const selectedIds = Object.keys(selectedRows)
    if (selectedIds.length <= 0 || !exportFn) return

    try {
      await exportFn(selectedIds)
      toastFeedback('success', intl.formatMessage({ id: 'feedback.success.export' }))
    } catch (error) {
      toastFeedback('error', intl.formatMessage({ id: 'feedback.error.default' }))
    }
  }

  return (
    <Button
      variant={btnVariant}
      icon={btnIcon}
      onClick={handleFileExport}
      className="whitespace-nowrap"
    >
      {selectedRowsCount > 0 ? (
        <span>
          {intl.formatMessage(
            { id: 'messages.export_count' },
            { count: selectedRowsCount }
          )}
        </span>
      ) : (
        <span>
          {intl.formatMessage({ id: "messages.export" })}
        </span>
      )}
    </Button>
  )
}
