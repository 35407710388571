import React, { useState, useEffect } from "react"
import {
  TableArrayFilter,
  TableExportButton,
  TableResetButton,
  TableViewOptions
} from "@fnd/components/Table"
import Field from "@fnd/components/Field"
import { useIntl } from "react-intl"
import { spotimatchEndpoints } from "@fnd/core/spotimatch"

export default function ({
  data,
  table
}) {
  const [tagOptions, setTagOptions] = useState([])
  const isFiltered = table.getState().columnFilters.length > 0
  const intl = useIntl()

  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueTags = new Set()
      data.forEach(row => {
        if (row.tags) {
          row.tags.forEach(tag => uniqueTags.add(tag))
        }
      })

      const sortedTagOptions = [...uniqueTags]
        .sort((a, b) => a.localeCompare(b))
        .map(tag => ({ label: tag, value: tag }))

      setTagOptions(sortedTagOptions)
    }
  }, [data])

  return (
    <div className="table-filters">
      <div className="flex flex-col md:flex-row flex-wrap items-center gap-2 w-full">
        <Field
          className="w-full flex-1"
          placeholder="Filter by name"
          value={(table.getColumn("name")?.getFilterValue()) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
        />

        {table.getColumn("tags") && (
          <TableArrayFilter
            icon="tag"
            className="w-full flex-1"
            column={table.getColumn("tags")}
            title="Filter by tags"
            options={tagOptions}
          />
        )}
      </div>

      <div className="flex items-start md:flex-row flex-1 w-full md:items-center gap-2">
        <TableResetButton table={table} />

        <TableViewOptions table={table} />

        <TableExportButton
          exportFn={spotimatchEndpoints.exportRadios}
          table={table}
        />
      </div>
    </div>
  )
}
