import React, { useEffect, useState } from 'react'
import Icon from '@fnd/components/Icon'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import Genres from '@fnd/modules/Analysis/Search/Genres'
import { PLANS } from '@fnd/constants'
import { Controller } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useVipCampaignStore } from '@fnd/store'

const VipFormSpotify = ({
  control,
  errors,
  plan,
  profile,
  setValue
}) => {
  const { form } = useVipCampaignStore()
  const [spotifyArtistId, setSpotifyArtistId] = useState(null)

  const handleArtistChange = (data) => {
    setSpotifyArtistId(data?.id)
    setValue('info.spotify.artist', {
      id: data?.id,
      name: data?.name,
      image: data?.images[0]?.url,
      popularity: data?.popularity,
    })
  }

  const handleTrackChange = (data) => {
    setValue('info.spotify.track', {
      id: data?.id,
      name: data?.name,
      image: data?.album?.images[0]?.url,
    })
  }

  useEffect(() => {
    if (plan.name !== PLANS.AGENCY && profile.spotify_artist_id) {
      setSpotifyArtistId(profile.spotify_artist_id[0])
    } else {
      setSpotifyArtistId(form?.info?.spotify?.artistId)
    }
  }, [])

  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-xl m-0">
        <Icon name="spotify" type="brand" className="mr-2" />
        Spotify
      </h4>

      <div className="grid md:grid-cols-2 gap-4">
        <Controller
          name="info.spotify.artistId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SearchSelect
              defaultValue={spotifyArtistId}
              {...field}
              entity="artist"
              onDataChange={handleArtistChange}
              errors={errors?.info?.spotify?.artistId}
              isDisabled={
                plan.name !== PLANS.AGENCY &&
                profile.spotify_artist_id.length > 0
              }
            />
          )}
        />

        {spotifyArtistId?.length > 0 && (
          <Controller
            name="info.spotify.trackId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SearchSelect
                defaultValue={form?.info?.spotify?.trackId}
                {...field}
                entity="track"
                limitBy="artist"
                artistIds={[spotifyArtistId]}
                onDataChange={handleTrackChange}
                errors={errors?.info?.spotify?.trackId}
              />
            )}
          />
        )}

        <Controller
          name="info.spotify.genres"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Genres
              className="md:col-span-2"
              label={<FormattedMessage id="messages.genres" />}
              onGenreSelect={(genres) =>
                setValue('info.spotify.genres', genres)
              }
              defaultValue={form?.info?.spotify?.genres}
              errors={errors?.info?.spotify?.genres}
              {...field}
            />
          )}
        />
      </div>
    </div>
  )
}

export default VipFormSpotify
