import React from 'react'
import Button from '@fnd/components/Button'
import Emoji from '@fnd/components/Emoji'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'

function Paywall({
  icon, emoji, message, className, ctaLabel, ctaLink, children
}) {
  const classes = classNames({
    'paywall': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {emoji && emoji}
      {icon && <Icon name={icon} />}
      {!emoji && !icon && <Emoji symbol="🙁" label="Sad Face" />}

      {message && <p>{message}</p>}
      {children && <p>{children}</p>}

      {ctaLabel && ctaLink && (
        <Button
          className="mt-4"
          variant="primary"
          to={ctaLink}
          label={ctaLabel}
        />
      )}
    </div>
  )
}

export default Paywall
