import React from 'react'
import Wrapper from '@fnd/components/Wrapper'
import { PlanetXLogo } from '@fnd/components/Logo/Logo'


const ContractOfService = () => {
  return (
    <Wrapper id="terms" className="max-w-3xl mb-32">
      <div className="text-center mt-32 mb-8">
        <div className="block mx-auto max-w-[320px] mb-8">
          <PlanetXLogo />
        </div>

        <h1 className="text-2xl text-center uppercase tracking-wide">
          TERMS AND CONDITIONS
        </h1>

        <h2 className="text-xl text-center uppercase tracking-wide">
          CONTRACT OF SERVICE - MATCHFY.IO A PLANET X BRAND
        </h2>

        <h3 className="text-lg text-center">
          SERVICES: Pitch to Playlist and Additional Promotion Services
        </h3>
      </div>

      <div className="flex flex-col items-start gap-8">
        <div>
          <h4>
            Article 1: VIP Service
          </h4>

          <p className="mb-3">
            1.1. Planet X SRL provides VIP services, acting as an intermediary between curators and artists, recognized by Spotify
            GLOBAL AB.
          </p>

          <p className="mb-3">
            1.2. The application has been verified by the legal team of SPOTIFY AB, adhering to Spotify&apos;s guidelines.
          </p>

          <p className="mb-3">
            1.3. Planet X SRL does not guarantee a specific number of plays, as play counts are not monitored by the company. The
            promotion is entirely ORGANIC.
          </p>

          <p className="mb-3">
            1.4. Success depends on the quality of the track and satisfaction of new listeners.
          </p>
        </div>

        <div>
          <h4>
            Article 2: Additional Promotion Services
          </h4>

          <p className="mb-3">
            2.1. In addition to Pitch to Playlist, Planet X SRL offers a variety of promotion services including but not limited to
            YouTube, TikTok, Meta, and more.
          </p>
        </div>

        <div>
          <h4>
            Article 3: Duration and Monitoring
          </h4>

          <p className="mb-3">
            3.1. The promotion will last for 30/60 days for each track, with results accessible in the activity section on matchfy.io.
          </p>
        </div>

        <div>
          <h4>
            Article 4: Start of Promotion
          </h4>

          <p className="mb-3">
            4.1. After payment, Planet X SRL&apos;s VIP manager will contact the client via email, and the promotion will commence within
            48 working hours.
          </p>
        </div>

        <div>
          <h4>
            Article 5: Reporting and Liability
          </h4>

          <p className="mb-3">
            5.1. No additional reports beyond those available on matchfy.io are provided. Reports include additional analysis of the
            artist&apos;s profile and promotion.
          </p>

          <p className="mb-3">
            5.2. Planet X SRL does not assume responsibility for copyright issues or other problems not caused by its work.
          </p>
        </div>

        <div>
          <h4>
            Article 6: Advertising and Third Parties
          </h4>

          <p className="mb-3">
            6.1. By accepting this agreement, the client grants Planet X SRL permission to use the song/artist name and associated
            content to promote the track through its social networks.
          </p>

          <p className="mb-3">
            6.2. Planet X SRL is not responsible for strikes from third-party distributors beyond its knowledge.
          </p>

          <p className="mb-3">
            6.3. In case of using services or partners other than Planet X SRL, the client must inform the company via <a href="mailto:info@planetxsrl.com">info@planetxsrl.com</a> or pec <a href="mailto:planetxsrl@legalmail.it">planetxsrl@legalmail.it</a>.
          </p>
        </div>

        <div>
          <h4>
            Article 7: Contents
          </h4>

          <p className="mb-3">
            7.1. For ongoing promotions not involving Planet X SRL, the client must send all content related to the track (Artwork,
            Promotional Videos, Photos, Press Kit).
          </p>
        </div>

        <div>
          <h4>
            Article 8: Privacy
          </h4>

          <p className="mb-3">
            8.1. The privacy policy is available at <a href="https://www.matchfy.io/privacy">
              https://www.matchfy.io/privacy
            </a>
          </p>
        </div>

        <div>
          <h4>
            Article 9: Future Promotions
          </h4>

          <p className="mb-3">
            9.1. Any subsequent promotions must be planned and quoted by Planet X SRL&apos;s VIP Director and accepted through a
            contract.
          </p>
        </div>

        <div>
          <h4>
            Article 10: Goals of the VIP Promotion
          </h4>

          <p className="mb-3">
            10.1. The VIP promotion aims to increase the artist&apos;s and track&apos;s popularity, leading to inclusion in key Spotify algorithmic
            playlists.
          </p>

          <p className="mb-3">
            10.2. The TRIG TO ALGO promo (as indicated in the quote, if purchased) concludes when specific playlists are
            activated. Once activated, Planet X SRL has no further control, as they are entirely managed by Spotify.
          </p>
        </div>

        <div>
          <h4>
            Article 11: External Playlists
          </h4>

          <p className="mb-3">
            11.1. Playlists to which tracks are sent are not owned by Planet X SRL, being curated by third parties not affiliated with
            the company. The company assumes no responsibility for play counts or their origin.
          </p>
        </div>

        <div>
          <h4>
            Article 12: Algorithm-Based Promotion
          </h4>

          <p className="mb-3">
            12.1. Promotions are not dependent on the company&apos;s team commitment, relying on algorithms and being uniform for all
            users based on mathematical calculations.
          </p>
        </div>

        <div>
          <h4 className="uppercase">
            Acceptance
          </h4>

          <p className="mb-3">
            By accepting this agreement on Matchfy.io, the client hereby acknowledges having read and understood all provisions
            and fully accepts them. Planet X SRL and the client confirm being bound by the terms and conditions set forth in this
            agreement.
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export default ContractOfService
