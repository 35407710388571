import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'

export default function PodiumStep({ podium, winner, className }) {
  const navigate = useNavigate()
  const offset = podium.length - winner.position

  const classes = classNames({
    'podium-step': true,
    [`podium-step-${winner.position + 1}`]: true,
    'cursor-pointer': true,
    [className]: className
  })

  const handleClick = () => {
    navigate(`${ROUTES.CURATORS}/${winner.owner}`)
  }

  return (
    <div className={classes} onClick={handleClick}>
      <motion.div
        className="podium-avatar"
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            opacity: 1,
            transition: {
              delay: 0.3 + offset / 2,
              duration: 0.6,
            }
          },
          hidden: { opacity: 0 }
        }}
      >
        <img
          src={winner.images[0]?.url}
          alt={winner.display_name ?? winner.owner}
        />
      </motion.div>

      <motion.div
        className="podium-step-col"
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            height: 200 * (offset / podium.length),
            opacity: 1,
            transition: {
              delay: 0.2 + offset / 2,
              duration: 0.4,
              ease: 'backInOut'
            }
          },
          hidden: { opacity: 0, height: 0 }
        }}
      >
        <span className="podium-index">
          {winner.position + 1}
        </span>
      </motion.div>
    </div>
  )
}
