import React from "react"
import { Checkbox } from "@fnd/components/Field/Checkbox"
import Badge from "@fnd/components/Badge"
import Icon from "@fnd/components/Icon"
import { TableColumnHeader } from "@fnd/components/Table/TableColumnHeader"
import { DataTableRowActions } from "./MagazineTableActions"
import { getMediaUrl } from '@fnd/core/libs/media'
import { truncate } from 'lodash-es'

export const getColumns = (intl, actionProps = {}) => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_all' })}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label={intl.formatMessage({ id: 'messages.select_row' })}
      />
    ),
    show: false,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <TableColumnHeader
        column={column}
        title="Name"
      />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <div className="flex items-center gap-4">
          {item?.logo && (
            <img
              src={getMediaUrl(item.logo)}
              alt={item.tiktok_handle}
              className="w-[42px] h-[42px] rounded"
            />
          )}

          <div className="flex flex-col items-start">
            <span className="font-medium">
              {item.name}
            </span>
            {item?.description && (
              <span className="text-sm opacity-80">
                {item.description.length > 80 ? truncate(item.description, { length: 100 }) : item.description}
              </span>
            )}
          </div>
        </div>
      )
    },
    enableSorting: true,
    enableHiding: true,
  },
  {
    accessorKey: "tags",
    header: ({ column }) => (
      <TableColumnHeader
        column={column}
        title="Tags"
      />
    ),
    cell: ({ row }) => {
      const item = row.original
      if (!item.tags || item.tags.length === 0) return null

      return (
        <div className="flex flex-wrap gap-2">
          {item.tags.map((tag) => (
            <Badge
              key={tag}
              variant="orange-light"
              label={tag}
            />
          ))}
        </div>
      )
    },
    filterFn: (row, id, value) => {
      const rowValue = row.getValue(id)
      if (!rowValue) return false
      return value.some((tag) => rowValue.includes(tag))
    },
  },
  {
    accessorKey: "website",
    header: ({ column }) => (
      <TableColumnHeader
        column={column}
        title="Website"
      />
    ),
    cell: ({ row }) => {
      const item = row.original

      return (
        <a
          href={item.website}
          target="_blank"
          rel="noreferrer"
          className="inline-flex items-center gap-2 text-orange underline"
        >
          {item.website}
          <Icon name="external-link" />
        </a>
      )
    },
  },
  {
    id: "actions",
    cell: ({ row }) =>
      <DataTableRowActions
        row={row}
        {...actionProps}
      />,
  },
]

export default getColumns
