import React from 'react'
import { useIntl } from 'react-intl'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import Badge from '@fnd/components/Badge'

const youtubeLinks = {
  it: [
    {
      title: 'Svela i segreti del tuo profilo artista: guida all\'analisi',
      url: 'https://www.youtube.com/embed/aLmR44rXoQI'
    },
    {
      title: 'Come gestire il tuo profilo Matchfy',
      url: 'https://www.youtube.com/embed/FOqxdFpWfoU'
    },
    {
      title: 'Come scegliere il piano ideale per la tua carriera musicale',
      url: 'https://www.youtube.com/embed/nfYWW6aGNxE'
    },
    {
      title: 'Come promuovere il tuo profilo curatore e le tue playlist',
      url: 'https://www.youtube.com/embed/0RkTgYl6LAI'
    },
    {
      title: 'Push to playlist: come inviare la tua musica ai curatori',
      url: 'https://www.youtube.com/embed/B7u-FBQUqkI'
    },
    {
      title: 'Diventa un curatore: gestione delle submissions (con Auto AI)',
      url: 'https://www.youtube.com/embed/SRxDyk2sqE4'
    },
    {
      title: 'Pubblica la tua musica con Matchfy Distribution',
      url: 'https://www.youtube.com/embed/gHS6ylzNe5k'
    },
    {
      title: 'Come creare una release di successo: guida alla pubblicazione',
      url: 'https://www.youtube.com/embed/ZRjA3OARR-E'
    }
  ],
  en: [
    {
      title: 'Welcome to Matchfy',
      url: 'https://www.youtube.com/embed/sVwc7wDY-Vc',
    },
    {
      title: 'Find the best plan for your music career',
      url: 'https://www.youtube.com/embed/PVT4b1jT-OU',
    },
    {
      title: 'Master your Matchfy profile: unlock its full potential',
      url: 'https://www.youtube.com/embed/ri7YtlcXleQ',
    },
    {
      title: 'Push to playlist: how to send your music to curators and manage submissions (with Auto AI)',
      url: 'https://www.youtube.com/embed/K4rGN9kXTzA',
    },
    {
      title: 'How to promote your curator profile and your playlists',
      url: 'https://www.youtube.com/embed/ayIqxjvuT58',
    },
    {
      title: 'Publish your music with Matchfy Distribution',
      url: 'https://www.youtube.com/embed/_EB9ByBQUfI',
    },
    {
      title: 'Create a new release: step-by-step guide',
      url: 'https://www.youtube.com/embed/6XmwkEw0jUw'
    }
  ],
  es: [
    {
      title: 'Welcome to Matchfy',
      url: 'https://www.youtube.com/embed/sVwc7wDY-Vc',
    },
    {
      title: 'Find the best plan for your music career',
      url: 'https://www.youtube.com/embed/PVT4b1jT-OU',
    },
    {
      title: 'Master your Matchfy profile: unlock its full potential',
      url: 'https://www.youtube.com/embed/ri7YtlcXleQ',
    },
    {
      title: 'Push to playlist: how to send your music to curators and manage submissions (with Auto AI)',
      url: 'https://www.youtube.com/embed/K4rGN9kXTzA',
    },
    {
      title: 'How to promote your curator profile and your playlists',
      url: 'https://www.youtube.com/embed/ayIqxjvuT58',
    },
    {
      title: 'Publish your music with Matchfy Distribution',
      url: 'https://www.youtube.com/embed/_EB9ByBQUfI',
    },
    {
      title: 'Create a new release: step-by-step guide',
      url: 'https://www.youtube.com/embed/6XmwkEw0jUw'
    }
  ],
  fr: [
    {
      title: 'Welcome to Matchfy',
      url: 'https://www.youtube.com/embed/sVwc7wDY-Vc',
    },
    {
      title: 'Find the best plan for your music career',
      url: 'https://www.youtube.com/embed/PVT4b1jT-OU',
    },
    {
      title: 'Master your Matchfy profile: unlock its full potential',
      url: 'https://www.youtube.com/embed/ri7YtlcXleQ',
    },
    {
      title: 'Push to playlist: how to send your music to curators and manage submissions (with Auto AI)',
      url: 'https://www.youtube.com/embed/K4rGN9kXTzA',
    },
    {
      title: 'How to promote your curator profile and your playlists',
      url: 'https://www.youtube.com/embed/ayIqxjvuT58',
    },
    {
      title: 'Publish your music with Matchfy Distribution',
      url: 'https://www.youtube.com/embed/_EB9ByBQUfI',
    },
    {
      title: 'Create a new release: step-by-step guide',
      url: 'https://www.youtube.com/embed/6XmwkEw0jUw'
    }
  ],
}

const Tutorials = () => {
  const intl = useIntl()
  const locale = intl.locale ? intl.locale : 'en'

  return (
    <Wrapper>
      <SectionTitle
        title={intl.formatMessage({ id: 'tutorials.page.title' })}
        subtitle={intl.formatMessage({ id: 'tutorials.page.subtitle' })}
        center
        badge={
          <Badge
            variant="primary-light"
            icon="graduation-cap"
            label={intl.formatMessage({ id: 'navigation.tutorials' })}
          />
        }
      />

      <div className="tutorials-grid mb-16">
        {youtubeLinks[locale].map((tutorial) => (
          <div key={tutorial.url} className="tutorial-card border-outline">
            <h4 className="text-balance">
              {tutorial.title}
            </h4>

            <iframe
              width="100%"
              height="300px"
              src={tutorial.url}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title={tutorial.title}
              allowFullScreen
            />
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

export default Tutorials
