import React, { useEffect, useState } from 'react'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import AuthVerify from '@fnd/components/Auth/AuthVerify'
import {
  AccelerateIntro,
  AccelerateForm,
  AccelerateDaysRemaining,
  AccelerateRequirements,
} from '@fnd/components/Accelerate'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { isUserAuthorizedSelector } from '@fnd/modules/User'
import Spinner from '@fnd/components/Spinner'

const useReceiveAccelerates = () => {
  const [acceleratesState, setAccelerates] = useState({
    isLoading: true,
    error: null,
    accelerates: [],
  })
  const patch = (nextState) =>
    setAccelerates((currentState) => ({ ...currentState, ...nextState }))

  const addAccelerates = (accelerate) =>
    patch({ accelerates: acceleratesState.accelerates.concat(accelerate) })

  useEffect(() => {
    spotimatchEndpoints
      .getAcceleratesThisMonth()
      .toPromise()
      .then((response) => patch({ isLoading: false, accelerates: response }))
      .catch(console.log)
  }, [])

  return [acceleratesState, addAccelerates]
}

const AccelerateContent = () => {
  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))
  const [acceleratesState, addAccelerates] = useReceiveAccelerates()

  const hasUserUsedAccelerated = Boolean(
    acceleratesState.accelerates.length || !isAuthorized
  )

  return (
    <div className="relative max-w-screen-lg mx-auto pb-32">
      <AccelerateIntro />

      <div id="accelerate-apply" className="grid grid-cols-1 md:grid-cols-2 gap-16">
        <div className="relative">
          <AccelerateRequirements />

          <AccelerateDaysRemaining
            className="mt-8"
            hasAccelerated={hasUserUsedAccelerated}
          />
        </div>

        <div>
          <AuthVerify className="my-0">
            {acceleratesState.isLoading && <Spinner className="mt-8" />}

            {!hasUserUsedAccelerated && (
              <AccelerateForm onSubmit={addAccelerates} />
            )}
          </AuthVerify>
        </div>
      </div>
    </div>
  )
}

export default AccelerateContent
