import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidEmail } from '@fnd/core/libs/validate'
import cx from 'classnames'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { PLANS } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Icon from '@fnd/components/Icon'
import Select from 'react-select'
import Spinner from '@fnd/components/Spinner'
import Paywall from '@fnd/components/Paywall'
import countries from '@fnd/modules/Profile/BillingInfo/countries.json'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import {
  userProfileSelector,
  isUserAuthorizedSelector
} from '@fnd/modules/User'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import ReactGA from 'react-ga4'
import Auth from '@fnd/components/Auth'
import parse from 'html-react-parser'
import { toastFeedback } from '@fnd/core/libs/toast'

const validation = {
  link: (value) => !!value && !value.includes('spotify'),
  trackName: (value) => !!value,
  artistId: (value) => !!value,
  email: (value) => isValidEmail(value),
  name: (value) => value.length > 2,
}

const validate = (value, type) => {
  if (typeof value !== 'string' || !validation[type]) {
    return true
  }
  return validation[type](value)
}


const initialState = {
  name: {
    value: '',
    valid: false,
    touched: false,
  },
  trackName: {
    value: '',
    valid: false,
    touched: false,
  },
  email: {
    value: '',
    valid: false,
    touched: false,
  },
  artistId: {
    value: '',
    valid: false,
    touched: false,
  },
  link: {
    value: '',
    valid: false,
    touched: false,
  },
  country: {
    value: '',
    valid: true,
    touched: false,
  },
  phone: {
    value: '',
    valid: true,
    touched: false,
  },
}

export default function AccelerateForm({
  btnLabel,
  onSubmit,
  bypassLimit = false,
}) {
  const { isAuthorized, profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [isPhoneValid, setPhoneValid] = useState(true)

  const updateField = (field) => (name, value) =>
    setState((currentState) => ({
      ...currentState,
      [name]: { ...currentState[name], [field]: value },
    }))

  const updateValue = updateField('value')
  const updateValid = updateField('valid')
  const updateTouched = updateField('touched')

  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [termsAgreed, setTermsAgreed] = useState(false)

  const termsChangeHandler = ({ target }) => {
    setTermsAgreed(target.checked)
  }

  const countryChangeHandler = (option, { action }) => {
    if (!['select-option', 'clear'].includes(action)) {
      return
    }

    const value = option ? option.value : ''
    updateValue('country', value)
  }

  const changeHandler = ({ target }) => {
    const { name, value } = target
    updateValue(name, value)
  }

  const phoneChangeHandler = (value) => {
    if (!value) return
    const isValid = isValidPhoneNumber(value)
    setPhoneValid(isValid)
    updateValue('phone', isValid ? formatPhoneNumberIntl(value) : '')
  }

  const blurHandler = ({ target }) => {
    const { value, name } = target

    if (validate(value, name)) {
      return
    }

    updateValid(name, false)
  }

  const focusHandler = ({ target }) => {
    const { name } = target
    updateValid(name, true)
    if (!state[name].touched) {
      updateTouched(name, true)
    }
  }

  const isFormValid = Object.values(state).every(({ valid }) => valid) && termsAgreed && isPhoneValid

  const submitHandler = (evt) => {
    evt.preventDefault()
    Object.entries(state).forEach(([key, { touched }]) => {
      if (!touched) {
        updateTouched(key, true)
      }
    })

    if (!isFormValid || isSubmitLoading) {
      return
    }

    setSubmitLoading(true)

    const payload = {
      name: state.name.value,
      trackName: state.trackName.value,
      email: state.email.value,
      artistId: state.artistId.value,
      phone: state.phone.value,
      link: state.link.value,
      country: state.country.value,
    }

    try {
      spotimatchEndpoints
        .createAccelerate(payload)
        .toPromise()
        .then((response) => {
          if (onSubmit) {
            onSubmit(response)
          }

          ReactGA.event({
            category: 'Accelerate',
            action: 'Accelerate form submitted',
          })

          setSuccess(true)
          toastFeedback('success', intl.formatMessage({ id: 'feedback.success.accelerate' }))
        })
    } catch (error) {
      console.log(error)
      setError(true)
      toastFeedback('error', intl.formatMessage({ id: 'feedback.error.default' }))
    } finally {
      setSubmitLoading(false)
    }
  }

  const getInputClasses = (inputName) =>
    cx({
      invalid: !state[inputName].valid && state[inputName].touched,
    })

  const btnDisabled = isSubmitLoading || (isAuthorized && !isFormValid)

  if (success) {
    return (
      <Empty
        className="my-0"
        variant="success"
        icon="check-circle"
        message={intl.formatMessage({ id: 'feedback.success.accelerate' })}
      />
    )
  }

  if (error) {
    return (
      <Empty
        className="my-0"
        message={intl.formatMessage({ id: 'feedback.error.default' })}
        variant="error"
      />
    )
  }

  if (isSubmitLoading) {
    return (
      <Spinner />
    )
  }

  return (
    <Auth login emptyState>
      {profile?.plan?.name !== PLANS.FREE || bypassLimit ? (
        <form className="w-full flex flex-col gap-4">
          <div className="form-group">
            <label htmlFor="accelerate-link">
              <FormattedMessage id="accelerate.form.link" />
              <Icon
                name="info-circle"
                className="container-fluid hovertext"
                text="accelerate.form.hoverLink"
              />
            </label>
            <input
              className={getInputClasses('link')}
              type="text"
              name="link"
              id="accelerate-link"
              value={state.link.value}
              onBlur={blurHandler}
              onFocus={focusHandler}
              onChange={changeHandler}
              placeholder={intl.formatMessage({
                id: 'accelerate.form.link.placeholder',
              })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="accelerate-email">
              <FormattedMessage id="accelerate.form.email" />
            </label>
            <input
              onBlur={blurHandler}
              onFocus={focusHandler}
              onChange={changeHandler}
              value={state.email.value}
              className={getInputClasses('email')}
              type="email"
              name="email"
              id="accelerate-email"
              placeholder={intl.formatMessage({
                id: 'accelerate.form.email.placeholder',
              })}
            />
          </div>

          <div className="form-group">
            <SearchSelect
              id="artist-field"
              className="flex-1"
              name="name"
              entity="artist"
              tooltipText={<FormattedMessage id="accelerate.form.hoverArtist" />}
              label={<FormattedMessage id="accelerate.form.artistId" />}
              onChange={value =>
                setState(state => ({
                  ...state,
                  artistId: { value, touched: true, valid: true },
                })
                )}
              onClear={() =>
                setState(state => ({
                  ...state,
                  artistId: { value: null, touched: false, valid: false },
                })
                )}
              isDisabled={false}
            />
          </div>

          <div className="form-group">
            <label htmlFor="accelerate-name">
              <FormattedMessage id="accelerate.form.name" />
            </label>
            <input
              onBlur={blurHandler}
              onFocus={focusHandler}
              onChange={changeHandler}
              value={state.name.value}
              className={getInputClasses('name')}
              type="text"
              name="name"
              id="accelerate-name"
              placeholder={intl.formatMessage({
                id: 'accelerate.form.name.placeholder',
              })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="billing-phone">
              <FormattedMessage id="field.phone.label" />
            </label>

            <PhoneInput
              placeholder={intl.formatMessage({
                id: 'field.phone.label',
              })}
              value={state.phone.value}
              onChange={phoneChangeHandler}
              onBlur={(e) => blurHandler(e, true)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="accelerate-track-name">
              <FormattedMessage id="profile.my_activity.table.track" />
            </label>
            <input
              onBlur={blurHandler}
              onFocus={focusHandler}
              onChange={changeHandler}
              value={state.trackName.value}
              className={getInputClasses('trackName')}
              type="text"
              name="trackName"
              id="accelerate-track-name"
              placeholder={intl.formatMessage({
                id: 'accelerate.form.trackName',
              })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="billing-country">
              <FormattedMessage id="accelerate.form.country" />
            </label>
            <Select
              id="billing-country"
              name="country"
              onChange={countryChangeHandler}
              isClearable
              options={countries}
              className="select"
              classNamePrefix="select"
            />
          </div>

          <div className="form-group">
            <label className="terms-label">
              <input
                type="checkbox"
                checked={termsAgreed}
                onChange={termsChangeHandler}
              />
              <FormattedMessage id="payment.i_agree_to" />{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={ROUTES.TERMS}
                className="font-semibold underline"
              >
                <FormattedMessage id="payment.terms_and_conditions" />
              </a>
            </label>
          </div>

          <div className="overview-button-block">
            <Button
              icon="rocket"
              onClick={submitHandler}
              disabled={btnDisabled}
              className="w-full"
              label={btnLabel ? btnLabel : <FormattedMessage id="navigation.accelerate" />}
              loading={isSubmitLoading}
            />
          </div>
        </form>) : (
        <Paywall
          icon="rocket"
          ctaLink={ROUTES.PRICING}
          ctaLabel={<FormattedMessage id="navigation.pricing" />}
        >
          {parse(intl.formatMessage({ id: 'paywall.accelerate' }))}
        </Paywall>
      )}
    </Auth>
  )
}
