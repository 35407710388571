import React from 'react'
import Wrapper from '@fnd/components/Wrapper'

export default () => {

  const PianoPlayground = React.lazy(() => import('@fnd/components/Piano/PianoPlayground'))

  return (
    <Wrapper className="playground">
      <React.Suspense fallback={null}>
        <PianoPlayground />
      </React.Suspense>
    </Wrapper>
  )
}
