import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  COUNTERS,
  TRUSTPILOT_LINK
} from '@fnd/constants'
import { formatNumber } from '@fnd/core/libs/currency'
import { ROUTES } from '@fnd/screens/constants'
import { Link as Scroll } from 'react-scroll'
import Auth from '@fnd/components/Auth'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import {
  splitArray,
  getRandomUniqueNumbers
} from '@fnd/core/libs/helpers'
import StarRating from '@fnd/components/Rating'
import { TrustpilotMicroComboWidget } from '@fnd/components/Trustpilot'
import Icon from '@fnd/components/Icon'
import Marquee from 'react-fast-marquee'
import { motion } from 'framer-motion'

const HomeHero = () => {
  const IMAGES_NUMBER = 90
  const IMAGES_GRID = 60
  const IMAGE_ROWS = 3

  const imgs = getRandomUniqueNumbers(1, IMAGES_NUMBER, IMAGES_GRID)
  const imageRows = splitArray(imgs, IMAGE_ROWS)
  const intl = useIntl()

  const animation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  }

  return (
    <div className="home-hero">
      <div className="home-hero-content">
        <motion.h1 {...animation}>
          <FormattedMessage id="home.main.title" />
        </motion.h1>

        <motion.h2>
          <FormattedMessage id="home.main.subtitle" />
        </motion.h2>

        <Auth login loginClasses="button-cta mt-2">
          <Button
            to={ROUTES.MATCH}
            variant="gradient"
            className="button-cta mt-2"
          >
            <FormattedMessage id="home.main.start_now" />
          </Button>
        </Auth>

        <div className="flex flex-col sm:flex-row flex-wrap items-center justify-center gap-2 mt-8">
          <Badge
            variant="primary-light"
            icon="exchange"
            className="px-4 text-base md:text-lg font-semibold"
            value={intl.formatMessage({ id: 'home.solution.submissions.counter' })}
            label={formatNumber(COUNTERS.SUBMISSIONS)}
          />

          <Badge
            variant="primary-light"
            icon="user-music"
            className="px-4 text-base md:text-lg font-semibold"
            value={intl.formatMessage({ id: 'home.solution.artists.counter' })}
            label={formatNumber(COUNTERS.ARTISTS)}
          />

          <Badge
            variant="primary-light"
            icon="list-music"
            className="px-4 text-base md:text-lg font-semibold"
            value={intl.formatMessage({ id: 'home.solution.playlists.counter' })}
            label={formatNumber(COUNTERS.PLAYLISTS)}
          />
        </div>

        <TrustpilotMicroComboWidget className="w-full mt-6" />

        <Scroll
          className="home-hero-scroll-down mt-8"
          to="intro"
          spy
          smooth
          offset={-70}
          duration={500}
        >
          <Icon name="arrow-down" className="mr-2" />
          <FormattedMessage id="home.main.scroll_down" />
        </Scroll>
      </div>

      <div className="home-grid-wrapper">
        <div className="home-grid">
          {imageRows.map((row, i) => (
            <Marquee
              key={i}
              direction={i % 2 === 0 ? 'left' : 'right'}
              className="marquee"
            >
              {row.map((idx) => (
                <div key={idx} className="home-grid-cell">
                  <img
                    src={`/images/covers/sp_playlist_${idx}.jpeg`}
                    alt={`Playlist Cover ${idx}`}
                  />
                </div>
              ))}
            </Marquee>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeHero
