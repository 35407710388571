import React from 'react'
import classNames from 'classnames'

const SoundcloudLogo = ({ className }) => (
  <svg className={classNames({ [className]: className })} viewBox="0 0 672 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_295_224)">
      <path d="M231.041 63.9041C227.705 64.0376 224.388 63.3325 221.395 61.8533C218.402 60.3741 215.827 58.168 213.906 55.4368L220.257 49.5656C221.602 51.2644 223.323 52.6282 225.284 53.5499C227.245 54.4715 229.393 54.926 231.56 54.8777C236.792 54.8777 239.268 53.3599 239.268 49.8452C239.268 46.3305 237.751 45.5317 229.403 43.5347C221.056 41.5377 215.584 38.8217 215.584 29.6355C215.584 21.1682 222.653 16.016 231.56 16.016C234.597 15.9818 237.604 16.6193 240.366 17.8828C243.128 19.1464 245.576 21.0048 247.536 23.325L241.146 29.8352C239.928 28.3505 238.399 27.1515 236.666 26.3233C234.934 25.495 233.041 25.0578 231.121 25.0424C226.408 25.0424 224.81 27.1592 224.81 29.2361C224.81 32.1517 226.727 33.2301 233.717 34.8277C240.706 36.4253 248.494 39.0614 248.494 49.1662C248.415 58.0329 243.103 63.9041 231.041 63.9041Z" fill="#FF5500" />
      <path d="M275.933 63.9042C264.391 63.9042 256.522 54.9576 256.522 39.9401C256.522 24.9227 264.51 15.9761 276.053 15.9761C287.596 15.9761 295.424 24.8827 295.424 39.9401C295.424 54.9975 287.396 63.9042 275.933 63.9042ZM275.933 25.1224C269.783 25.1224 265.669 30.3545 265.669 39.8203C265.669 49.2861 269.942 54.6381 276.133 54.6381C282.324 54.6381 286.358 49.5257 286.358 39.9401C286.358 30.3545 282.044 25.2422 275.853 25.2422L275.933 25.1224Z" fill="#FF5500" />
      <path d="M324.101 63.9041C313.477 63.9041 306.288 56.3155 306.288 42.7359V16.7349H315.115V42.7359C315.115 50.1647 318.51 54.7179 324.101 54.7179C329.693 54.7179 333.207 50.1647 333.207 42.7359V16.7349H342.034V42.7359C342.154 56.2356 335.484 63.9041 324.101 63.9041Z" fill="#FF5500" />
      <path d="M383.692 63.2651L367.476 37.8632C366.398 36.1457 364.96 33.8692 364.361 32.631C364.361 34.3484 364.361 40.2196 364.361 42.7758V63.2651H355.813V16.7349H364.241L380.017 41.338C381.096 43.0554 382.533 45.332 383.093 46.5701C383.093 44.8527 383.093 39.0214 383.093 36.4253V16.7349H391.799V63.2651H383.692Z" fill="#FF5500" />
      <path d="M418.679 63.2651H405.499V16.7349H419.318C431.939 16.7349 441.245 22.9655 441.245 40.0199C441.245 56.3554 430.022 63.2651 418.679 63.2651ZM418.999 25.7613H414.326V54.2386H418.679C427.945 54.2386 432.179 48.5272 432.179 40.0199C432.179 30.4743 428.345 25.7613 418.999 25.7613Z" fill="#FF5500" />
      <path d="M479.588 30.3145C478.922 28.7633 477.803 27.4493 476.377 26.5443C474.952 25.6393 473.287 25.1855 471.6 25.2421C464.97 25.2421 461.176 31.353 461.176 40.02C461.176 48.687 465.17 54.7578 471.64 54.7578C473.513 54.7638 475.343 54.2022 476.89 53.1469C478.438 52.0917 479.629 50.5924 480.307 48.8467L487.856 53.5197C486.513 56.7492 484.202 59.4825 481.24 61.3425C478.278 63.2025 474.812 64.0976 471.32 63.9041C459.897 63.9041 451.909 54.678 451.909 39.9401C451.909 25.2022 459.897 15.976 471.52 15.976C474.918 15.8472 478.274 16.7668 481.132 18.6102C483.99 20.4536 486.212 23.1315 487.496 26.2806L479.588 30.3145Z" fill="#FF5500" />
      <path d="M500.437 63.2651V16.7349H509.303V54.0788H531.43V63.2651H500.437Z" fill="#FF5500" />
      <path d="M555.434 63.9042C543.891 63.9042 536.023 54.9576 536.023 39.9401C536.023 24.9227 544.011 15.9761 555.554 15.9761C567.097 15.9761 574.925 24.8827 574.925 39.9401C574.925 54.9975 566.937 63.9042 555.434 63.9042ZM555.434 25.1224C549.243 25.1224 545.13 30.3545 545.13 39.8203C545.13 49.2861 549.443 54.6381 555.594 54.6381C561.745 54.6381 565.898 49.4059 565.898 39.9401C565.898 30.4743 561.585 25.2422 555.394 25.2422L555.434 25.1224Z" fill="#FF5500" />
      <path d="M603.682 63.9041C593.018 63.9041 585.828 56.3155 585.828 42.7359V16.7349H594.655V42.7359C594.655 50.1647 598.05 54.7179 603.682 54.7179C609.313 54.7179 612.748 50.1647 612.748 42.7359V16.7349H621.575V42.7359C621.655 56.2356 614.985 63.9041 603.682 63.9041Z" fill="#FF5500" />
      <path d="M649.014 63.5447H635.833V17.0145H649.613C662.234 17.0145 671.58 23.2452 671.58 40.2597C671.58 56.6351 660.357 63.5447 649.014 63.5447ZM649.333 26.041H644.66V54.5183H649.014C658.28 54.5183 662.513 48.7669 662.513 40.2597C662.513 30.7539 658.639 26.041 649.333 26.041Z" fill="#FF5500" />
      <path d="M179.638 56.3155C179.258 62.7534 176.409 68.7965 171.685 73.1862C166.96 77.5758 160.724 79.9737 154.276 79.8802H93.2474C91.9091 79.8696 90.6291 79.3306 89.6865 78.3805C88.7438 77.4303 88.2149 76.1462 88.215 74.8078V9.2661C88.1804 8.1521 88.4819 7.05348 89.0802 6.11319C89.6786 5.1729 90.5461 4.43448 91.5699 3.99401C96.883 1.05042 102.919 -0.333973 108.984 1.19968e-06C116.249 -0.024303 123.386 1.92194 129.633 5.63155C134.467 8.46465 138.664 12.2643 141.963 16.7931C145.261 21.3219 147.59 26.4824 148.804 31.9521C150.903 31.3232 153.084 31.0135 155.274 31.0334C158.553 31.0311 161.798 31.6904 164.815 32.9718C167.832 34.2532 170.56 36.1304 172.835 38.491C175.11 40.8516 176.885 43.6469 178.054 46.7097C179.223 49.7724 179.761 53.0396 179.638 56.3155Z" fill="#FF5500" />
      <path d="M80.4665 13.3799C80.4665 12.8503 80.2561 12.3424 79.8816 11.9678C79.5071 11.5933 78.9992 11.3829 78.4695 11.3829C77.9399 11.3829 77.4319 11.5933 77.0574 11.9678C76.6829 12.3424 76.4725 12.8503 76.4725 13.3799C74.176 34.8998 74.176 56.603 76.4725 78.1228C76.4725 78.6525 76.6829 79.1604 77.0574 79.5349C77.4319 79.9094 77.9399 80.1198 78.4695 80.1198C78.9992 80.1198 79.5071 79.9094 79.8816 79.5349C80.2561 79.1604 80.4665 78.6525 80.4665 78.1228C83.7416 55.9161 82.4236 35.6665 80.4665 13.3799Z" fill="#FF5500" />
      <path d="M68.4845 21.0484C68.3606 20.6144 68.0986 20.2326 67.7383 19.9608C67.3779 19.689 66.9389 19.542 66.4875 19.542C66.0362 19.542 65.5971 19.689 65.2368 19.9608C64.8765 20.2326 64.6145 20.6144 64.4905 21.0484C62.2938 40.0365 62.2938 59.2146 64.4905 78.2027C64.5833 78.664 64.8329 79.079 65.1969 79.3772C65.5609 79.6753 66.017 79.8382 66.4875 79.8382C66.9581 79.8382 67.4141 79.6753 67.7782 79.3772C68.1422 79.079 68.3918 78.664 68.4845 78.2027C70.9407 59.2304 70.9407 40.0207 68.4845 21.0484Z" fill="#FF5500" />
      <path d="M55.9035 19.0914C55.9035 18.5617 55.6931 18.0538 55.3186 17.6793C54.944 17.3048 54.4361 17.0944 53.9065 17.0944C53.3768 17.0944 52.8689 17.3048 52.4944 17.6793C52.1199 18.0538 51.9095 18.5617 51.9095 19.0914C49.5525 38.7113 49.5525 58.5428 51.9095 78.1628C51.9095 78.6924 52.1199 79.2003 52.4944 79.5748C52.8689 79.9494 53.3768 80.1598 53.9065 80.1598C54.4361 80.1598 54.944 79.9494 55.3186 79.5748C55.6931 79.2003 55.9035 78.6924 55.9035 78.1628C58.3598 58.5489 58.3598 38.7052 55.9035 19.0914Z" fill="#FF5500" />
      <path d="M43.6019 24.8427C43.4779 24.4087 43.216 24.027 42.8556 23.7551C42.4953 23.4833 42.0562 23.3363 41.6049 23.3363C41.1535 23.3363 40.7145 23.4833 40.3542 23.7551C39.9938 24.027 39.7319 24.4087 39.6079 24.8427C37.3312 42.5699 37.3312 60.5155 39.6079 78.2426C39.7006 78.704 39.9502 79.119 40.3142 79.4171C40.6783 79.7153 41.1343 79.8782 41.6049 79.8782C42.0755 79.8782 42.5315 79.7153 42.8955 79.4171C43.2596 79.119 43.5092 78.704 43.6019 78.2426C46.0382 60.526 46.0382 42.5593 43.6019 24.8427Z" fill="#FF5500" />
      <path d="M31.1406 38.1428C31.0479 37.6814 30.7983 37.2664 30.4342 36.9683C30.0702 36.6701 29.6141 36.5072 29.1436 36.5072C28.673 36.5072 28.217 36.6701 27.8529 36.9683C27.4889 37.2664 27.2393 37.6814 27.1466 38.1428C24.191 51.8821 25.5889 64.5032 27.1466 78.3225C27.1466 78.8521 27.357 79.3601 27.7315 79.7346C28.106 80.1091 28.6139 80.3195 29.1436 80.3195C29.6732 80.3195 30.1812 80.1091 30.5557 79.7346C30.9302 79.3601 31.1406 78.8521 31.1406 78.3225C32.8181 64.2636 34.2559 51.9221 31.1406 38.1428Z" fill="#FF5500" />
      <path d="M18.799 35.9461C18.6536 35.5325 18.3834 35.1743 18.0257 34.9209C17.668 34.6675 17.2404 34.5314 16.802 34.5314C16.3637 34.5314 15.9361 34.6675 15.5784 34.9209C15.2206 35.1743 14.9504 35.5325 14.805 35.9461C12.591 49.3283 12.591 62.9833 14.805 76.3655C14.8978 76.8268 15.1474 77.2418 15.5114 77.54C15.8754 77.8381 16.3315 78.001 16.802 78.001C17.2726 78.001 17.7286 77.8381 18.0927 77.54C18.4567 77.2418 18.7063 76.8268 18.799 76.3655C21.2355 63.0025 21.2355 49.3091 18.799 35.9461Z" fill="#FF5500" />
      <path d="M6.33777 42.8957C6.19237 42.4821 5.92216 42.1239 5.56444 41.8705C5.20672 41.6171 4.77915 41.481 4.34076 41.481C3.90238 41.481 3.47481 41.6171 3.11709 41.8705C2.75937 42.1239 2.48916 42.4821 2.34376 42.8957C0.206702 51.6606 0.206702 60.8107 2.34376 69.5757C2.34376 70.1053 2.55416 70.6133 2.92867 70.9878C3.30318 71.3623 3.81113 71.5727 4.34076 71.5727C4.8704 71.5727 5.37835 71.3623 5.75286 70.9878C6.12737 70.6133 6.33777 70.1053 6.33777 69.5757C8.89403 60.866 8.89403 51.6053 6.33777 42.8957Z" fill="#FF5500" />
    </g>
    <defs>
      <clipPath id="clip0_295_224">
        <rect width="670.993" height="80" fill="white" transform="translate(0.586426)" />
      </clipPath>
    </defs>
  </svg>
)

export default SoundcloudLogo
