import React from 'react'
import { ViewSwitch } from '@fnd/components/View'
import { TextFadeUp } from '@fnd/components/Animation'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Checkbox from '@fnd/components/Field/Checkbox'
import { SpinnerIcon } from '@fnd/components/Spinner'
import { useIntl } from 'react-intl'


function SpotifyListHeader({
  items,
  genres,
  fetchNextPage,
  hasMore,
  loadMore,
  skipPushed,
  setSkipPushed,
  view,
  setView
}) {
  const intl = useIntl()

  return (
    <div className="analysis-header">
      <div className="flex flex-col md:flex-row md:items-center w-full gap-2">
        <div className="flex items-center gap-1">
          <SpinnerIcon
            icon="list-music"
            animating={hasMore}
          />

          <TextFadeUp
            className="text-lg font-bold tracking-wide"
            value={items?.length}
          />

          <span>
            {intl.formatMessage({ id: 'match.showing_message' })}
          </span>
        </div>

        {genres?.length > 0 && (
          <div className="flex flex-wrap gap-1">
            {genres.map((genre, index) => (
              <Badge
                key={index}
                variant="light"
                className="h-9"
                label={genre}
              />
            ))}
          </div>
        )}

        <Checkbox
          id="filter-skip-pushed"
          className="my-1 md:mx-2"
          checked={skipPushed}
          onCheckedChange={() => setSkipPushed(!skipPushed)}
          label={intl.formatMessage({ id: 'match.filters.skip_pushed' })}
        />

        <Button
          variant="green"
          icon="sync-alt"
          className="text-sm h-9 rounded-lg"
          label={intl.formatMessage({ id: 'match.filters.load_more' })}
          disabled={!loadMore}
          onClick={fetchNextPage}
        />
      </div>

      <ViewSwitch
        className="w-full md:w-auto"
        view={view}
        setView={setView}
      />
    </div>
  )
}

export default SpotifyListHeader
