import React, { useEffect, useRef, useMemo, useState } from "react"
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@fnd/components/Table/Table"
import { TablePagination } from "@fnd/components/Table/TablePagination"
import Empty from "@fnd/components/Empty"
import Spinner from "@fnd/components/Spinner"
import classNames from "classnames"
import { PER_PAGE } from "@fnd/constants"
import { useVirtualizer } from '@tanstack/react-virtual'

export function VirtualDataTable({
  rowIdKey = "id",
  columns,
  data,
  filters,
  isLoading = true,
  pagination,
  setPagination,
  total,
  className
}) {
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnFilters, setColumnFilters] = useState([])
  const [sorting, setSorting] = useState([])

  const defaultPagination = {
    pageIndex: 0,
    pageSize: PER_PAGE,
  }

  const tableColumns = useMemo(() => columns, [])

  const table = useReactTable({
    data,
    columns: tableColumns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: pagination || defaultPagination,
    },
    enableRowSelection: true,
    manualPagination: pagination ? true : false,
    autoResetSelectedRows: false,
    getRowId: (row) => row[rowIdKey],
    rowCount: total || data.length,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    debugTable: true
  })

  const TableFilters = filters
  const parentRef = useRef(null)

  const rowVirtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => parentRef.current,
    measureElement: typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
      ? element => element?.getBoundingClientRect().height
      : undefined,
    estimateSize: () => 75,
    overscan: 50,
  })

  useEffect(() => {
    const hiddenColumns = columns
      .filter((column) => column.show === false)
      .map((column) => column.id)

    setColumnVisibility({
      ...columnVisibility,
      ...hiddenColumns.reduce((item, key) => {
        item[key] = false
        return item
      }, {}),
    })
  }, [columns])

  const tableClasses = classNames({
    'flex flex-col gap-4': true,
    [className]: className,
  })

  return (
    <div className={tableClasses}>
      {filters && <TableFilters data={data} table={table} />}

      <div
        ref={parentRef}
        className="relative w-full overflow-y-auto border-outline rounded-lg"
        style={{ maxHeight: '80vh' }}
      >
        <Table className="grid">
          <TableHeader className="grid sticky top-0 z-10">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="flex w-full">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="flex"
                      style={{
                        width: `${header.getSize()}%`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody
            className="relative grid"
            style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = table.getRowModel().rows[virtualRow.index]
              return (
                <TableRow
                  data-index={virtualRow.index}
                  ref={node => rowVirtualizer.measureElement(node)}
                  key={row.id}
                  className="flex absolute w-full"
                  style={{
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="flex items-center"
                      style={{
                        width: `${cell.column.getSize()}%`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}

            {!isLoading && table.getRowModel().rows?.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center text-muted-foreground"
                >
                  <Empty ghost className="my-0" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <TablePagination pagination={pagination} table={table} />
    </div>
  )
}
