import React, { memo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import HeadMeta from '@fnd/components/HeadMeta'
import { Tour } from '@fnd/components/Tour/Tour'
import { CuratorDetail, CuratorPage } from '@fnd/modules/Curator'
import { PlaylistDetail } from '@fnd/modules/Playlist'
import Profile from '@fnd/modules/Profile'
import { TrackDetail, TrackPlays } from '@fnd/modules/Track'
import { TikTokMatch } from '@fnd/modules/TikTok'
import { MagazineMatch } from '@fnd/modules/Magazine'
import { RadioMatch } from '@fnd/modules/Radio'
import Match from '@fnd/modules/Analysis'
import { isBlockedUserSelector, isUserAuthorizedSelector } from '@fnd/modules/User/selectors'

import Accelerate from './AcceleratePage'
import Affiliate from './Affiliate'
import Analysis from './Analysis'
import Contest from './Contest'
import Faq from './Faq'
import Home from './Home'
import Chat from './Chat'
import DistributionPage from './DistributionPage'
import Invoice from './Invoice'
import Notifications from './Notifications'
import PaymentRedirect from './PaymentRedirect'
import Pricing from './Pricing'
import Privacy from './Privacy'
import Token from './Token'
import PopularSubmissions from './PopularSubmissions'
import Rank from './Rank'
import Report from './Report'
import ServiceLevelAgreement from './ServiceLevelAgreement'
import Support from './Support'
import Team from './Team'
import Terms from './Terms'
import Tutorials from './Tutorials'
import Vip from './Vip'
import Playground from './Playground'
import ContractOfService from './ContractOfService'
import NotFound from './NotFound'
import SingleSignOn from './SingleSignOn'

import { ROUTES } from './constants'
import { onlyUpdateForKeys } from 'recompose'

const Main = memo(() => {
  return (
    <Routes>
      <Route path={ROUTES.MY_PROFILE.ROOT} element={<Profile />} />
      <Route path={ROUTES.MATCH} element={<Match />} />
      <Route path={ROUTES.CHAT} element={<Chat />} />
      <Route path={ROUTES.CHAT_DETAIL} element={<Chat detail={true} />} />
      <Route path={ROUTES.ANALYSIS} element={<Analysis />} />
      <Route path={ROUTES.CURATORS} element={<CuratorPage />} />
      <Route path={ROUTES.CONTEST} element={<Contest />} />
      <Route path={ROUTES.CURATOR_DETAIL} element={<CuratorDetail />} />
      <Route path={ROUTES.DISTRIBUTION} element={<DistributionPage />} />
      <Route path={ROUTES.PLAYLIST_DETAIL} element={<PlaylistDetail />} />
      <Route path={ROUTES.TRACKS} element={<TrackPlays />} />
      <Route path={ROUTES.TIKTOK} element={<TikTokMatch />} />
      <Route path={ROUTES.TIKTOK_DETAIL} element={<TikTokMatch />} />
      <Route path={ROUTES.MAGAZINE} element={<MagazineMatch />} />
      <Route path={ROUTES.RADIO} element={<RadioMatch />} />
      <Route path={ROUTES.RADIO_DETAIL} element={<RadioMatch />} />
      <Route path={ROUTES.TRACK_DETAIL} element={<TrackDetail />} />
      <Route
        path={ROUTES.CURATOR_PLAYLIST_DETAIL}
        element={<PlaylistDetail />}
      />
      <Route path={ROUTES.INVOICE} element={<Invoice />} />
      <Route path={ROUTES.PRICING} element={<Pricing />} />
      <Route path={ROUTES.PRICING_LANDING} element={<Pricing landing />} />
      <Route path={ROUTES.AFFILIATE} element={<Affiliate />} />
      <Route path={ROUTES.VIP} element={<Vip />} />
      <Route path={ROUTES.ACCELERATE_PAGE} element={<Accelerate />} />
      <Route path={ROUTES.RANK} element={<Rank />} />
      <Route path={ROUTES.REPORT} element={<Report />} />
      <Route path={ROUTES.TEAM} element={<Team />} />
      <Route path={ROUTES.FAQ} element={<Faq showTitle className="my-10" />} />
      <Route path={ROUTES.TOKEN} element={<Token />} />
      <Route path={ROUTES.POPULAR} element={<PopularSubmissions />} />

      <Route path={ROUTES.SLA} element={<ServiceLevelAgreement />} />
      <Route path={ROUTES.TERMS} element={<Terms />} />
      <Route path={ROUTES.PRIVACY} element={<Privacy />} />
      <Route path={ROUTES.CONTRACT_OF_SERVICE} element={<ContractOfService />} />

      <Route path={ROUTES.TUTORIALS} element={<Tutorials />} />
      <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
      <Route path={ROUTES.PLAYGROUND} element={<Playground />} />
      <Route path={ROUTES.PAYMENT_REDIRECT} element={<PaymentRedirect />} />

      <Route
        path={ROUTES.PROFILE}
        render={() => <Navigate to={ROUTES.MY_PROFILE.OVERVIEW} />}
      />
      <Route
        path={ROUTES.HISTORY_ACTIVITY}
        render={() => <Navigate to={ROUTES.MY_PROFILE.OVERVIEW} />}
      />
      <Route
        path={ROUTES.MY_DOWNLOADS}
        render={() => <Navigate to={ROUTES.MY_PROFILE.OVERVIEW} />}
      />
      <Route
        path={ROUTES.PAYMENTS_HISTORY}
        render={() => <Navigate to={ROUTES.MY_PROFILE.OVERVIEW} />}
      />
      <Route
        path={ROUTES.ACCOUNT}
        render={() => <Navigate to={ROUTES.MY_PROFILE.OVERVIEW} />}
      />

      <Route path={ROUTES.SUPPORT} element={<Support />} />

      <Route path={ROUTES.HOME} element={<Home />} />

      <Route path={ROUTES.ALL} element={<NotFound />} />
      <Route path={ROUTES.SPOTIFY_SSO} element={<SingleSignOn />} />
    </Routes>
  )
}, () => true)

const _Main = ({ user }) => {
  const isBlocked = isBlockedUserSelector(user)
  const isLogged = isUserAuthorizedSelector(user)

  if (isLogged && isBlocked) {
    return <Navigate to={ROUTES.BLOCKED} />
  }
  return (
    <>
      <HeadMeta />
      <Tour />
      <Main />
    </>
  )
}

export default onlyUpdateForKeys(['user'])(_Main)
