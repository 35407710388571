import React from 'react'
import classNames from 'classnames'

const NapsterLogoIcon = ({ className }) => (
  <svg className={classNames({ [className]: className })} viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_288_246)">
      <path fillRule="evenodd" clipRule="evenodd" d="M123.99 28.5925C125.358 28.4008 126.727 28.4008 128.097 28.4008H136.506V43.3618L136.701 43.5536C144.72 47.198 150 55.2539 150 64.0771C150 72.7087 144.72 80.7649 136.701 84.6011L136.506 84.7928V85.1764C136.31 118.168 108.735 145.021 75.0979 145.021C41.4602 145.021 13.8852 118.168 13.6897 85.1764V84.7928H13.2986C5.2803 81.1484 0 73.0922 0 64.2693C0 55.446 5.2803 47.3898 13.2986 43.7453L13.4941 43.5536V28.5925H22.0991H22.0991C23.4681 28.5925 24.837 28.5925 26.206 28.7844H26.4016L26.5971 28.5925C37.94 13.8232 56.3234 5 75.0979 5C93.8723 5 112.06 13.8232 123.598 28.4008L123.794 28.5925H123.99ZM75.0981 20.1529C62.9726 20.1529 51.2389 24.9481 42.6338 33.3877L42.0473 33.7714L43.2204 34.155C45.3716 35.3058 47.7185 36.8403 49.8698 38.3748L50.0654 38.5666L50.2611 38.3748C55.9323 35.4977 64.9286 32.2369 75.2937 32.2369C85.6587 32.2369 94.6547 35.4977 100.326 38.3748L100.522 38.5666L100.717 38.3748C102.868 36.8403 105.02 35.4977 107.367 34.155L107.953 33.7714L107.562 33.3877C98.9572 24.9481 87.2231 20.1529 75.0981 20.1529ZM119.687 84.6012C119.687 108.769 99.5436 128.525 74.9022 128.525C50.2609 128.525 30.3129 108.769 30.3129 84.6012V46.6228L30.8996 46.8146C38.7223 48.7325 46.154 57.1722 47.914 59.2823C49.8696 57.1722 59.0612 48.9242 75.0979 48.9242C91.1342 48.9242 100.326 57.364 102.282 59.2823C104.042 57.1722 111.278 48.7325 119.296 46.8146L119.883 46.6228V84.6012H119.687Z" fill="#2259FF" />
    </g>
    <defs>
      <clipPath id="clip0_288_246">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default NapsterLogoIcon
