import { SpinnerAudio } from '@fnd/components/Spinner'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'


export function TrackCard({
  track, vertical, link, onClick, titleTag, nowPlaying, className
}) {
  const intl = useIntl()
  const navigate = useNavigate()

  const onClickHandler = () => {
    if (link) navigate(link)
    if (onClick) onClick(track)
  }

  const classes = classNames({
    'player': true,
    'player-link': link,
    'player-vertical': vertical,
    [className]: className,
  })

  const TrackTitle = titleTag

  return (
    <div className={classes} onClick={onClickHandler}>
      <div className="player-card">
        <div className="track-card-main">
          <div className="track-cover">
            <img src={track.album.images[0]?.url} alt={track.name} />
          </div>

          <div className="track-info">
            <TrackTitle className="mb-0">{track.name}</TrackTitle>

            <div className="track-artists">
              {track.artists.map((artist) => (
                <a target="_blank"
                  rel="noopener noreferrer"
                  href={`https://open.spotify.com/artist/${artist.id}`}
                  key={artist.id}
                >
                  {artist.name}
                </a>
              ))}
            </div>
          </div>
        </div>

        {nowPlaying && <div className="player-is-playing">
          <SpinnerAudio />
          <span className="font-medium">
            {intl.formatMessage({ id: 'track_player.now_playing' })}
          </span>
        </div>}
      </div>
    </div>
  )
}


export default TrackCard

TrackCard.defaultProps = {
  titleTag: 'h3'
}
