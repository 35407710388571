import React from 'react'
import classNames from 'classnames'

const StepperFooter = ({ children, className }) => {

  const classes = classNames({
    'stepper-footer': true,
    [className]: className,
  })

  return (
    <div className={classes} >
      {children}
    </div>
  )
}

export default StepperFooter
