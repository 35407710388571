import React from 'react'
import classNames from 'classnames'
import Badge from '@fnd/components/Badge'
import { VideoAutoplay } from '@fnd/components/Video'
import { useInView } from 'react-intersection-observer'
import { isMobile } from 'react-device-detect'
import { nFormatter } from '@fnd/core/libs/helpers'

export function TikTokCard({
  nickname,
  avatar,
  cover,
  video,
  tags,
  tikTokId,
  followers,
  heartCount,
  videoCount,
  preview,
  onClick,
  className,
}) {
  const { ref, inView } = useInView({
    threshold: .5,
  })

  const classes = classNames({
    'tiktok-card': true,
    'card-preview': preview,
    'cursor-pointer': true,
    [className]: className,
  })

  const genres = tags.filter(tag => tag !== 'all')

  return (
    <div ref={ref} key={tikTokId} className={classes} onClick={onClick}>
      <div className="tiktok-card-video">
        <VideoAutoplay
          src={video}
          autoplay={inView && isMobile}
        />
      </div>

      <div className="tiktok-card-cover">
        <img src={cover} alt={nickname} />
      </div>

      <div className="tiktok-card-bg">
        <img src={cover} alt={nickname} />
      </div>

      <div className="tiktok-card-content px-4 pb-4">
        <div className="flex flex-col justify-center items-center gap-2">
          <img
            className="tiktok-card-avatar -m-16"
            src={avatar}
            alt={nickname}
          />
          <h3 className="text-xl text-center mt-10 mb-0">
            {nickname}
          </h3>
        </div>

        <div className="flex items-center justify-between gap-2">
          {heartCount && heartCount > 0 && <Badge
            className="flex-1 justify-center"
            icon="heart"
            variant="light"
            label={nFormatter(heartCount)}
          />}

          {followers && followers > 0 && <Badge
            className="flex-1 justify-center"
            icon="users"
            variant="light"
            label={nFormatter(followers)}
          />}

          {videoCount && videoCount > 0 && <Badge
            className="flex-1 justify-center"
            icon="video"
            variant="light"
            label={nFormatter(videoCount)}
          />}
        </div>

        {genres && genres?.length > 0 && <div>
          <Badge
            className="block text-center truncate"
            icon="music"
            variant="light"
            label={genres.join([', '])}
          />
        </div>}
      </div>
    </div>
  )
}

export default TikTokCard
